import React, { useState, createContext, useContext } from 'react'
import { UserRound, NotebookText, BookCopy, BriefcaseBusiness, GraduationCap, Wrench, ShieldCheck, Stamp, Grip, ChevronUp, ChevronDown } from 'lucide-react';
const ResumeSectionsContext = createContext();

export const ResumeSectionsContextProvider = ({ children }) => {
    const [sections, setSections] = useState([
        {
          name:'PERSONAL INFORMATION',
          icon:<UserRound />,
        },
        {
          name:'SUMMARY',
          icon:<NotebookText />,
        },
        {
          name:'EXPERIENCE',
          icon:<BookCopy />,
        },
        {
          name:'EDUCATION',
          icon:<GraduationCap />,
        },
        {
          name:'SKILLS',
          icon:<Wrench />,
        },
        {
          name:'CERTIFICATIONS',
          icon: <ShieldCheck />,
        },
        // {
        //   name:'PROJECTS',
        //   icon:<BriefcaseBusiness />,
        // },
        // {
        //   name:'REFEREES',
        //   icon: <Stamp />,
        // },
      ])

  return (
    <ResumeSectionsContext.Provider value={{
        sections,
        setSections,
    }}>
        { children }
    </ResumeSectionsContext.Provider>
  )
}

export const useResumeSectionsContext = ()=> useContext(ResumeSectionsContext)

