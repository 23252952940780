import React, { useState, useRef, useEffect } from "react";
import { UserRound, LogOut
} from 'lucide-react';
import { useBillingContext } from "../../contexts/billingContext";
import tokenService from "../../services/token.service";

const NavDropDown = ({ tabs, handleSignOut, handleSelectTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const NavDropDownRef = useRef(null);
  const { paymentDetails } = useBillingContext();


  const toggleNavDropDown = () => {
    setIsOpen(!isOpen);
  };

  const closeNavDropDown = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (NavDropDownRef.current && !NavDropDownRef.current.contains(e.target)) {
      closeNavDropDown();
    }
  };

  const handleItemClick = () => {
    closeNavDropDown();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative mx-5 font-primary z-50" ref={NavDropDownRef}>
      <div className="flex items-center gap-4">
        <button
          onClick={toggleNavDropDown}
          className="rounded-full h-9 w-9 flex justify-center items-center border p-1 bg-dark animate-pulse"
        >
          <UserRound color="white"/>
        </button>
        <div className="hidden md:block">
          <h3 className="text-xxsm font-normal">{tokenService.getUser().email}</h3>
          {/* <h3 className="text-xxsm font-normal flex gap-2 mt-1">Account type: 
          <span className="rounded-full px-5 py-0 shadow-lg border-2 bg-yellow/40">{paymentDetails?.paymentStatus === 'paid' ? 'subscribed' : 'Free'}</span> 
          </h3> */}
          
          <div className="flex items-center gap-2">
            <div className="h-4 w-4 flex items-center justify-center rounded-full border border-primary">
                <div className="h-3 w-3 bg-green-600 rounded-full animate-pi border border-primary p-0 animate-ping">

                </div>
            </div>
            <h3 className="text-xsm font-normal flex gap-2">Online </h3>
          </div>
          
        </div>
      </div>
      {isOpen && (
        <ul className="absolute right-0 mt-2 w-80 bg-white border border-gray-300 rounded-md shadow-lg p-5">
          {
            tabs.map((tab, i)=>(
                <li 
                key={`${tab.name}-${i}`}
                onClick={()=>{
                  handleSelectTab(tab.name);
                  handleItemClick();
                }}
                className='w-full flex flex-row items-center gap-1 py-2
                cursor-pointer hover:bg-light capitalize text-slate-600 border-b border-light font-normal text-xsm'>
                    <span>{tab.icon}</span>{tab.name}
                </li>
            ))
          }
           <li className='w-full flex flex-row items-center gap-1 mt-4 cursor-pointer font-normal text-xsm' onClick={handleSignOut}><span><LogOut size={17} /></span>Logout</li>
        </ul>
      )}
    </div>
  );
};

export default NavDropDown;
