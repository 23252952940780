import coreClient from "../../services/coreApi";
export const useDeleteResume = () => {
  return async(resumeId, itemId)=>{
    try {
        const res = await coreClient.delete(`/resume/${resumeId}/delete/${itemId}`,);
        return res;
    } catch (error) {
       throw error
    }
  }
}

