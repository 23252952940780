import React, { useState } from 'react';
import { Home, Download, ScanSearch, LayoutTemplate, Files } from 'lucide-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useResumeDataContext } from '../../contexts/resumeDataContext';
import { useTemplateContext } from '../../contexts/templateContext';
import { useResumeSectionsContext } from '../../contexts/resumeSectionsContext';
import { useCoverLetterTemplateContext } from '../../contexts/coverLetterTemplateContext';
import { useCoverLetterDataContext } from '../../contexts/coverLetterDataContext';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { ResumeDocumentPDF, CoverLetterDocumentPDF } from '../download-document';
import { TemplatesDialog } from '../modal';
import { PayButtonMobile, PayButtonCoverLetterMobile } from '../pay-button';
import { PayWall } from '../pay-wall';


const MobileFooter = ({ handleToggleResumeTemplatesModal, handleToggleCoverLetterTemplatesModal, handleToggleResumePreview, handleToggleCoverLetterPreview }) => {
    
    const [showPayWallModal, setShowPayWallModal] = useState(false);
    const { resume } = useResumeDataContext();
    const {  template } = useTemplateContext();
    const { sections } = useResumeSectionsContext();
    const { coverLetterTemplate } = useCoverLetterTemplateContext();
    const { coverLetter } = useCoverLetterDataContext();
    const { sideBarTab, handleSelectTab } = useSideBarContext();

    const handleRedirectDocument = (path)=>{
        handleSelectTab(path)
    }

    const handleTogglePayWallModal = ()=>{
        setShowPayWallModal(!showPayWallModal);
      }
  
   

  return (
    <>
        <ul className='flex items-center justify-between gap-2'>
            {/* <li className='flex flex-col items-center text-xxsm'>
                <span><Home size={18}color='gray' /></span>
                Home
            </li> */}
            <li 
            onClick={()=>handleRedirectDocument(sideBarTab ==='edit cover letter' ? 'my cover letters' : 'my resumes')}
            className='flex flex-col items-center text-xxsm'>
                <span><Files size={18}color='gray' /></span>
                Documents
            </li>
            {
                sideBarTab ==='edit cover letter' ? (
                    <li 
                    onClick={handleToggleCoverLetterTemplatesModal}
                    className='flex flex-col items-center text-xxsm'>
                        <span><LayoutTemplate size={18}color='gray' /></span>
                        Templates
                    </li>
                ):(
                    <li 
                    onClick={handleToggleResumeTemplatesModal}
                    className='flex flex-col items-center text-xxsm'>
                        <span><LayoutTemplate size={18}color='gray' /></span>
                        Templates
                    </li>
                )
            }
            {
                sideBarTab ==='edit cover letter' ?(
                    <li className='flex flex-col items-center text-xxsm'
                    onClick={handleToggleCoverLetterPreview}
                    >
                        <span><ScanSearch size={18}color='gray' /></span>
                        Preview
                    </li>
                ):(
                    <li className='flex flex-col items-center text-xxsm'
                        onClick={handleToggleResumePreview}
                    >
                        <span><ScanSearch size={18}color='gray' /></span>
                        Preview
                    </li>
                )
            }
            
            <li className='flex flex-col items-center text-xxsm'>
                <span><Download size={18}color='gray' /></span>
            {
                sideBarTab ==='edit cover letter' 
                ? 
                (
                // <PDFDownloadLink
                //     document={<CoverLetterDocumentPDF coverLetterTemplate={coverLetterTemplate} data={coverLetter} />}
                //     fileName={`-cover-letter.pdf`}
                //     style={{ textDecoration: "none" }}
                // >
                //     <button>Download</button>
                // </PDFDownloadLink>
                <PayButtonCoverLetterMobile 
                    coverLetterTemplate={coverLetterTemplate}
                    data={coverLetter} 
                    handleTogglePayWallModal={handleTogglePayWallModal}
                 />
                )
                : (
                <PayButtonMobile template={template} resume={resume} sections={sections} handleTogglePayWallModal={handleTogglePayWallModal} />
                )
            }
            </li>
        </ul>
        <PayWall 
            handleTogglePayWallModal={handleTogglePayWallModal} 
            showPayWallModal={showPayWallModal}
            payWallText='Free Plan Limit Exausted!'  
        />
    </>
  )
}

export default MobileFooter