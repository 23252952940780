import React from 'react'
import { motion } from 'framer-motion';
import { useSideBarContext } from '../../../contexts/sideBarTabsContext';
import { useResumeFormSectionContext } from '../../../contexts/resumeFormSectionContext';

const CardResume01 = ({ data, sections }) => {
    const { handleSelectTab } = useSideBarContext();
    const { handleSelectedSection } = useResumeFormSectionContext();



    const handleEditSection = (sectionName)=>{
        handleSelectTab('edit resume')
        handleSelectedSection(sectionName)
    }

    const summaryComp = ()=>{
        return  <div className='cursor-pointer hover:border  p-2 rounded-md font-primary mb-5'>
        {data?.summary && (<div className='mt-3 '>
            <h2 
                className='text-xxxsm font-normal pb-1 pt-2 flex items-center uppercase border-b border-blue border-t border-t-topStroke'
            
            >
                summary
            </h2>
            
            <p className=' mt-1 text-[9px] md:text-xxxsm'>{data?.summary?.summary?.length > 150 ? data?.summary?.summary?.slice(0, 150) + '...' : data?.summary?.summary}</p>
        </div>)}
    </div>
    }

    const workExperienceComp = ()=>{
        return  <div className='cursor-pointer hover:border  p-2 rounded-md' onClick={()=>handleEditSection('EXPERIENCE')}>
        {
            data?.workExperience?.workExperienceArray?.length > 0 && (
                <>
                    <h2 
                    className='font-bold pb-1 pt-2 flex items-center uppercase border-b border-blue border-t border-t-topStroke'
                    >
                        Work Experience
                    </h2>
                    {
                        data?.workExperience?.workExperienceArray?.map((experience)=>(
                            <div key={experience?._id} className=''>
                                <h3 className=' font-bold pb-1 pt-2 flex items-center capitalize'>
                                    { experience?.title && experience?.title } 
                                    { experience?.company &&  ' | ' + experience?.company}  
                                    { experience?.location &&  ' | ' + experience?.location }
                                    { experience?.startDate && ' | ' + experience?.startDate } 
                                    { experience?.endDate && ' - ' + experience?.endDate} 
                                    </h3>
                                <div className=''>
                                    <ul className='text-gray-600'>
                                        {experience?.bulletPoints.map((points, index)=> (
                                            <li key={index}>{points}</li>
                                        ))}
                                    </ul>
                                </div> 
                            </div>
                        ))
                    }
                </>
            )
        }
    </div>
    }

    const educationComp = ()=>{
        return <div className='cursor-pointer hover:border  p-2 rounded-md' onClick={()=>handleEditSection('EDUCATION')}>
        {
            data?.education?.educationArray?.length > 0 && (
                <>
                    <h2 
                    className='font-bold pb-1 pt-2 flex items-center uppercase border-b border-blue border-t border-t-topStroke'
                    >
                        EDUCATION
                    </h2>
                    {
                        data?.education?.educationArray?.map((education)=>(
                            <div key={education._id} className=''>
                                <h3 className=' font-bold pb-1 pt-2 flex items-center capitalize'>
                                    { education?.fieldOfStudy && education?.fieldOfStudy } 
                                    { education.institution && ' | ' + education.institution }  
                                    { education?.location && ' | ' + education?.location }
                                    { education?.year && ' | ' + education?.year }
                                    </h3>
                            </div>
                        ))
                    }
                </>
            )
        }
    </div>
    }
    const certificationsComp = ()=>{
        return <div className='cursor-pointer hover:border  p-2 rounded-md' onClick={()=>handleEditSection('CERTIFICATIONS')}>
        {
            data?.certifications?.certificationsArray?.length > 0 && (
                <>
                    <h2 
                    className='font-bold pb-1 pt-2 flex items-center uppercase border-b border-blue border-t border-t-topStroke'
                    >
                        CERTIFICATIONS
                    </h2>
                    {
                        data?.certifications?.certificationsArray?.map((certification)=>(
                            <div key={certification._id} className=''>
                                <h3 className=' font-bold pb-1 pt-2 flex items-center capitalize'>
                                    { certification?.certificationName && certification?.certificationName } 
                                    { certification.issuingOrganization && ' | ' + certification.issuingOrganization }  
                                    {/* { certification?.certificationUrl && ' | ' + certification?.certificationUrl } */}
                                    { certification?.year && ' | ' + certification?.year }
                                    </h3>
                            </div>
                        ))
                    }
                </>
            )
        }
    </div>
    }

    const skillsComp = ()=>{
        return <div className='cursor-pointer hover:border  p-2 rounded-md' onClick={()=>handleEditSection('SKILLS')}>
        {
            data?.skills?.skillsArray?.length > 0 && (
            <>
                <h2 
                    className='font-bold pb-1 pt-2 flex items-center uppercase border-b border-blue border-t border-t-topStroke'
                >
                    Skills
                </h2> 
                <div className='mt-2'>
                    {data?.skills && data?.skills?.skillsArray?.map((skill, index)=>(
                        <span className=' font-bold capitalize pt-2' key={index}>
                            {index === data.skills?.length - 1 ? skill.skill + '.' : skill.skill + ', '}
                        </span>
                    ))}
                </div>
            </>)
        }
    </div>
    }

    const renderSection = (section) => {
        switch (section) {
            case 'SUMMARY':
                return summaryComp();
            // case 'EXPERIENCE':
            //     return workExperienceComp();
            // case 'EDUCATION':
            //     return educationComp();
            // case 'CERTIFICATIONS':
            //     return certificationsComp();
            // case 'SKILLS':
            //     return skillsComp();
            default:
                return null;
        }
    };

  return (
    <motion.div 
    initial={{ x: -100, opacity: 0, scale: 0.8 }} // Initial animation state
    animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to apply
    transition={{ type: 'spring', stiffness: 80, damping: 10 }} // Animation transition
    className=''>
    <div className='font-resume text-xxxsm'>
        <h1 className='text-center text-xxsm leading-[36px] font-bold capitalize'>{data?.name}</h1>
        {sections.map((section, index) => (
            <div key={`${section?.name}-${index}`}>
                {renderSection(section?.name)}
            </div>
        ))}
        
        </div>
</motion.div>
  )
}

export default CardResume01