import React from 'react';
import { Hero, Hero2 } from '../hero';
import { PageSwitcher } from '../page-switcher';
import Partners from './Partners';
import ActionHero from './ActionHero';
import { ShuffleHero } from '../hero';

const Sections = ({ isLoggedIn }) => {
  return (
    <>
        <Hero2 isLoggedIn={isLoggedIn}/>
        {/* <ShuffleHero /> */}
        <PageSwitcher />
        <Partners />
        <ActionHero isLoggedIn={isLoggedIn}/>
    </>
  )
}

export default Sections