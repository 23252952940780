
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Trash2, FilePenLine, Plus } from 'lucide-react';
import { toast } from 'sonner';
import { useCoverLetterItemContext } from '../../contexts/coverLetterItemContext';
import { useCoverLetterDataContext } from '../../contexts/coverLetterDataContext';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { useGetAllCoverLetters } from '../../hooks/get/useGetAllCoverLetters';
import { useDeleteCoverLetter } from '../../hooks/delete/useDeleteCoverLetter';
import emptyResumeItems2  from '../../assets/svg/empty-resume-items-2.svg'
import { EmptyResume } from '../../components/placeholders';
import { ResumeListSkeleton } from '../../components/loader';
import { CardCoverLetter } from '../../components/cover-letters/card-coverletter';

const CoverLetters = () => {
    const [coverLetters, setCoverLetters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingDeleteCoverLetter, setLoadingDeleteCoverLetter] = useState(false);
    const [selectedCoverLetter, setSelectedCoverLetter] = useState(null);
    const getCoverLetters = useGetAllCoverLetters();
    const deleteCoverLetter = useDeleteCoverLetter();
    const { setSelectedCoverLetterId } = useCoverLetterItemContext();
    const { coverLetter } = useCoverLetterDataContext();
    const { handleSelectTab } = useSideBarContext();

    const handleSelectedTab = ()=>{
        handleSelectTab('create cover letter')
    }
    const handleEditSelectedTab = ()=>{
        handleSelectTab('edit cover letter')
    }

    const handleSelectedId = (id)=>{
        setSelectedCoverLetterId(id)
    }

    const handleSelectedCoverLetter = (resumeId)=>{
        setSelectedCoverLetter(resumeId)
    }
 

    async function getAllCoverLetters(){
        setLoading(true)
        setTimeout(async()=>{
            try {
                const { data, status } = await getCoverLetters();
                if(status===200){
                    setCoverLetters(data?.data)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        },2000)
    };
    async function getAllCoverLettersItemsNoReLoad(){
        try {
            const { data, status } = await getCoverLetters();
            if(status===200){
                setCoverLetters(data?.data)
            }
        } catch (error) {
            toast.error('an error occured, try again')
        }
    };

    const handleDeleteCoverLetter = async(coverLetterId)=>{
        setLoadingDeleteCoverLetter(true)
            setTimeout(async()=>{
                try {
                    const { status, data } = await deleteCoverLetter(coverLetterId);
                    if(status===200){
                        getAllCoverLettersItemsNoReLoad();
                        toast.success(data?.message)
                    }
                    setLoadingDeleteCoverLetter(false)
                } catch (error) {
                    setLoadingDeleteCoverLetter(false)
                    toast.error('an error occured, try again')
                }
        },1500)
    }

    useEffect(()=>{
        getAllCoverLetters();
    },[])

    
   
  
  return (
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }} 
      animate={{ x: 0, opacity: 1, scale: 1 }} 
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
      className=''
    >
     <div className='mb-[6rem]'>
        {
            loading ? <div className='grid grid-cols-2 gap-3'>
                {Array.from({ length: 6}).map((_, i) => (
                <ResumeListSkeleton key={i} />
              ))}
            </div> : (
                <>
                    {
                        coverLetters && coverLetters.length > 0 
                        ? (
                        <div className='h-full grid grid-cols-2 gap-4'>
                            {
                                coverLetters.map((coverLetter, i)=>(
                                <div
                                    key={coverLetter?._id} 
                                        className='w-full h-full p-3 my-3 bg-white shadow-md 
                                        rounded-md relative hover:bg-[rgba(0,0,0,0.05)]'
                                    >
                                    <div className=''>
                                        {/* <img src={kara} alt="" className='shadow-md' /> */}
                                        <CardCoverLetter coverLetter={coverLetter}/>
                                    </div>
                                    <div className='w-full absolute  bottom-0 left-0 child flex items-center justify-between duration-200 md:p-3 gap-3 border-t bg-gray-50'>
                                        <h3 className='text-xxsm md:text-xsm capitalize'>{coverLetter?.companyName 
                                        ? (coverLetter?.companyName.length > 16 
                                        ? coverLetter?.companyName.slice(0, 15) + ' ...' 
                                        : coverLetter?.companyName) : `cover-letter-#${i+1}`}</h3>
                                        <div className='flex items-center gap-3'>
                                            <span onClick={
                                                ()=>{
                                                    handleSelectedId(coverLetter._id)
                                                    handleEditSelectedTab()
                                                }
                                                } className='p-2 rounded-full bg-white cursor-pointer shadow-lg tooltip' data-tip="edit">
                                                <FilePenLine size={15} color='gray'/>
                                            </span>
                                            <span
                                            onClick={()=>{
                                                handleDeleteCoverLetter(coverLetter._id)
                                                handleSelectedCoverLetter(coverLetter._id)
                                            }}
                                                className='p-2 rounded-full bg-red-100 shadow-xl cursor-pointer tooltip'
                                                data-tip="delete"
                                            >
                                                {
                                                    loadingDeleteCoverLetter && selectedCoverLetter === coverLetter._id 
                                                    ? <span className="loading loading-spinner loading-xs"></span> 
                                                    :<Trash2 size={15} color='red' />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                        ) 
                        : (
                        <>
                            <EmptyResume 
                                image={emptyResumeItems2}
                            >
                                <h3 className='mb-3'>Your cover letter folder is empty</h3>
                                <button className='w-full flex items-center justify-center gap-2 text-xsm bg-[#e3e3e3] px-3 py-2 
                                rounded-full cursor-pointer'
                                    onClick={handleSelectedTab}
                                >
                                    <span><Plus size={15}/></span> create cover letter
                                </button>
                            </EmptyResume>
                        </>)
                    }
                </>
            )
        }
    </div>
    </motion.div>
  );
};

export default CoverLetters;
