


import React, { useState, useRef } from 'react';
import { toast } from 'sonner';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CoverLetterDocumentPDF } from '../download-document';
import { usevalidateCoverLetterPayment } from '../../hooks/post/usevalidateCoverLetterPayment';
import { handleCoverLetterTypeDownloaded } from '../../services/heapAnalytics';

const PayButtonCoverLetterMobile = ({ coverLetterTemplate, coverLetter, handleTogglePayWallModal }) => {
    const [loading, setLoading] = useState(false);
    const validatePayment = usevalidateCoverLetterPayment();
    const downloadLinkRef = useRef();

    const handleMakeApiCall = async () => {
        try {
            setLoading(true);
            const { status } = await validatePayment();
            if (status === 200) {
                toast.success('Resume downloaded successfully');
                downloadLinkRef.current.click();
                handleCoverLetterTypeDownloaded(coverLetterTemplate);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                handleTogglePayWallModal();
                toast.error(`${error.response.data.message}`);
            } else if (error.request) {
                toast.error('No response from server. Please try again later.');
            } else {
                toast.error(`${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <button 
                disabled={loading} 
                onClick={handleMakeApiCall}
            >
                { loading ? <h2 className='px-6'><span className="loading loading-spinner loading-xs"></span></h2> : <span>Download</span>}
            </button>
            <PDFDownloadLink
                document={<CoverLetterDocumentPDF coverLetterTemplate={coverLetterTemplate} data={coverLetter} />}
                fileName={`${coverLetter?.lastName}-${coverLetter?.firstName}-resume.pdf`}
                style={{ textDecoration: 'none' }}
            >
                {({ loading: pdfLoading }) => (
                    <a
                        ref={downloadLinkRef}
                        style={{ display: 'none' }}
                    />
                )}
            </PDFDownloadLink>
        </>
    );
};

export default PayButtonCoverLetterMobile;
