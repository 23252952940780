
import axios from 'axios';
import { baseURL } from '../../services/baseURL.service';

export const useLinkedinAuth = () => {
  return async()=>{
    try {
        const response = await axios.get(`${baseURL}/auth/linkedin`);
        return response
    } catch (error) {
        throw error
    }
  }
}

