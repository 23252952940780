
import tokenService from "./token.service"


export const handleResumeTypeDownloaded = (templateType)=>{
    window.heap.track('RESUME TEMPLATE TYPE', {
        email: tokenService.getUser().email,
        resumeTemplateType:templateType
    })
}
export const handleCoverLetterTypeDownloaded = (templateType)=>{
    window.heap.track('COVER LETTER TEMPLATE TYPE', {
        email: tokenService.getUser().email,
        resumeTemplateType:templateType
    })
}