


import React, { useState, useRef } from 'react';
import { toast } from 'sonner';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CoverLetterDocumentPDF } from '../download-document';
import { PdfDownloadButton } from '../button/PdfDownloadBtn';
import { usevalidateCoverLetterPayment } from '../../hooks/post/usevalidateCoverLetterPayment';
import { handleCoverLetterTypeDownloaded } from '../../services/heapAnalytics';

const PayButtonCoverLetter = ({ coverLetterTemplate, coverLetter, handleTogglePayWallModal }) => {
    const [loading, setLoading] = useState(false);
    const validatePayment = usevalidateCoverLetterPayment();
    const downloadLinkRef = useRef();

    const handleMakeApiCall = async () => {
        setLoading(true);
        setTimeout(async()=>{
            try {
                const { status } = await validatePayment();
                if (status === 200) {
                    toast.success('Cover Letter downloaded successfully');
                    downloadLinkRef.current.click();
                    handleCoverLetterTypeDownloaded(coverLetterTemplate);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.data) {
                    handleTogglePayWallModal();
                    toast.error(`${error.response.data.message}`);
                } else if (error.request) {
                    toast.error('No response from server. Please try again later.');
                } else {
                    toast.error(`${error.message}`);
                }
            }
        },2000)
    };

    return (
        <>
            <PdfDownloadButton loading={loading} onClick={handleMakeApiCall} />
            <PDFDownloadLink
                document={<CoverLetterDocumentPDF coverLetterTemplate={coverLetterTemplate} data={coverLetter} />}
                fileName={`${coverLetter?.lastName}-${coverLetter?.firstName}-resume.pdf`}
                style={{ textDecoration: 'none' }}
            >
                {({ loading: pdfLoading }) => (
                    <a
                        ref={downloadLinkRef}
                        style={{ display: 'none' }}
                    />
                )}
            </PDFDownloadLink>
        </>
    );
};

export default PayButtonCoverLetter;
