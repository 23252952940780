import React from 'react';
import { Link } from 'react-router-dom';
import { HomeLink } from '../action-btns';

const NavBar = ({ isLoggedIn, handleLogout }) => {
  
  return (
    <div className="font-primary bg-dark flex justify-between items-center">
        <div className="pl-3 md:pl-6">
            <div className="">
            </div>
            <HomeLink color='white'/>
        </div>
        <div className="h-full">
        {isLoggedIn ? (
          <button
            className='h-full px-10 py-4 bg-yellow/40 font-bold
                 text-white hover:bg-light hover:text-dark duration-500 ease-in text-xsm md:text-sm'
            onClick={handleLogout}
          >
            Log Out
          </button>
        ) : (
          <Link to="/sign-in">
            <button
              className='h-full px-10 py-4 bg-yellow/40 font-bold text-white
                  hover:bg-light hover:text-dark duration-500 ease-in text-xsm md:text-sm'
            >
              Login
            </button>
          </Link>
        )}
        </div>
    </div>
  )
}

export default NavBar