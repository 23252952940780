import { FaTimesCircle } from 'react-icons/fa';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';


const CoverLetterTemplatesDialog = ({ children, modalOpen, modalClose }) => {
   
  return (
    <>
        {
            modalOpen && (
                <div className='w-full h-screen absolute top-0 left-0 overflow-y-auto'>
                    <motion.div 
                    initial={{ 
                        x: -100, 
                        opacity: 0, 
                        // scale: 0.8 
                    }} 
                    animate={{ x: 0, opacity: 1, scale: 1 }} 
                    transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
                    className='md:h-3/4 md:w-5/12 bg-white shadow-md rounded-md relative'>
                        <span onClick={modalClose} className="cursor-pointer absolute right-3 top-5 text-slate-500 bg-white p-3 rounded-full shadow-lg"><X /></span>
                        <div className='p-5'>
                            { children }
                        </div>
                    </motion.div>
                </div>
            )
        }
    </>
  )
}

export default CoverLetterTemplatesDialog