import coreClient from "../../services/coreApi";
export const useUpdateResume = () => {
  return async(resumeId, payload)=>{
    try {
        const res = await coreClient.put(`/resume/${resumeId}/update`, payload);
        return res;
    } catch (error) {
        throw error
    }
  }
}

