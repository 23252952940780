import React from 'react'
import { motion } from 'framer-motion';
import { useSideBarContext } from '../../../contexts/sideBarTabsContext';
import { useResumeFormSectionContext } from '../../../contexts/resumeFormSectionContext';
import { HoverToEdit } from '../../hover';

const PreviewResume08 = ({ data, sections }) => {
    const { handleSelectTab } = useSideBarContext();
    const { handleSelectedSection } = useResumeFormSectionContext();


    const handleEditSection = (sectionName)=>{
        handleSelectTab('edit resume')
        handleSelectedSection(sectionName)
    }

    const summaryComp = ()=>{
        return  <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('SUMMARY')}>
        {data?.summary && (<div className='mt-3 '> 
            <p className=' mt-1 text-xxxsm'>{data.summary.summary}</p>
            <HoverToEdit />
        </div>)}
    </div>
    }

    const workExperienceComp = ()=>{
        return  <div className='cursor-pointer relative group  p-2 rounded-md text-xxxsm' onClick={()=>handleEditSection('EXPERIENCE')}>
        {
            data?.workExperience?.workExperienceArray?.length > 0 && (
                <>
                    <h2 
                    className='text-[12px] font-normal pb-3 mb-3 pt-2 flex  text-[#14171ac1] uppercase border-b border-dark text-lightDark'
                    >
                        Work Experience
                    </h2>
                    {
                        data?.workExperience?.workExperienceArray?.map((experience)=>(
                            <div key={experience?._id} className=''>
                                <h3 className=' font-medium pt-2 flex items-center capitalize text-lightDark'>
                                    { experience?.title && experience?.title } 
                                    { experience?.company &&  ' / ' + experience?.company}  
                                </h3>
                                <h3 className=' font-medium pb-1 flex items-center capitalize text-lightDark'>
                                    { experience?.location &&  experience?.location }
                                    { experience?.startDate && ' / ' + experience?.startDate } 
                                    { experience?.endDate && ' - ' + experience?.endDate} 
                                </h3>
                                <div className=''>
                                    <ul className='text-gray-600'>
                                        {experience?.bulletPoints.map(points => (
                                            <li>{points}</li>
                                        ))}
                                    </ul>
                                </div> 
                            </div>
                        ))
                    }
                </>
            )
        }
        <HoverToEdit />
    </div>
    }
    const projectsComp = ()=>{
        return  <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('EXPERIENCE')}>
        {
            data?.workExperience?.workExperienceArray?.length > 0 && (
                <>
                    <h2 
                    className='text-[12px] font-normal pb-3 mb-3 pt-2 flex  text-[#14171ac1] uppercase'
                    style={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.057)",
                    }}
                    >
                        Projects
                    </h2>
                    {
                        data?.workExperience?.workExperienceArray?.map((experience)=>(
                            <div key={experience?._id} className=''>
                                <h3 className=' font-bold pb-1 pt-2 flex items-center capitalize'>
                                    { experience?.title && experience?.title } 
                                    <span className='font-light ml-1'>{ experience?.company &&  ' | ' + experience?.company} </span> 
                                    <span className='font-light ml-1'>{ experience?.location &&  ' | ' + experience?.location }</span>
                                </h3>
                                <div className=''>
                                    <ul className='text-gray-600'>
                                        {experience?.bulletPoints.map(points => (
                                            <li>{points}</li>
                                        ))}
                                    </ul>
                                </div> 
                            </div>
                        ))
                    }
                </>
            )
        }
        <HoverToEdit />
    </div>
    }

    const educationComp = ()=>{
    return <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('EDUCATION')}>
        <h2
            className='text-[12px] font-normal pb-3 mb-3 pt-2 flex uppercase mt-5 text-lightDark'
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.057)",
            }}
            
          >
            Education
          </h2>
          {data?.education?.educationArray?.map((item, index) => {
            return (
              <div key={index} className='mb-5'>
                {item.institution !== undefined && item.institution && (
                  <h4 className='font-normal  text-xxsm text-lightDark'>
                    {item.institution}
                  </h4>
                )}
                {item.degree !== undefined && item.degree && (
                  <h4 className='  text-xxxsm'>{item.degree}</h4>
                )}
                {item.fieldOfStudy !== undefined && item.fieldOfStudy && (
                  <h4 className='  text-xxxsm'>
                    {item.fieldOfStudy}
                  </h4>
                )}
                {item.location !== undefined && item.location && item.year && (
                  <h4 className='  text-xxxsm text-lightDark'>
                    {item.location}, {item.year}
                  </h4>
                )}
              </div>
            );
          })}
          <HoverToEdit />
    </div>
    }

    const certificationsComp = ()=>{
        return <div className='cursor-pointer relative group  p-2 rounded-md text-xxxsm' onClick={()=>handleEditSection('CERTIFICATIONS')}>
        {
            data?.certifications?.certificationsArray?.length > 0 && (
                <>
                    <h2 
                    className='text-[12px] font-normal pb-3 mb-3 pt-2 flex  text-[#14171ac1] uppercase border-b border-dark text-lightDark'
                    >
                        CERTIFICATIONS
                    </h2>
                    {
                        data?.certifications?.certificationsArray?.map((certification)=>(
                            <div key={certification._id} className=''>
                                <h3 className=' font-medium pb-1 pt-2 flex items-center capitalize text-lightDark'>
                                    { certification?.certificationName && certification?.certificationName } 
                                    { certification.issuingOrganization && ' | ' + certification.issuingOrganization }  
                                    {/* { certification?.certificationUrl && ' | ' + certification?.certificationUrl } */}
                                    { certification?.year && ' | ' + certification?.year }
                                    </h3>
                            </div>
                        ))
                    }
                </>
            )
        }
        <HoverToEdit />
    </div>
    }

    const skillsComp = ()=>{
    return <div onClick={()=>handleEditSection('SKILLS')} className='cursor-pointer relative group  p-2 rounded-md'>
                <h2
                    className='text-[12px] font-normal pb-3 mb-3 pt-2 flex uppercase mt-5 text-lightDark'
                    style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.057)",
                    }}
                   
                >
                    skills
                </h2>
            <ul className='list-disc pl-5 '>
                {data?.skills && data?.skills?.skillsArray?.map((skill, index) => {
                return (
                    <li key={index} className='text-xxsm'>
                    {skill.skill !== undefined && skill.skill}
                    </li>
                );
                })}
            </ul>
            <HoverToEdit />
        </div>
    }

    const renderRight = (section) => {
        switch (section) {
            case 'EDUCATION':
                return educationComp();
            case 'SKILLS':
                return skillsComp();
            default:
                return null;
        }
    };

    const renderLeft = (section)=>{
        switch (section) {
            case 'SUMMARY':
                return summaryComp();
            case 'EXPERIENCE':
                return workExperienceComp();
            case 'CERTIFICATIONS':
                return certificationsComp();
            default:
                return null;
        }
    }

  return (
    <motion.div 
    initial={{ x: -100, opacity: 0, scale: 0.8 }} // Initial animation state
    animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to apply
    transition={{ type: 'spring', stiffness: 80, damping: 10 }} // Animation transition
    className=''>
        <div className='w-full font-Montserrat flex gap-5 '>
            <div className='bg-[#f9f9f9] p-4 w-2/6  text-xxxsm break-words '>
                <h2
                    className='text-[12px] font-normal pb-3 mb-3 pt-2 flex uppercase text-lightDark'
                    style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.057)",
                    }}
                >
                    contact
                </h2>
                <ul className='list-disc pl-5 '>
                    {data.address !== undefined && data?.address && (
                    <li className='text-xxsm'>{data?.address}</li>
                    )}
                    {data.email !== undefined && data?.email && (
                    <li className='text-xxsm'>{data?.email}</li>
                    )}
                    {data.phone !== undefined && data?.phone && (
                    <li className='text-xxsm'>{data?.phone}</li>
                    )}
                    {data.linkedin !== undefined && data?.linkedin && (
                    <li className='text-xxsm'>{data?.linkedin}</li>
                    )}
                </ul>
                {sections.map((section, index) => (
                    <div key={`${section?.name}-${index}`}>
                        {renderRight(section?.name)}
                    </div>
                ))}
            </div>
            <div className='w-4/6'>
                <div className=' text-left '>
                    <h1 className='text-[28px] leading-[36px] font-smbold capitalize text-[#14171ac1] '>{data?.lastName} {data?.firstName}</h1>
                    <h1 className='text-[18px] leading-[36px] font-normal capitalize  text-lightDark'>{data?.jobTitle}</h1>
                    {sections.map((section, index) => (
                        <div key={`${section?.name}-${index}`}>
                            {renderLeft(section?.name)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
</motion.div>
  )
}

export default PreviewResume08