import React, { useState, createContext, useContext } from 'react'
import { useGetPaymentDetails } from '../hooks/get/useGetPaymentDetails';

const BillingContext = createContext();

export const BillingContextProvider = ({ children }) => {
    const { getPaymentDetails } = useGetPaymentDetails();
    const [paymentDetails, setPaymentDetails] = useState(null)


    const fetchPaymentDetails = async () => {
        try {
          const { status, data } = await getPaymentDetails();
          if (status === 200) {
            setPaymentDetails(data?.data);
          }
        } catch (error) {
          //throw error;
          return  error
        }
      };

  return (
    <BillingContext.Provider value={{
        fetchPaymentDetails,
        paymentDetails,
    }}>
        { children }
    </BillingContext.Provider>
  )
}

export const useBillingContext = ()=> useContext(BillingContext)

