// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import tokenService from "../services/token.service";


// export const AuthorizedPage = ({ children }) => {

//   const navigateTo = useNavigate();
//  const user  = tokenService.getUser();
 

//   useEffect(() => {
//     if (!user?.email) {
//       navigateTo("/sign-in");
//     }
//   }, [user?.email, navigateTo]);

//   if (user?.email) {
//     return children;
//   }

//   return null;
// };


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tokenService from "../services/token.service";

export const AuthorizedPage = ({ children }) => {
  const navigateTo = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const fetchedUser = await tokenService.getUser();
      setUser(fetchedUser);
      setLoading(false);
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (!loading && !user?.email) {
      navigateTo("/sign-in");
    }
  }, [user?.email, loading, navigateTo]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user?.email) {
    return children;
  }

  return null;
};


// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import tokenService from "../services/token.service";

// export const AuthorizedPage = ({ children }) => {
//   const navigateTo = useNavigate();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const checkAuthStatus = async () => {
//       const user = tokenService.getUser();
//       const refreshToken = tokenService.getLocalRefreshToken();
//       const accessToken = tokenService.getLocalAccessToken();

//       if (!refreshToken) {
//         tokenService.removeUser();
//         navigateTo("/sign-in");
//       } else if (accessToken && tokenService.isAccessTokenExpired(accessToken)) {
//         tokenService.removeUser();
//         navigateTo("/sign-in");
//       } else {
//         setLoading(false);
//       }
//     };

//     checkAuthStatus();
//   }, [navigateTo]);

//   if (loading) {
//     return <div>Loading...</div>; 
//   }

//   return children;
// };

