import { PageRouter } from "./routes";
import { Toaster } from 'sonner'
import { SideBarTabsContextProvider } from "./contexts/sideBarTabsContext";
import { ResumeItemContextProvider } from "./contexts/resumeItemContext";
import { CoverLetterItemContextProvider } from "./contexts/coverLetterItemContext";
import { ResumeDataContextProvider } from "./contexts/resumeDataContext";
import { CoverLetterDataContextProvider } from "./contexts/coverLetterDataContext";
import { TemplateContextProvider } from "./contexts/templateContext";
import { CoverLetterTemplateContextProvider } from "./contexts/coverLetterTemplateContext";
import { ResumeFormSectionContextProvider } from "./contexts/resumeFormSectionContext";
import { BillingContextProvider } from "./contexts/billingContext";
import { ResumeSectionsContextProvider } from "./contexts/resumeSectionsContext";
function App() {
  return (
    <>
      <Toaster 
      // richColors 
      position="top-right" />
      <SideBarTabsContextProvider>
        <ResumeItemContextProvider>
          <CoverLetterItemContextProvider>
            <ResumeDataContextProvider>
              <CoverLetterDataContextProvider>
                <TemplateContextProvider>
                  <CoverLetterTemplateContextProvider>
                    <ResumeFormSectionContextProvider>
                      <ResumeSectionsContextProvider>
                        <BillingContextProvider>
                          <PageRouter />
                        </BillingContextProvider>
                      </ResumeSectionsContextProvider>
                    </ResumeFormSectionContextProvider>
                  </CoverLetterTemplateContextProvider>
                </TemplateContextProvider>
              </CoverLetterDataContextProvider>
            </ResumeDataContextProvider>
          </CoverLetterItemContextProvider>
        </ResumeItemContextProvider>
      </SideBarTabsContextProvider>
    </>
  );
}

export default App;
