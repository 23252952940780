import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';

import MerriweatherRegular from "../../assets/fonts/Merriweather-Regular.ttf";
import MerriweatherItalic from "../../assets/fonts/Merriweather-Italic.ttf";
import MerriweatherBold from "../../assets/fonts/Merriweather-Bold.ttf";
import MerriweatherBoldItalic from "../../assets/fonts/Merriweather-BoldItalic.ttf";
import MerriweatherLight from "../../assets/fonts/Merriweather-Light.ttf";

import MontserratRegular from "../../assets/fonts/Montserrat-Regular.ttf";
import MontserratItalic from "../../assets/fonts/Montserrat-Italic.ttf";
import MontserratBold from "../../assets/fonts/Montserrat-Bold.ttf";
import MontserratBoldItalic from "../../assets/fonts/Montserrat-BoldItalic.ttf";
import MontserratLight from "../../assets/fonts/Montserrat-Light.ttf";


Font.register({
  family: "Merriweather",
  fonts: [
    { src: MerriweatherRegular, fontWeight: "regular"  },
    { src: MerriweatherItalic, fontStyle: "italic" },
    { src: MerriweatherBold, fontWeight: "bold" },
    { src: MerriweatherLight, fontWeight: "light" },
    { src: MerriweatherBoldItalic, fontStyle: "italic", fontWeight: "bold" },
  ],
});

Font.register({
  family: "Montserrat",
  fonts: [
    { src: MontserratRegular },
    { src: MontserratItalic, fontStyle: "italic" },
    { src: MontserratBold, fontWeight: "bold" },
    { src: MontserratLight, fontWeight: "light" },
    { src: MontserratBoldItalic, fontStyle: "italic", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 40,
  },
  section:{
    paddingTop:10,
    paddingBottom:10,
  },
  headerSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #283d56",
    paddingTop:10,
    paddingBottom:10,
  },
  sectionTitleText: {
    fontFamily: 'Montserrat',
    fontSize: 28,
    fontWeight: "bold",
    textTransform: "capitalize",
    textAlign: "center",
    color:'#14171ac1'
  },
  text: {
    fontSize:9,
    fontFamily: 'Montserrat',
    fontWeight: "normal",
  },
  contentText: {
    fontSize: 9,
    fontFamily: 'Montserrat',
    fontWeight: "normal",
    marginBottom: 8,
  },
  mediumText: {
    fontSize:9.5,
    fontFamily: 'Montserrat',
    fontWeight: "bold",
    textTransform: "capitalize",
    color: '#14171ac1'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});



const CoverLetterDoc01 = ({ data }) => {

  const letterContents = data?.coverLetterContent ? data.coverLetterContent.split('.').filter(Boolean).map(content => content.trim()) : [];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
          <View style={styles.headerSection}>
            <Text style={styles.sectionTitleText}>{data?.lastName} {data?.firstName}</Text>
            <View>
              <Text style={styles.text}>{data?.phone}</Text>
              <Text style={styles.text}>{data?.applicantCity}, {data?.applicantCountry} {data?.applicantZipCode ? '/' + data?.applicantZipCode : ''}</Text>
              <Text style={styles.text}>{data?.email}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={[styles.text, { fontWeight: 'bold', color:'#14171ac1'}]}>{data?.applicationDate}</Text>
          </View>
          <View style={styles.section}>
            <Text style={[styles.text, { fontWeight: 'bold', color:'#14171ac1'}]}>{data?.companyName}</Text>
            <Text style={[styles.text, { fontWeight: 'bold', color:'#14171ac1'}]}>{data?.companyCity}, {data?.companyCountry} {data?.companyZipCode ? '/' + data?.companyZipCode : ''}</Text>
          </View>
          {/* <View style={styles.section}>
            <Text style={styles.mediumText}>RE: {data?.jobTitle}</Text>
          </View> */}
          <View style={[styles.section, { marginVertical: 10 }]}>
              {letterContents.map((content, i)=>(
              <Text key={i} style={styles.contentText}>{content}{i !== letterContents.length - 1 ? '.' : ''}</Text>
            ))}
          </View>
          <View style={styles.section}>
            <Text style={styles.mediumText}>{data?.lastName} {data?.firstName}</Text>
          </View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          />
      </Page>
  </Document>
  )
}

export default CoverLetterDoc01