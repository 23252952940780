import React, { useState, createContext, useContext } from 'react'

const SideBarTabsContext = createContext();

export const SideBarTabsContextProvider = ({ children }) => {

    const [ sideBarTab, setSideBarTab] = useState('my resumes');

    const handleSelectTab = (tab)=>{
      setSideBarTab(tab)
  }

  return (
    <SideBarTabsContext.Provider value={{
        sideBarTab,
        setSideBarTab,
        handleSelectTab
    }}>
        { children }
    </SideBarTabsContext.Provider>
  )
}

export const useSideBarContext = ()=> useContext(SideBarTabsContext)

