// import React, { useState, useEffect } from 'react';
// import { toast } from 'sonner';
// import { useNavigate } from 'react-router-dom';
// import { Check, ChevronRight, Ban } from 'lucide-react';
// import { useLinkedinVerifyCode } from '../../hooks/auth/useLinkedinVerifyCode'
// import tokenService from '../../services/token.service';

// const LinkedinVerifyCode = () => {
//     const [loading, setLoading] = useState(false);
//     const [isCodelVerified, setIsCodelVerified] = useState(null);
//     const linkedinVerifyCode = useLinkedinVerifyCode();
//     const code  = new URL(window.location).searchParams.get('code');
//     const navigateTo = useNavigate();

//     function handleRedirect(){
//         navigateTo('/sign-in');
//     }

//     useEffect(()=>{
//         async function handleCallback(){
//             try {
//                 setLoading(true)
//                 const { status, data }  = await linkedinVerifyCode(code);
//                 if(status === 200){
//                     tokenService.setUser(data);
//                     navigateTo('/resume-dashboard');
//                     toast.success('successfully logged in');
//                     setIsCodelVerified(true);
//                 }
//             } catch (error) {
//                 setLoading(false);
//                 if (error.response && error.response.data) {
//                 toast.error(`${error.response.data.message}`);
//                 } else if (error.request) {
//                     toast.error('No response from server. Please try again later.');
//                 } else {
//                     toast.error(`Error: ${error.data.message}`);
//                 }
//             }finally{
//                 setLoading(false)
//             }
//         }
//         handleCallback();
//     },[code])
    
//   return (
//     <main className='h-screen flex justify-center items-center font-primary'>
//         {isCodelVerified === null ? (
//             <h2>Verifying account...</h2>
//         )  : (
//             <div className='w-6/12'>
//                 <div className='flex flex-col justify-center items-center'>
//                     <div className='p-1 border rounded-full'>
//                         <h3 className='p-1 shadow-lg bg-light rounded-full'>
//                             <p className='h-24 w-24 border flex items-center justify-center p-3 rounded-full bg-dark/60'>
//                                 <Ban color='red' />
//                             </p>
//                         </h3>
//                     </div>
//                 </div>
//                 <div className='text-center my-6'>
//                     <h3>
//                         Sorry, verification was not successful!
//                     </h3>
//                 </div>
//                 <button 
//                     onClick={handleRedirect}
//                     className='m-auto flex items-center justify-center gap-2 py-2 px-3 rounded-md hover:text-light duration-300 ease-in bg-white'>
//                     Continue to web
//                     <span><ChevronRight /></span>
//                 </button>
//             </div>
//         )}
//     </main>
//   )
// }

// export default LinkedinVerifyCode


import React, { useState, useEffect } from 'react';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { Check, ChevronRight, Ban } from 'lucide-react';
import { useLinkedinVerifyCode } from '../../hooks/auth/useLinkedinVerifyCode';
import tokenService from '../../services/token.service';

const LinkedinVerifyCode = () => {
    const [loading, setLoading] = useState(false);
    const [isCodeVerified, setIsCodeVerified] = useState(null);
    const linkedinVerifyCode = useLinkedinVerifyCode();
    const code = new URL(window.location).searchParams.get('code');
    const navigateTo = useNavigate();

    const handleRedirect = () => {
        navigateTo('/sign-in');
    };

    useEffect(() => {
        const handleCallback = async () => {
            setLoading(true);
            try {
                const { status, data } = await linkedinVerifyCode(code);
                if (status === 200) {
                    tokenService.setUser(data);
                    navigateTo('/resume-dashboard');
                    toast.success('Successfully logged in');
                    setIsCodeVerified(true);
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    toast.error(error.response.data.message);
                } else if (error.request) {
                    toast.error('No response from server. Please try again later.');
                } else {
                    toast.error(`Error: ${error.message}`);
                }
                setIsCodeVerified(false);
            } finally {
                setLoading(false);
            }
        };

        handleCallback();
    }, [code, linkedinVerifyCode, navigateTo]);

    return (
        <main className='h-screen flex justify-center items-center font-primary'>
            {loading ? (
                <h2>Verifying account...</h2>
            ) : isCodeVerified === true ? (
                <div className='w-6/12'>
                    <div className='flex flex-col justify-center items-center'>
                        <div className='p-1 border rounded-full'>
                            <h3 className='p-1 shadow-lg bg-light rounded-full'>
                                <p className='h-24 w-24 border flex items-center justify-center p-3 rounded-full bg-dark/60'>
                                    <Check color='green' />
                                </p>
                            </h3>
                        </div>
                    </div>
                    <div className='text-center my-6'>
                        <h3>Verification successful!</h3>
                    </div>
                </div>
            ) : (
                <div className='w-6/12'>
                    <div className='flex flex-col justify-center items-center'>
                        <div className='p-1 border rounded-full'>
                            <h3 className='p-1 shadow-lg bg-light rounded-full'>
                                <p className='h-24 w-24 border flex items-center justify-center p-3 rounded-full bg-dark/60'>
                                    <Ban color='red' />
                                </p>
                            </h3>
                        </div>
                    </div>
                    <div className='text-center my-6'>
                        <h3>Sorry, verification was not successful!</h3>
                    </div>
                    <button
                        onClick={handleRedirect}
                        className='m-auto flex items-center justify-center gap-2 py-2 px-3 rounded-md hover:text-light duration-300 ease-in bg-white'>
                        Continue to web
                        <span><ChevronRight /></span>
                    </button>
                </div>
            )}
        </main>
    );
};

export default LinkedinVerifyCode;
