

import React, { useState, useRef } from 'react';
import { toast } from 'sonner';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ResumeDocumentPDF } from '../download-document';
import { usevalidatePayment } from '../../hooks/post/usevalidatePayment';
import { handleResumeTypeDownloaded } from '../../services/heapAnalytics';

const PayButtonMobile = ({ template, resume, sections, handleTogglePayWallModal }) => {
    const [loading, setLoading] = useState(false);
    const validatePayment = usevalidatePayment();
    const downloadLinkRef = useRef();

    const handleMakeApiCall = async () => {
        try {
            setLoading(true);
            const { status } = await validatePayment();
            if (status === 200) {
                toast.success('Resume downloaded successfully');
                downloadLinkRef.current.click();
                handleResumeTypeDownloaded(template);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                handleTogglePayWallModal()
                toast.error(`${error.response.data.message}`);
            } else if (error.request) {
                toast.error('No response from server. Please try again later.');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
             <button 
                disabled={loading} 
                onClick={handleMakeApiCall}
            >
                { loading ? <h2 className='px-6'><span className="loading loading-spinner loading-xs"></span></h2> : <span>Download</span>}
            </button>
            <PDFDownloadLink
                document={<ResumeDocumentPDF template={template} data={resume} sections={sections} />}
                fileName={`${resume?.lastName}-${resume?.firstName}-resume.pdf`}
                style={{ textDecoration: 'none' }}
            >
                {({ loading: pdfLoading }) => (
                    <a
                        ref={downloadLinkRef}
                        style={{ display: 'none' }}
                    />
                )}
            </PDFDownloadLink>
        </>
    );
};

export default PayButtonMobile;
