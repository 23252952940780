import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import TemplatesLibrary3 from '../sections/TemplatesLibrary3';
import '../../assets/css/customFonts.css'



const buttonVariants = {
    hidden: { opacity: 0, x: -70 },
    visible: { opacity: 1, x: 0 },
};





const Hero2 = ({ isLoggedIn }) => {


  const controls = useAnimation();
  const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }
      
    const listItems = [
        'Resume',
        'Link',
    ]

  return (
    <div className='h-screen flex border-b border-2'>
       <div className='h-full w-1/2 relative font-primary hidden md:block'>
          <div className='h-full flex justify-center items-center'>
            <div className='h-9/12 flex justify-center items-center'>
              <TemplatesLibrary3 />
            </div>
            <div className='w-full h-full absolute top-0 left-0 flex justify-center items-center bg-dark/30'>
              <div className='rotate-90'>
                  <motion.h1 
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                      transition={{ duration: 1.2, delay: 0.2 }}
                      className='font-xbold text-lg md:text-[130px] 
                      leading-[3rem] md:leading-[7rem] text-white text-center '>
                          Resume
                  </motion.h1>
              </div>
            </div>
          </div>
       </div>
       <div className='h-full md:w-1/2 flex justify-center items-center font-primary'>
            <div className="hero-content text-center bg-white md:p-10 rounded-md">
              <div className="">
            <ul>
                {
                    listItems.map((item, index)=>(
                        <motion.li key={index}
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 0.8, delay: index }}
                        className={`font-xbold font-primary text-xl text-center md:text-[130px] 
                    leading-[3.5rem] md:leading-[6rem]`}
                    
                    >
                        {/* className={`font-xbold font-${item==='Link' ? 'playwrite' : 'primary'} text-lg text-center md:text-[130px] 
                    leading-[3rem] md:leading-[6rem]`}> */}
                           {item}
                        </motion.li>
                    ))
                }
            </ul>
              <div className='w-10/12 md:w-9/12 m-auto'>
                <motion.p 
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ duration: 1.2, delay: 0.3 }}
                  className="md:py-6 text-sm mt-10">
                  {/* Craft Your Future with Confidence <br />  */}
                  <span className='font-bold'>Professional Resumes Made Effortless and Impactful.</span> <br />
                  Transform Your Career Prospects with Tailored, High-Quality Resumes Designed to Stand Out.
                </motion.p>
                {
                  isLoggedIn ? (
                    <Link to="/resume-dashboard">
                      <motion.button
                        initial="hidden"
                        animate="visible"
                        variants={buttonVariants}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        className="w-full bg-ligh border border-dark
                        rounded-md hover:bg-dark hover:text-white text-sm duration-300 py-5 my-4 font-bold">
                        Proceed to Dashboard
                      </motion.button>
                    </Link>
                  ) : (
                    <Link to="/sign-up">
                      <motion.button
                        initial="hidden"
                        animate="visible"
                        variants={buttonVariants}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        className="w-full bg-ligh border border-dark
                        rounded-md hover:bg-dark hover:text-white text-sm duration-300 py-5 my-4 font-bold">
                        Create Resume
                      </motion.button>
                    </Link>
                  )
                }
              </div>
              </div>
          </div>
        </div>
    </div>
  )
}

export default Hero2