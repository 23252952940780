import React, { useState, useEffect } from 'react'
import { CircleCheck, MoveRight } from 'lucide-react';
import { toast } from 'sonner';
import { useCreateSubscription } from '../../hooks/post/useCreateSubscription';

const ListSubcriptionsCard = ({ subscriptions }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const { createSubscription, loading, error, success } = useCreateSubscription();

    const handleCheckout = async(planId)=>{
        try {
            const { status, data } = await createSubscription(planId); 
            if(status===200){
                window.location.href = data.data?.url
            }
          } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(`Error: ${error.response.data.error}`);
              } else if (error.request) {
                toast.error('No response from server. Please try again later.');
              } else {
                toast.error(`Error: ${error.message}`);
              }
          }
    }

  return (
    <>
        {subscriptions && subscriptions.length > 0 && (
            subscriptions.filter((sub)=>sub.name !== 'Premium Plan').map((subscription, index) => (
                <div key={subscription?.id} 
                className={`md:h-2/3 md:w-1/2 flex flex-col justify-between mt-3 md:mt-0 ${subscription.name==='Basic Plan' ? 'bg-[#EBEBEB]' : 'bg-dark text-white '} rounded-lg p-5`}>
                    <span className={`border px-3 py-1 text-xxsm text-center ${subscription.name==='Basic Plan' ? 'border border-dark' : ' text-white '} rounded-full md:w-2/3`}>
                    {subscription.name}
                    </span>
                    <h3 className='my-2'>unlimited</h3>
                    <hr className='border border-white'/>
                    {
                        subscription.features.map((feature, i)=>(
                        
                                <h3 className='w-full flex items-center gap-3 my-1 text-xsm'>
                                    <span><CircleCheck /></span> {feature?.name}
                                </h3>
                        
                        ))
                    }
                    <hr className='border border-white'/>
                    <div className='flex items-center justify-between gap-2 mt-5'>
                        <h3 className='font-bold'>${Number(subscription?.price) * 0.01} / month</h3>
                        <button 
                        onClick={()=>{
                            handleCheckout(subscription?.priceId)
                            setSelectedIndex(index)
                        }}
                        disabled={loading}
                        className='flex items-center px-4 py-2 gap-2 bg-white text-dark text-xxsm font-normal border-2 border-slate-500 rounded-full'>
                            {loading && selectedIndex === index  ? <span className="loading loading-spinner loading-xs"></span>  : <span className='flex items-center gap-1'>Buy Plan <MoveRight size={15} /></span>} 
                        </button>
                    </div>
                </div>
            ))
        )}
    </>
  )
}

export default ListSubcriptionsCard