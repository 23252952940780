
import { ResumeDoc01, ResumeDoc02, 
  ResumeDoc03, ResumeDoc03B,
  ResumeDoc004, ResumeDoc004B,
  ResumeDoc005, ResumeDoc005B,
  } from '../../components/resume-docs';

const ResumeDocumentPDF = ({ template, data, sections }) => {

  switch (template) {
    case 1:
      return <ResumeDoc01 data={data} sections={sections} />;
    case 2:
      return <ResumeDoc02 data={data} sections={sections} />;
    case 3:
      return <ResumeDoc03 data={data} sections={sections} />;
    case 4:
      return <ResumeDoc03B data={data} sections={sections} />;
    case 5:
      return <ResumeDoc005 data={data} sections={sections} />;
    case 6:
      return <ResumeDoc005B data={data} sections={sections} />;
    case 7:
      return <ResumeDoc004 data={data} sections={sections} />;
    case 8:
      return <ResumeDoc004B data={data} sections={sections} />;
    default:
      return null;
  }
};

export default ResumeDocumentPDF;
