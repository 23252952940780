import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'sonner'
import { BarChart4, Layers, Linkedin, MessageCircleMore } from 'lucide-react';
import { useListPlans } from '../../hooks/get/useListPlans';
import { useGetPaymentDetails } from '../../hooks/get/useGetPaymentDetails';
import { PaymentSkeleton } from '../../components/loader';
import { ListSubcriptionsCard, SubscriptionPlanCard } from '../../components/cards';

const appFeatures  = [
   
    {
        id:1,
        title:'Analysis',
        description:'Real Time Content Analysis',
        icon: <BarChart4 size={18} />
    },
    {
        id:2,
        title:'Sample Library',
        description:'Resumes, cover letters, and resignation letters.',
        icon: <Layers  size={18}/>
    },
    {
        id:3,
        title:'Linkedin Profile Importing',
        description:'Use your Linkedin Profile to create a resume in seconds.',
        icon: <Linkedin size={18} />
    },
    {
        id:4,
        title:'Ai Keywords targeting',
        description:'Improve your chances by using the AI targeted keywords.',
        icon: <MessageCircleMore  size={18}/>
    },
]


const Subscriptions = () => {
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState(null)
    const listPlans = useListPlans();
    
    const { getPaymentDetails, loading:loadingPaymentDetails, error:paymentDetailsError } = useGetPaymentDetails();

    useEffect(()=>{
        (async function (){
            try {
                setLoadingSubscription(true);
                const { status, data } = await listPlans();
                if(status===200){
                    setSubscriptions(data)
                }
            } catch (error) {
                console.log(error)
                return error
            }finally{
                setLoadingSubscription(false);
            }
        })()
    },[])


    useEffect(() => {
        async function fetchPaymentDetails(){
          try {
            const { status, data } = await getPaymentDetails();
            if(status===200){
                setPaymentDetails(data?.data)
            }
          } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
            //   toast.error(`Error: ${error.response.data.error}`);
             return error
            } else if (error.request) {
              toast.error('No response from server. Please try again later.');
            } else {
            //   toast.error(`Error: ${error.message}`);
             return error
            }
          }
        }
        fetchPaymentDetails();
      }, []);


  return (
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }} // Initial animation state
      animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to apply
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} // Animation transition
      className=' bg-white'
    >
      <div className='mb-[4rem] md:mb-0'>
        <div className='bg-light/900 hidden md:block'>
            <h3 className='text-center py-5 font-primary font-normal bg-[#EBEBEB]'>All the features for you to write <br /> A job winning Resume</h3>

            <div className='grid grid-cols-2 gap-3 font-primary p-3'>
                {
                    appFeatures.map((feature, i)=>(
                        <div className='flex flex-col justify-center items-center shadow-md p-2' key={i}>
                            <span>{feature.icon}</span>
                            <h3 className='font-normal text-xsm text-center'>{feature.title}</h3>
                            <p className='text-xxsm text-center'>{feature.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className='my-2 px-2'>
            <div className='h-full w-full flex flex-col md:flex-row gap-2  font-primary '>
                {
                loadingSubscription ? (
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mt-10'>
                        {
                            Array.from({ length: 2}).map((_, i)=>(
                                <PaymentSkeleton key={i} />
                            ))
                        }
                    </div>
                ) : (
                        <>
                        {
                            paymentDetails?.paymentStatus==='paid' ?
                            <SubscriptionPlanCard 
                                billing={paymentDetails}
                            />
                            : (
                                <>
                                    <ListSubcriptionsCard subscriptions={subscriptions}/>
                                </>
                            ) 
                        }
                        </>
                    )
                }
            </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Subscriptions;
