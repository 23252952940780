import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useDebouncedCallback } from "use-debounce";
import { toast } from 'sonner';
import { UserRound, NotebookText, BookCopy, BriefcaseBusiness, Landmark, Grip, ChevronUp, ChevronDown } from 'lucide-react';
import { useCoverLetterDataContext } from '../../contexts/coverLetterDataContext';
import { useCoverLetterItemContext } from '../../contexts/coverLetterItemContext';
import { useGetCoverLetter } from '../../hooks/get/useGetCoverLetter';
import { useUpdateCoverLetter } from '../../hooks/update/useUpdateCoverLetter';
import { CoverLetterPersonalInformationForm, CompanyInformationform, CoverLetterContentForm } from '../../components/section-forms/cover-letter-sections-form';

const coverLetterSections = [ {
  name:'PERSONAL INFORMATION',
  icon:<UserRound />,
  },
  {
    name:'COMPANY INFORMATION',
    icon:<NotebookText />,
  },
  {
    name:'COVERLETTER CONTENT',
    icon:<BookCopy />,
  },
]

const EditCoverLetter = () => {
  const {coverLetter, setCoverLetter } = useCoverLetterDataContext();
  const [selectedSection, setSelectedSection] = useState('');
  const [isActiveSection, setIsActiveSection] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { selectedCoverLetterId } = useCoverLetterItemContext();
  const getCoverLetter = useGetCoverLetter();
  const updateCoverLetter = useUpdateCoverLetter();

  const [formData, setFormData] = useState({
    applicationDate:'',
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    linkedin:'',
    jobTitle:'',
    applicantCountry:'',
    applicantState: '',
    applicantCity: '',
    applicantZipCode: '',
    companyName: '',
    companyCountry:'',
    companyState: '',
    companyCity: '',
    companyZipCode: '',
    coverLetterContent: ''
  });

  const handleChange = (e)=>{
    const { name, value } = e.target;
    setFormData({ ...formData, [name]:value });
    debouncedUpdateResumeData()
  }

  

    const handleSelectedSection = (sectionName)=>{
        setSelectedSection(sectionName); 
        setIsActiveSection(true); 
    }

    const debouncedUpdateResumeData = useDebouncedCallback(
      async () => {
          if (selectedCoverLetterId) {
              try {
                  setLoadingUpdate(true)
                  const { status, data } = await updateCoverLetter(selectedCoverLetterId, {
                    applicationDate:formData?.applicationDate,
                    firstName:formData?.firstName,
                    lastName:formData?.lastName,
                    email:formData?.email,
                    phone: formData?.phone,
                    linkedin: formData?.linkedin,
                    jobTitle: formData?.jobTitle,
                    applicantCountry:formData?.applicantCountry,
                    applicantState: formData?.applicantState,
                    applicantCity: formData?.applicantCity,
                    applicantZipCode: formData?.applicantZipCode,
                    companyName: formData?.companyName,
                    companyCountry:formData?.companyCountry,
                    companyState: formData?.companyState,
                    companyCity: formData?.companyCity,
                    companyZipCode: formData?.companyZipCode,
                    coverLetterContent:formData?.coverLetterContent
                  });
                  if(status===200){
                    setCoverLetter(data?.data)
                    // getCoverLetterData();
                  }
              } catch (error) {
                  console.log(error);
              }finally{
                setLoadingUpdate(false)
              }
          }
      },
      1000 
  );


  async function getCoverLetterData(){
    if(selectedCoverLetterId){
      try {
          const { status, data } = await getCoverLetter(selectedCoverLetterId);
          if(status===200){
            setFormData(prev =>({
              ...prev, 
              applicationDate:data?.data?.applicationDate,
              firstName:data?.data?.firstName,
              lastName:data?.data?.lastName,
              email:data?.data?.email,
              phone: data?.data?.phone,
              linkedin: data?.data?.linkedin,
              jobTitle: data?.data?.jobTitle,
              applicantCountry:data?.data?.applicantCountry,
              applicantState: data?.data?.applicantState,
              applicantCity: data?.data?.applicantCity,
              applicantZipCode: data?.data?.applicantZipCode,
              companyName: data?.data?.companyName,
              companyCountry:data?.data?.companyCountry,
              companyState: data?.data?.companyState,
              companyCity: data?.data?.companyCity,
              companyZipCode: data?.data?.companyZipCode,
              coverLetterContent:data?.data?.coverLetterContent
            }));
          }
      } catch (error) {
          return error
      }
    }
  }
  
    useEffect(()=>{
      getCoverLetterData();
  },[selectedCoverLetterId]);


  const renderSectionContents = ()=>{
    switch (selectedSection) {
      case 'PERSONAL INFORMATION':
          return <CoverLetterPersonalInformationForm
                  formData={formData}
                  handleChange={handleChange}
          />
      case 'COMPANY INFORMATION':
          return <CompanyInformationform
          formData={formData}
          handleChange={handleChange}
          />
      case 'COVERLETTER CONTENT':
          return <CoverLetterContentForm
          formData={formData}
          handleChange={handleChange}
          />
     
      default:
        break;
    }
  }
  return (
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }}
      animate={{ x: 0, opacity: 1, scale: 1 }}
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
      className=''
    >
      <div className='bg-white rounded-md shadow-lg p-4 font-primary mb-[10rem]'>
        <h3 className='my-5 text-sm font-normal'>Edit Your Cover Letter</h3>
        {
            coverLetterSections.map((section, index)=>(
              <div key={`${section.name}-${index}`}
                className='my-3'
              >
                <div 
                onClick={()=>handleSelectedSection(section?.name)}
                className='flex justify-between items-center py-3 px-3 cursor-pointer bg-[#f3f3f3] text-xsm'
                >
                  <h3 
                  className='flex items-center gap-3 cursor-pointer text-xxsm font-normal'>
                  <span className='cursor-grab'>
                    {section.name === 'PERSONAL INFORMATION' 
                    ? <UserRound/> 
                    : section.name === 'COMPANY INFORMATION' 
                    ? <BriefcaseBusiness size={20}/> : <NotebookText size={20} />}
                  </span>
                    {section.name}
                  </h3>
              
                </div>
                {
                  isActiveSection && selectedSection === section.name && (
                    <div className='border p-5 mt-2 rounded-md text-xxsm'>
                      { renderSectionContents()}
                    </div>
                  )
                }
              </div>
            ))
          }
      </div>
    </motion.div>
  )
}



export default EditCoverLetter