import React from 'react';
import { CalendarCheck } from 'lucide-react'

const SubscriptionPlanCard = ({ billing }) => {

    function convertDate(dateFormat){
        const date = new Date(dateFormat);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
            // timeZoneName: 'short'
          };
          const readableDate = date.toLocaleString('en-US', options);
          return readableDate

    }
  return (
    <div className='w-full font-primary'>
        <div className="card w-full bg-base-100 shadow-xl border border-slate-400">
            {/* <figure><img src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg" alt="Shoes" 
            className='h-48 w-full object-cover' /></figure> */}
            <div className="card-body">
            <h3 className='h-6 w-6 border p-5 rounded-full flex items-center justify-center '>
                <span><CalendarCheck /></span>
            </h3>
                <h2 className="card-title text-sm my-1">
                <span className="badge badge-ghost">{billing?.planName} </span>
                <span className="badge  text-xxsm">{convertDate(billing.createdAt)}</span>
                <span className="badge badge-ghost">{billing.paymentStatus} </span>
                </h2>
                <p className='text-xsm'>This is your current subscription plan, you can decide to take further actions below!</p>
                <div className="card-actions justify-end">
                    <button className='px-10 py-1 bg-dark text-white rounded-full'>
                        {billing?.planName === 'Basic Plan' ? 'upgrade' : 'downgrade'}
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SubscriptionPlanCard