import React from 'react'

const SummaryForm = ({ formData, handleChange }) => {

  const calculateRows = (text) => {
    const lines = text ? text.split('\n') : ['']; 
    return Math.max(lines.length, 6);
  }

  return (
    <div>
      <label htmlFor="summary" className='mb-2 capitalize'>Edit resume summary</label>
      <div className='mt-3'>
        <textarea 
        name="summary" 
        id="" 
        //  rows={calculateRows(formData?.summary)}
        rows={Math.max(8, formData?.summary?.split('\n').length)}
        placeholder='edit summary'
        value={formData?.summary}
        onChange={handleChange}
        className='w-full border border-gray-400 rounded-lg px-4 py-4 pr-1 focus:outline-none focus:border-dark text-gray-400 overflow-hidden'
        />
      </div>
    </div>
  )
}

export default SummaryForm