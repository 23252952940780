import coreClient from "../../services/coreApi";

export const useGenerateBullet = () => {
  return async(payload)=>{
    try {
        const res = await coreClient.post('/generate-text', payload);
        return res;
    } catch (error) {
        throw error;
    }
  }
}

