import React, { useState, createContext, useContext } from 'react'


const TemplateContext = createContext();

export const TemplateContextProvider = ({ children }) => {
    const [template, setTemplate] = useState(1)
    
    const handleSelectTemplate = (id)=>{
        setTemplate(id)
    }

  return (
    <TemplateContext.Provider value={{
        template,
        handleSelectTemplate,
        setTemplate
    }}>
        { children }
    </TemplateContext.Provider>
  )
}

export const useTemplateContext = ()=> useContext(TemplateContext)

