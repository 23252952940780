
import resume001 from '../assets/png/resume-sample-001.png'
import resume002 from '../assets/png/resume-sample-002.png'
import resume003 from '../assets/png/resume-sample-003.png'
import resume004 from '../assets/png/resume-sample-004.png'
import resume005 from '../assets/png/resume-sample-005.png'
import resume006 from '../assets/png/resume-sample-005B.png'
import resume007 from '../assets/png/resume-sample-006.png'
import resume008 from '../assets/png/resume-sample-006B.png'

export const dashboardResumeTemplates = [
    {
      id:1,
      name:'simple',
      image:resume001
    },
    {
      id:2,
      name:'professional',
      image:resume002
    },
    {
      id:3,
      name:'dialect',
      image:resume003
    },
    {
      id:4,
      name:'attention',
      image:resume004
    },
    {
      id:5,
      name:'brady',
      image:resume007
    },
    {
      id:6,
      name:'random',
      image:resume008
    },
    {
      id:7,
      name:'brady',
      image:resume005
    },
    {
      id:8,
      name:'random',
      image:resume006
    },
  ]