import React from 'react';
import { motion } from 'framer-motion';
import { useTemplateContext } from '../../contexts/templateContext';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { dashboardResumeTemplates } from '../../utils/dashboardResumeTemplates';

const ResumeTemplates = () => {

  const {  handleSelectTemplate, template:templateId } = useTemplateContext();
  const { handleSelectTab } = useSideBarContext();
  

  return (
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }} // Initial animation state
      animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to apply
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} // Animation transition
      className='bg-white rounded-md p-7 shadow-lg'
    >
      <div className='mb-[3rem]'>
        <div className='grid grid-cols-2 gap-2'>
          {
            dashboardResumeTemplates.map((template, i)=>(
              <motion.div onClick={()=>handleSelectTemplate(template.id)} 
              className={`rounded-md shadow-lg cursor-pointer p-2 ${templateId === i + 1 ? 'border border-slate-500' : ''}`} 
              key={template.id}
              initial={{ x: -50, opacity: 0 }} 
              animate={{ x: 0, opacity: 1 }} 
              transition={{ duration: 0.5, delay: i * 0.3 }} 
              >
                <img src={template.image} alt="" />
                {/* <div className='bg-red-500'>
                  <h3>{template.name}</h3>
                </div> */}
              </motion.div>
            ))
          }
        </div>
      </div>
      
    </motion.div>
  );
};

export default ResumeTemplates;
