
import axios from 'axios';
import { baseURL } from '../../services/baseURL.service';

export const useGoogleVerifyCode = () => {
  return async(code)=>{
    try {
        const response = await axios.get(`${baseURL}/auth/google/callback?code=${code}`);
        return response
    } catch (error) {
        throw error
    }
  }
}

