import React from 'react';
import linkedinScreenshot from '../../assets/png/screenshot-linkedin.png';
import linkedinIcon from '../../assets/png/linkedin-icon.png'

const Feature03 = () => {
  return (
    <div className='h-full flex items-center border border-slate-500 rounded-md font-primary'>
      <div className='w-1/2 flex flex-col justify-center gap-5 items-center'>
        <img src={linkedinIcon} alt="" className='h-9 w-9 animate-bounce'/>
        <h3 className='text-lg font-bold text-center leading-10'>LinkedIn Integration</h3>
        <p className='text-center px-10'>Quickly import your LinkedIn profile details and transform them into a compelling resume.
           Save time and ensure consistency across your professional profiles.</p>
      </div>
      <div className='h-full w-1/2 bg-light rounded-md'>
        <img src={linkedinScreenshot} alt="" className='rounded-md h-full w-full object-contain'  />
      </div>
    </div>
  )
}

export default Feature03