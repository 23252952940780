import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';
import { toast } from 'sonner';
import { useTemplateContext } from '../../contexts/templateContext';
import { useCoverLetterTemplateContext } from '../../contexts/coverLetterTemplateContext';
import ResumeTemplates from './ResumeTemplates';
import CoverLetterTemplates from './CoverLetterTemplates';
import CreateDocument from './CreateDocument';
import CreateResume from './CreateResume';
import CreateCoverLetter from './CreateCoverLetter';
import EditResume from './EditResume';
import EditCoverLetter from './EditCoverLetter';
import ResumeList from './ResumeList';
import CoverLetters from './CoverLetters';
import Subscriptions from './Subscriptions';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import EditResumeTemplate from './EditResumeTemplate';
import EditCoverLetterTemplate from './EditCoverLetterTemplate';

import { DashboardHeader } from '../../components/dashboard-items';
import { MobileFooter } from '../../components/footer';
import tokenService from '../../services/token.service';
import { TemplatesDialog, CoverLetterTemplatesDialog, ResumePreviewModal, CoverLetterPreviewModal } from '../../components/modal';
import { dashboardResumeTemplates } from '../../utils/dashboardResumeTemplates';
import { coverLetterTemplates } from '../../utils/coverLetterTemplates';

const ResumeDashboard = () => {
    const [showResumeModal, setShowResumeModal] = useState(false);
    const [showCoverLetterModal, setShowCoverLetterModal] = useState(false);
    const [showResumePreview, setShowResumePreview] = useState(false);
    const [showCoverLetterPreview, setShowCoverLetterPreview] = useState(false);
    const { sideBarTab } = useSideBarContext();
    const {  handleSelectTemplate, template:templateId } = useTemplateContext();
    const { handleSelectCoverLetterTemplate, coverLetterTemplate: selectedTemplateId } = useCoverLetterTemplateContext();
    const navigateTo = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    

    const handleToggleResumeTemplatesModal = ()=>{
        setShowResumeModal(!showResumeModal);
        
    }
    const handleToggleCoverLetterTemplatesModal = ()=>{
        setShowCoverLetterModal(!showCoverLetterModal);
    }
    const handleToggleResumePreview = ()=>{
        setShowResumePreview(!showResumePreview);
    }
    const handleToggleCoverLetterPreview = ()=>{
        setShowCoverLetterPreview(!showCoverLetterPreview);
    }

   

    const renderSideBarItem = ()=>{
        switch (sideBarTab) {
            case 'create resume':
                return <CreateResume />
            case 'create cover letter':
                return <CreateCoverLetter />
            case 'create document':
                return <CreateDocument />
            case 'my cover letters':
                return <CoverLetters />
            case 'edit resume':
                return <EditResume />
            case 'edit cover letter':
                return <EditCoverLetter />
            case 'resume templates':
                return <ResumeTemplates />
            case 'cover letter templates':
                return <CoverLetterTemplates />
            case 'go premium':
                return <Subscriptions />
            case 'my resumes':
                return <ResumeList />
            default:
                return <div>Select an option from the sidebar.</div>;;
        }
    }

    const renderEditScreen = () => {
        switch (sideBarTab) {
            case 'my cover letters':
            case 'edit cover letter':
            case 'cover letter templates':
                return <EditCoverLetterTemplate />;
            default:
                return <EditResumeTemplate />;
        }
    };

    const handleSignOut = (e) => {
        // e.preventDefault();
        tokenService.removeUser();
        navigateTo("/sign-in");
        return;
      };
      

  return (
    <div className='h-full'>
        <div>
            <DashboardHeader handleSignOut={handleSignOut}/>
        </div>
        <div className='h-full flex flex-col md:flex-row justify-between p-2 bg-white border-t font-primary'>
            <div className='w-full md:w-1/2 min-h-full border-r p-3 overflow-y-auto'>
                {renderSideBarItem()}
            </div>
            <div className='w-1/2 hidden md:block px-2 min-h-full overflow-y-auto'>
                    {renderEditScreen()}
            </div>
            {isMobile && (sideBarTab==='edit resume' || sideBarTab ==='edit cover letter') && (
                <>
                    <div className='w-full  bg-white shadow-lg border-t py-2 px-5 absolute left-0 bottom-0 z-10'>
                        <MobileFooter 
                            handleToggleResumeTemplatesModal={handleToggleResumeTemplatesModal}
                            handleToggleCoverLetterTemplatesModal={handleToggleCoverLetterTemplatesModal}
                            handleToggleResumePreview={handleToggleResumePreview}
                            handleToggleCoverLetterPreview={handleToggleCoverLetterPreview}
                        />
                    </div>
                    <TemplatesDialog
                        modalOpen={showResumeModal}
                        modalClose={handleToggleResumeTemplatesModal}
                    >
                    <div className=''>
                        <h2 className='py-5 mt-10 uppercase font-normal'>select resume template</h2>
                        <div className='grid grid-cols-2 gap-2'>
                        {
                            dashboardResumeTemplates.map((template, i)=>(
                            <motion.div onClick={()=>{
                                handleSelectTemplate(template.id);
                                handleToggleResumeTemplatesModal();
                                toast.success(`Template ${template.id} selected`)
                            }} 
                            className={`rounded-md shadow-lg cursor-pointer p-2 ${templateId === i + 1 ? 'border border-slate-500' : ''}`} 
                            key={template.id}
                            initial={{ x: -50, opacity: 0 }} 
                            animate={{ x: 0, opacity: 1 }} 
                            transition={{ duration: 0.5, delay: i * 0.3 }} 
                            >
                                <img src={template.image} alt="" />
                            </motion.div>
                            ))
                        }
                        </div>
                    </div>
                    </TemplatesDialog>
                    <CoverLetterTemplatesDialog
                        modalOpen={showCoverLetterModal}
                        modalClose={handleToggleCoverLetterTemplatesModal}
                    >
                    <div className=''>
                        <h2 className='py-5 mt-0 uppercase font-normal'>select cover letter template</h2>
                        <div className='grid grid-cols-2 gap-2'>
                        {
                            coverLetterTemplates.map((template, i)=>(
                            <motion.div onClick={()=>{
                                handleSelectCoverLetterTemplate(template.id);
                                handleToggleCoverLetterTemplatesModal();
                                toast.success(`Template ${template.id} selected`)
                            }} 
                            className={`rounded-md shadow-lg cursor-pointer p-2 ${selectedTemplateId === i + 1 ? 'border border-slate-500' : ''}`} 
                            key={template.id}
                            initial={{ x: -50, opacity: 0 }} 
                            animate={{ x: 0, opacity: 1 }} 
                            transition={{ duration: 0.5, delay: i * 0.3 }} 
                            >
                                <img src={template.image} alt="" />
                            </motion.div>
                            ))
                        }
                        </div>
                    </div>
                    </CoverLetterTemplatesDialog>
                    <ResumePreviewModal
                         modalOpen={showResumePreview}
                         modalClose={handleToggleResumePreview}
                    />
                    <CoverLetterPreviewModal
                         modalOpen={showCoverLetterPreview}
                         modalClose={handleToggleCoverLetterPreview}
                    />
                </>
            )}
        </div>
    </div>
  )
}

export default ResumeDashboard