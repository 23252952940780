import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/HowItWorks.css';
import { Feature01, Feature02, Feature03, Feature04 } from '../features'

const PageSwitcher = () => {

    const stepsRef = useRef([]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );
  
      stepsRef.current.forEach((step) => {
        if (step) observer.observe(step);
      });
  
      return () => {
        stepsRef.current.forEach((step) => {
          if (step) observer.unobserve(step);
        });
      };
    }, []);


  return (
    <section className="container relative mb-36 flex w-full m-auto flex-col 
    items-center justify-center px-container-base lg:px-container-lg xl:px-container-xl hidden md:block">
      <div className="sticky top-[35vh] w-full">
        <div style={{ opacity: 1 }}>
          <h2 className="items-center justify-center text-center text-[5rem] font-xbold tracking-tight md:text-[130px] font-primary my-16">
            How It Works
          </h2>
        </div>
      </div>
      {['Head to Join the next Cohort!', 'Fill the Application form', 'Select the track you want to learn', 'You will be invited to Slack when it has started'].map((text, index) => (
        <div className="sticky top-[25vh] flex w-full justify-center" key={index}>
          <div
            className={`step flex min-h-[500px] w-full flex-col justify-between rounded-md step-${index}`}
            ref={(el) => (stepsRef.current[index] = el)}
          >
            {/* <img
              alt={text}
              loading="lazy"
              width="150"
              height="150"
              decoding="async"
              style={{ color: 'transparent' }}
              src={`https://hng.tech/media/images/landing/howItWorks/swag-jobs-${index + 1}.svg`}
            />
            <p className="text-[1.7rem] font-[700] tracking-tight md:text-[2.2rem]">{text}</p> */}
            {
              index === 0 ? <Feature01 /> 
              : index === 1 ? <Feature02 /> 
              : index === 2 ? <Feature03 /> 
              : <Feature04 /> 
            }
          </div>
        </div>
      ))}
    </section>
  )
}


export default PageSwitcher