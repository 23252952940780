
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from "formik";
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { FaEye, FaEyeSlash, FaLongArrowAltRight, FaLinkedin } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { toast } from 'sonner';
import { useSignIn } from '../../hooks/post/useSignIn';
import { useGoogleAuth } from '../../hooks/auth/useGoogleAuth';
import { useLinkedinAuth } from '../../hooks/auth/useLinkedinAuth';
import { TextInput } from '../../components/forms/inputs';
import { Button } from '../../components/button';
import { LoginFormSchema } from '../../utils/yupSchemas';
import tokenService from '../../services/token.service';
import TemplatesLibrary3 from '../../components/sections/TemplatesLibrary3';
import { HomeLink } from '../../components/action-btns';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigateTo = useNavigate();
  const signIn = useSignIn();
  const googleAuth = useGoogleAuth();
  const linkedinAuth = useLinkedinAuth();


  const user = tokenService.getUser();
  const [isLoggedIn, setIsLoggedIn] = useState(!!user.email);



  const handleSignInGoogle = async () => {
    try {
      const { status, data } = await googleAuth();
      if(status===200){
        window.open(data.redirectUrl, '_self');
      }

    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
          toast.error('No response from server. Please try again later.');
      } else {
          toast.error(`Error: ${error.data.message}`);
      }
    } finally {
      console.log('error')
    }
  };

  const handleSignInLinkedin = async () => {
    try {
      const { status, data } = await linkedinAuth();
      if(status===200){
        window.open(data.redirectUrl, '_self');
      }

    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
          toast.error('No response from server. Please try again later.');
      } else {
          toast.error(`Error: ${error.data.message}`);
      }
    } finally {
      console.log('error')
    }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  }; 

  const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants2 = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }

        useEffect(()=>{
          if(isLoggedIn){
            navigateTo('/resume-dashboard');
          }
        },[])

  return (
    <div className='h-screen bg-[#e3e3e3] flex'>
        <div className='h-full md:w-4/12 mx-3 md:mx-10 flex justify-cente items-center'>
          <div className='w-full bg-white p-4 md:p-10 rounded-xl shadow-lg font-primary'>
            <HomeLink color='dark'/>
            <div className='py-3'>
              <h3>
              Welcome to the <b>best resume generation platform!</b>
              </h3>
              <p className='hidden md:block py-5'>
              Unlock the power of personalized templates, tailored suggestions, and intuitive design tools to create resumes that stand out in any job market.
              </p>
            </div>
            <h3 className='my-3'>Login below or <span>sign up</span> to create an account</h3>
            <div className='w-full flex flex-col md:flex-row items-center gap-2 md:gap-1'>
              <div 
                onClick={handleSignInGoogle}
              className='w-full flex items-center justify-between rounded-md md:rounded-bl-md md:rounded-tl p-3
               bg-[#f7f7f7] cursor-pointer hover:bg-white hover:border ease-in-out duration-500'>
                <h3 className='font-normal'>Google</h3>
                <span><FcGoogle /></span>
              </div>
              <div 
              onClick={handleSignInLinkedin}
              className='w-full flex items-center justify-between rounded-md md:rounded-br-md md:rounded-tr-md p-3
               bg-[#f7f7f7] cursor-pointer hover:bg-white hover:border ease-in-out duration-500'>
                <h3 className='font-normal'>linkedin</h3>
                <span><FaLinkedin className='text-blue' /></span>
              </div>
            </div>
              <div className='flex items-center gap-3 my-3'>
                <hr className='w-full' />
                <span>or</span>
                <hr className='w-full' />
              </div>
            <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={LoginFormSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const { status, data } = await signIn({
                      email: values.email,
                      password: values.password,
                    });
                    if(status===200){
                      tokenService.setUser(data);
                      navigateTo('/resume-dashboard');
                      toast.success('successfully logged in')
                    }
                    setSubmitting(false);
                  } catch (error) {
                    if (error.response && error.response.data) {
                      toast.error(`${error.response.data.message}`);
                    } else if (error.request) {
                        toast.error('No response from server. Please try again later.');
                    } else {
                        toast.error(`Error: ${error.data.message}`);
                    }
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div>
                      <TextInput
                        label='email'
                        name='email'
                        autoComplete='email'
                        type='email'
                        placeholder='Your email'
                      />
                      <div className='relative'>
                        <TextInput
                          label='password'
                          name='password'
                          type={showPassword ? "text" : "password"}
                          placeholder='****************'
                          handleTogglePassword={handleTogglePassword}
                          passwordIcon={
                            showPassword ? (
                              <FaEye className='text-dark' />
                            ) : (
                              <FaEyeSlash className='text-dark' />
                            )
                          }
                        />
                      </div>
                    </div>
                    <Button 
                    type='submit' disabled={isSubmitting}
                    icon={<FaLongArrowAltRight/>}
                    >
                      {/* {isSubmitting ? "accessing account" : "Sign in"} */}
                      {
                        isSubmitting ? <span className="loading loading-spinner loading-xs"></span> : <span>Sign in</span>
                      }
                    </Button>
                  </Form>
                )}
            </Formik>
            <div className='flex justify-between items-center mt-4'>
              <h3 className=''>Don't Have an account?</h3>
              <Link to='/sign-up' className='text-dark border-b border-dark font-bold hover:text-yellow'>Sign up</Link>
            </div>
          </div>
        </div>
        <div className='h-full w-7/12 relative font-primary hidden md:block'>
          <div className='h-full flex justify-center items-center'>
            <div className=''>
              <TemplatesLibrary3 />
            </div>
            <div className='w-full h-full absolute top-0 left-0 flex justify-center items-center bg-dark/20'>
              <div className='rotate-90'>
                  <motion.h1 
                      initial="hidden"
                      animate="visible"
                      variants={variants2}
                      transition={{ duration: 1.2, delay: 0.2 }}
                      className='font-xbold text-lg md:text-[130px] 
                      leading-[3rem] md:leading-[7rem] text-white text-center '>
                          Sign In
                  </motion.h1>
              </div>
            </div>
          </div>
       </div>
    </div>
  )
}

export default Login