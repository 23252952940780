import React from 'react';
import resumeTemplateScreeshot from '../../assets/png/screenshot-resume-templates.png';
import cvIcon from '../../assets/png/cv-icon.png'

const Feature02 = () => {
  return (
    <div className='h-full flex items-center border border-slate-500 rounded-md font-primary'>
      <div className='h-3/4 w-1/2 flex justify-center items-center bg-white rounded-md'>
        <img src={resumeTemplateScreeshot} alt="" className=''  />
      </div>
      <div className='w-1/2 flex flex-col justify-center gap-5 items-center'>
        <img src={cvIcon} alt="" className='h-24 w-24 animate-bounce'/>
        <h3 className='text-lg font-bold'>Professional Templates</h3>
        <p className='text-center px-10'>Choose from a wide range of professionally designed templates that are both visually appealing and ATS-friendly.
           Make a great first impression with a polished and professional look.</p>
      </div>
    </div>
  )
}

export default Feature02