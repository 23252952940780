
import React, { useState } from 'react';
import { toast } from 'sonner';
import { NavBar } from '../components/nav-menu';
import { Sections } from '../components/sections';
import { Footer } from '../components/footer';
import tokenService from '../services/token.service';





const Landing = () => {

  const user = tokenService.getUser();
  const [isLoggedIn, setIsLoggedIn] = useState(!!user.email);

  const handleLogout = () => {
      tokenService.removeUser();
      setIsLoggedIn(false);
      toast.success('You are logged out');
  };

  return (
    <div>
      <NavBar isLoggedIn={isLoggedIn} handleLogout={handleLogout}/>
      <Sections isLoggedIn={isLoggedIn} />
      <Footer />
    </div>
  )
}

export default Landing