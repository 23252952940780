import React, { useState, createContext, useContext } from 'react'
import { useCoverLetterItemContext } from './coverLetterItemContext';

const CoverLetterDataContext = createContext();

export const CoverLetterDataContextProvider = ({ children }) => {
    const [coverLetter, setCoverLetter] = useState(null)
    const { selectedCoverLetterId } = useCoverLetterItemContext();

  return (
    <CoverLetterDataContext.Provider value={{
        coverLetter,
        setCoverLetter,
        selectedCoverLetterId,
    }}>
        { children }
    </CoverLetterDataContext.Provider>
  )
}

export const useCoverLetterDataContext = ()=> useContext(CoverLetterDataContext)

