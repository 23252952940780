import React, { useField } from "formik";

export default function TextInput({
  label,
  icon,
  passwordIcon,
  onClick,
  handleTogglePassword,
  ...props
}) {
  const [field, meta] = useField(props);
  return (
    <div className='grid grid-cols-1 relative mt-2 mb-4'>
      <label
        htmlFor={props.id || props.name}
        className='text-xsm md:text-[14px] font-normal mb-[4px] ml-1 capitalize'
      >
        {label}
      </label>
      {icon && (
        <span className='absolute bottom-[2.4rem] left-2'>
          {<img src={icon} alt='' />}
        </span>
      )}
      <div className='relative'>
        <input
          className={`${
            icon && "pl-7"
          } w-full ${meta.touched && meta.error ? 'border border-red-500 focus:border-red-500' : 'border'} border-gray-400 outline-none rounded-lg px-4 py-3 pr-10 focus:border-2 focus:border-dark text-gray-400 bg-transparent relative`}
          {...field}
          {...props}
          onClick={onClick}
        />
        <span
          onClick={handleTogglePassword}
          className='absolute bottom-3 right-3 cursor-pointer'
        >
          {passwordIcon}
        </span>
      </div>
      {meta.touched && meta.error ? (
        <p className='text-red-500 text-xsm'>{meta.error}</p>
      ) : null}
    </div>
  );
}
