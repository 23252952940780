import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useDebouncedCallback } from "use-debounce";
import { toast } from 'sonner';
import { UserRound, Grip, ChevronUp, ChevronDown } from 'lucide-react';
import { useResumeDataContext } from '../../contexts/resumeDataContext';
import { useResumeItemContext } from '../../contexts/resumeItemContext';
import { useResumeFormSectionContext } from '../../contexts/resumeFormSectionContext';
import { useResumeSectionsContext } from '../../contexts/resumeSectionsContext';
import { useGetResume } from '../../hooks/get/useGetResume';
import { useUpdateResume } from '../../hooks/update/useUpdateResume';
import { useDeleteResume } from '../../hooks/delete/useDeleteResume';
import { 
  PersonalInformationForm,
  SummaryForm,
  ExperienceForm, 
  EducationForm, 
  CertificationsForm,
  SkillsForm
 } from '../../components/section-forms';
 

const EditResume = () => {

    const { selectedResumeId } = useResumeItemContext();
    const { isActiveSection, handleSelectedSection, selectedSection } = useResumeFormSectionContext();
    const { sections, setSections } = useResumeSectionsContext();
    const [loadingAddItem, setLoadingAddItem] = useState(false);
    // const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const getResume = useGetResume()
    const updateResume = useUpdateResume();
    const deleteResume = useDeleteResume()
    const { resume, setResume, setLoadingUpdate } = useResumeDataContext();
    const [workExperience, setWorkExperience] = useState([]);
    const [education, setEducation] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [skills, setSkills] = useState([]);
  
    const [formData, setFormData] = useState({
      firstName:'',
      lastName:'',
      email:'',
      phone:'',
      linkedin:'',
      address:'',
      jobTitle:'',
      summary:''
    });

    
    
    const debouncedUpdateResumeData = useDebouncedCallback(
        async () => {
            if (selectedResumeId) {
                try {
                    setLoadingUpdate(true)
                    const { status, data } = await updateResume(selectedResumeId, {
                      firstName: formData.firstName,
                      lastName: formData.lastName,
                      email: formData.email,
                      phone: formData.phone,
                      linkedin: formData.linkedin,
                      address: formData.address,
                      jobTitle: formData.jobTitle,
                      summary: { summary: formData.summary, sectionTitle: 'summary' },
                      workExperience: { workExperienceArray : workExperience, sectionTitle: 'Experinece' },
                      education: { educationArray : education.educationArray, sectionTitle: 'Education' },
                      certifications: { certificationsArray : certifications.certificationsArray, sectionTitle: 'Certifications' },
                      skills: { skillsArray : skills.skillsArray, sectionTitle: 'skills' }
                    });
                    if(status===200){
                      setResume(data?.data)
                      // getResumeData();
                    }
                } catch (error) {
                  return error
                }finally{
                  setLoadingUpdate(false)
                }
            }
        },
        1000 
    );
  
    const handleChange = (e)=>{
      const { name, value } = e.target;
      setFormData({ ...formData, [name]:value });
      debouncedUpdateResumeData()
    }

    const handleExperienceChange = (index, field, value) => {
      const updatedWorkExperience = [...workExperience];
      updatedWorkExperience[index][field] = value;
      setWorkExperience(updatedWorkExperience);
      debouncedUpdateResumeData();
  };

    const handleEducationChange = (index, field, value) => {
      const updatedEducation = [...education?.educationArray];
      updatedEducation[index][field] = value;
      setEducation({ educationArray : updatedEducation, sectionTitle: 'Education' });
      debouncedUpdateResumeData();
  };

    const handleCertificationsChange = (index, field, value) => {
      const updatedCertifications = [...certifications?.certificationsArray];
      updatedCertifications[index][field] = value;
      setCertifications({ certificationsArray : updatedCertifications, sectionTitle: 'Certifications' });
      debouncedUpdateResumeData();
  };
    const handleSkillsChange = (index, field, value) => {
      const updatedSkill = [...skills?.skillsArray];
      updatedSkill[index][field] = value;
      setSkills({ skillsArray : updatedSkill, sectionTitle: 'Skills' });
      debouncedUpdateResumeData();
  };


  const addWorkExperience = async() => {
    const newExperience = {
        title: '',
        company: '',
        location: '',
        startDate: '',
        endDate: '',
        bulletPoints: ['']
    };

    const newExperienceData = [...workExperience, newExperience];
    setTimeout(async()=>{
      setLoadingAddItem(true)
        try {
          const { status, data } = await updateResume(selectedResumeId, { workExperience:{ workExperienceArray: newExperienceData, sectionTitle: 'Experinece' } });
          if(status===200){
            setResume(data?.data)
            getResumeData();
            toast.success('new work experience added')
          }
          setLoadingAddItem(false)
      } catch (error) {
          setLoadingAddItem(false)
          toast.error('an error occured, try again')
      }
    },1000)
};
  const addEducation = async() => {
    const newEducation = {
      degree:'',
      fieldOfStudy:'',
      institution:'',
      location:'',
      country:'',
      year:'',
    };

    const newEducationData = [...education?.educationArray || [], newEducation];

    setTimeout(async()=>{
      setLoadingAddItem(true)
        try {
          const { status, data } = await updateResume(selectedResumeId, { education: { educationArray : newEducationData, sectionTitle: 'Education' }});
          if(status===200){
            setResume(data?.data)
            getResumeData();
            toast.success('new education added')
          }
          setLoadingAddItem(false)
      } catch (error) {
          setLoadingAddItem(false)
          toast.error('an error occured, try again')
      }
    },1000)
};

  const addCertifications = async() => {
    const newcertification = {
      certificationName:'',
      issuingOrganization:'',
      certificationUrl:'',
      year:'',
    };

    const newCertificationsData = [...certifications?.certificationsArray || [], newcertification];

    setTimeout(async()=>{
      setLoadingAddItem(true)
        try {
          const { status, data } = await updateResume(selectedResumeId, { certifications: { certificationsArray : newCertificationsData, sectionTitle: 'Certifications' }});
          if(status===200){
            setResume(data?.data)
            getResumeData();
            toast.success('new certifications added')
          }
          setLoadingAddItem(false)
      } catch (error) {
          setLoadingAddItem(false)
          toast.error('an error occured, try again')
      }
    },1000)
};
  const addSkill = async() => {
    const newSkill = {
      skill:'',
    };

    const newSkillData = [...skills?.skillsArray || [], newSkill];

    setTimeout(async()=>{
      setLoadingAddItem(true)
        try {
          const { status, data } = await updateResume(selectedResumeId, { skills: { skillsArray : newSkillData, sectionTitle: 'Skills' }});
          if(status===200){
            setResume(data?.data)
            getResumeData();
            toast.success('new skill added')
          }
          setLoadingAddItem(false)
      } catch (error) {
          setLoadingAddItem(false)
          toast.error('an error occured, try again')
      }
    },1000)
};

  const handleDeleteSection = async(itemId) => {
    setLoadingDelete(true)
    setTimeout(async()=>{
      try {
        const { status, data } = await deleteResume(selectedResumeId, itemId);
        if(status===200){
          setResume(data?.data)
          getResumeData();
          toast.success(data?.message)
        }
        setLoadingDelete(false)
    } catch (error) {
        setLoadingAddItem(false)
        console.log(error);
        toast.error('an error occured, try again')
    }
    },1000)
};

  
  async function getResumeData(){
    if(selectedResumeId){
      try {
          const { status, data } = await getResume(selectedResumeId);
          if(status===200){
            setFormData(prev =>({
              ...prev, 
              title:data?.data?.jobTitle, 
              firstName:data?.data?.firstName,
              lastName:data?.data?.lastName,
              email:data?.data?.email,
              phone:data?.data?.phone,
              linkedin:data?.data?.linkedin,
              address:data?.data?.address,
              jobTitle:data?.data?.jobTitle,
              summary:data?.data?.summary?.summary,
            }));
            setWorkExperience(data?.data?.workExperience?.workExperienceArray);
            setEducation(data?.data?.education);
            setCertifications(data?.data?.certifications);
            setSkills(data?.data?.skills)
          }
      } catch (error) {
          console.log(error)
      }
    }
  }
  
    useEffect(()=>{
      getResumeData();
  },[selectedResumeId]);


  useEffect(()=>{
    handleSelectedSection('PERSONAL INFORMATION')
  },[])


  const renderSectionContents = ()=>{
    switch (selectedSection) {
      case 'PERSONAL INFORMATION':
          return <PersonalInformationForm
                  formData={formData}
                  handleChange={handleChange}
          />
      case 'SUMMARY':
          return <SummaryForm
          formData={formData}
          handleChange={handleChange}
          />
      case 'EXPERIENCE':
          return <ExperienceForm
          workExperience={workExperience}
          handleExperienceChange={handleExperienceChange}
          addWorkExperience={addWorkExperience}
          handleDeleteSection={handleDeleteSection}
          updateResume={updateResume}
          selectedResumeId={selectedResumeId}
          setResume={setResume}
          setWorkExperience={setWorkExperience}
          loadingAddItem={loadingAddItem}
          loadingDelete={loadingDelete}
          />
      case 'EDUCATION':
          return <EducationForm 
          education={education}
          setEducation={setEducation}
          updateResume={updateResume}
          selectedResumeId={selectedResumeId}
          setResume={setResume}
          handleEducationChange={handleEducationChange}
          addEducation={addEducation}
          loadingAddItem={loadingAddItem}
          loadingDelete={loadingDelete}
          handleDeleteSection={handleDeleteSection}
          />
      case 'CERTIFICATIONS':
          return <CertificationsForm 
          certifications={certifications}
          setCertifications={setCertifications}
          updateResume={updateResume}
          selectedResumeId={selectedResumeId}
          setResume={setResume}
          handleCertificationsChange={handleCertificationsChange}
          addCertifications={addCertifications}
          loadingAddItem={loadingAddItem}
          loadingDelete={loadingDelete}
          handleDeleteSection={handleDeleteSection}
          />
      case 'SKILLS':
          return <SkillsForm
            skills={skills}
            setSkills={setSkills}
            updateResume={updateResume}
            selectedResumeId={selectedResumeId}
            setResume={setResume}
            addskill={addSkill}
            handleSkillsChange={handleSkillsChange}
            loadingDelete={loadingDelete}
            handleDeleteSection={handleDeleteSection}
          />
      default:
        break;
    }
  }
  

  // to do
  // only render this page if there is an active selected id else
  // redirect user to resume list items

  const renderSectionName = (sectionName)=>{
    if(sectionName==='PERSONAL INFORMATION'){
      return 'PERSONAL INFORMATION'
    }
    if(sectionName==='SUMMARY'){
      return resume?.summary?.sectionTitle
    }
    if(sectionName==='EXPERIENCE'){
      return resume?.workExperience?.sectionTitle
    }
    if(sectionName==='EDUCATION'){
      return resume?.education?.sectionTitle
    }
    if(sectionName==='CERTIFICATIONS'){
      return resume?.certifications?.sectionTitle
    }
    if(sectionName==='SKILLS'){
      return resume?.skills?.sectionTitle
    }
  }

  
  const handleDragStart = (event, itemId, prefix) => {
    event.dataTransfer.setData('text/plain', `${prefix}-${itemId}`);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async(event, targetId, prefix) => {
    event.preventDefault();
    // const draggedId = event.dataTransfer.getData('text/plain');
    const data = event.dataTransfer.getData('text/plain');
    const [dragPrefix, draggedId] = data.split('-');
    
    if (dragPrefix !== prefix) {
      // Ignore drops from different drag sources
      return;
    }
    const draggedItem = sections.find((item) => item.name === draggedId);
    const targetIndex = sections.findIndex((item) => item.name === targetId);

    // Create a copy of the workExperience array
    const newItems = [...sections];

     // Remove the dragged item from its original position
    newItems.splice(newItems.indexOf(draggedItem), 1);

     // Insert the dragged item at the target position
    newItems.splice(targetIndex, 0, draggedItem);

    setSections(newItems)
   
  };

  return (
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }}
      animate={{ x: 0, opacity: 1, scale: 1 }}
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
      className=''
    >
        <div className='bg-white rounded-md shadow-lg p-4 font-primary mb-[6rem] md:mb-[10rem]'>
          {
            sections.map((section, index)=>(
              <div key={`${section.name}-${index}`}
                className='my-3'
                // draggable
                // onDragOver={handleDragOver}
                // onDragStartCapture={(event) => handleDragStart(event, section?.name, 'sections')}
                // onDropCapture={(event) => handleDrop(event, section?.name, 'sections')}
                draggable={section.name !== 'PERSONAL INFORMATION'}
                onDragOver={section.name !== 'PERSONAL INFORMATION' ? handleDragOver : null}
                onDragStartCapture={section.name !== 'PERSONAL INFORMATION' ? (event) => handleDragStart(event, section?.name, 'sections') : null}
                onDropCapture={section.name !== 'PERSONAL INFORMATION' ? (event) => handleDrop(event, section?.name, 'sections') : null}
              >
                <div 
                onClick={()=>handleSelectedSection(section?.name)}
                className='flex justify-between items-center py-3 px-3 cursor-pointer bg-[#f3f3f3] text-xsm'
                >
                  <h3 
                  className='flex items-center gap-3 cursor-pointer text-xxsm'>
                  <span className='cursor-grab'>
                    {section.name === 'PERSONAL INFORMATION' ? <UserRound/> :  <Grip size={20} />}
                  </span>
                  {renderSectionName(section?.name)}
                  </h3>
                  <h3 
                  className='cursor-pointer  text-xsm'>
                    { isActiveSection && selectedSection === section.name ? <ChevronUp /> : <ChevronDown/>}
                  </h3>
                </div>
                {
                  isActiveSection && selectedSection === section.name && (
                    <div className='border p-5 mt-2 rounded-md text-xxsm'>
                      { renderSectionContents() }
                    </div>
                  )
                }
              </div>
            ))
          }
        </div>
    </motion.div>
  );
};

export default EditResume;
