import React, { useState, useEffect } from 'react';
import { Plus, Trash2,  Grip, Pencil, RotateCw } from 'lucide-react';
import { toast } from 'sonner';
import { SpringLoader } from '../loader';

import { CertificationsYear } from './dates';


const CertificationsForm = ({ 
    certifications,
    setCertifications,
    updateResume,
    selectedResumeId,
    setResume,
    handleCertificationsChange,
    addCertifications,
    loadingAddItem,
    loadingDelete,
    handleDeleteSection
}) => {
  const [selectedCertification, setSelectedCertification] = useState('');
  const [isActiveCertificationTab, setIsActiveCertificationTab] = useState(false);


  const handleSelectedCertification = (certification)=>{
    setSelectedCertification(certification)
    setIsActiveCertificationTab(true)
  }

  const handleDragStart = (event, certificationId, prefix) => {
    event.dataTransfer.setData('text/plain', `${prefix}-${certificationId}`);
    
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async(event, targetId, prefix) => {
    event.preventDefault();
    // const draggedId = event.dataTransfer.getData('text/plain');

    const data = event.dataTransfer.getData('text/plain');
    const [dragPrefix, draggedId] = data.split('-');
    
    if (dragPrefix !== prefix) {
      // Ignore drops from different drag sources
      return;
    }
    
    const draggedItem = certifications.certificationsArray.find((item) => item?._id === draggedId);
    const targetIndex = certifications.certificationsArray.findIndex((item) => item?._id === targetId);

    // Create a copy of the workExperience array
    const newItems = [...certifications.certificationsArray];

     // Remove the dragged item from its original position
    newItems.splice(newItems.indexOf(draggedItem), 1);

     // Insert the dragged item at the target position
    newItems.splice(targetIndex, 0, draggedItem);

    try {
      const { status, data } = await updateResume(selectedResumeId, { certifications:{ certificationsArray: newItems } });
      if(status===200){
        setResume(data?.data);
        setCertifications(data?.data?.certifications)
        toast.success('certification items rearranged')
      }
    } catch (error) {
      console.log(error)
    }
  };
  
  


  return (
    <>
    {
      certifications && certifications?.certificationsArray?.map((certification, i)=>(
      <div key={certification?._id}
      >
        <div className='flex justify-between items-center my-3 px-3 py-3 bg-gray-100'
          draggable
          onDragOver={handleDragOver}
          onDragStartCapture={(event) => handleDragStart(event, certification?._id, 'certification')}
          onDropCapture={(event) => handleDrop(event, certification?._id, 'certification')}
        >
          <h3 className='flex items-center gap-2'
          
          >
            <span className='cursor-grab'><Grip size={17}/></span>
            certification #{i + 1} - {certification?.certificationName}
          </h3>
          <div className='flex items-center gap-3'>
            <span onClick={()=>handleSelectedCertification(certification?._id)} 
            className='cursor-pointer bg-white p-2 rounded-full tooltip' data-tip="edit"><Pencil size={13}/>
            </span>
            <span onClick={()=>{
              setSelectedCertification(certification?._id)
              handleDeleteSection(certification?._id)
            }} className='cursor-pointer p-2 bg-red-100 rounded-full tooltip' data-tip="delete">
              {loadingDelete && selectedCertification === certification?._id ? (
                <span className="loading loading-spinner loading-xs"></span>
              ) : (
                <Trash2 color='red' size={15} />
              )}
            </span>
          </div>
        </div>
        {
          isActiveCertificationTab && selectedCertification === certification?._id &&(
            <>    
              <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div>
                  <label htmlFor="">Certification Name</label>
                  <input 
                    type="text"
                    name='certificationName'
                    value={certification.certificationName}
                    onChange={(e) => handleCertificationsChange(i, 'certificationName', e.target.value)}
                    placeholder='certificationName'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 capitalize pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div>
                  <label htmlFor="issuingOrganization">Issuing Organization</label>
                  <input 
                    type="text"
                    name='issuingOrganization'
                    value={certification?.issuingOrganization}
                    onChange={(e) => handleCertificationsChange(i, 'issuingOrganization', e.target.value)}
                    placeholder='field of study'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 capitalize pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                {/* <div>
                  <label htmlFor="certificationUrl">Certification Url (Optional)</label>
                  <input 
                    type="text"
                    name='certificationUrl'
                    value={certification?.certificationUrl}
                    onChange={(e) => handleCertificationsChange(i, 'certificationUrl', e.target.value)}
                    placeholder='certificationUrl'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div> */}
                <div className='flex flex-col md:flex-row items-center gap-1 relative'>
                  <div className='w-full'>
                    <CertificationsYear
                      index={i}
                      handleCertificationsChange={handleCertificationsChange}
                      initialDate={certification?.year}
                    />
                  </div>
                </div>
              </div>
              
            </>
          )
        }
      </div>
      ))
    }
    {
      loadingAddItem ? <SpringLoader /> : <button 
      onClick={addCertifications}
    className='w-full flex items-center justify-center gap-3 mt-3 text-center text-white bg-dark py-2 rounded-full hover:bg-gray-700'>
      Add certification
      <Plus size={20}/>
    </button>
    }
    </>
  )
}

export default CertificationsForm