import React, { useState, useEffect } from 'react';
import { Plus, Trash2,  Grip, Pencil, RotateCw } from 'lucide-react';
import { toast } from 'sonner';
import { SpringLoader } from '../loader';

import { EducationYear } from './dates';


const EducationForm = ({ 
  education,
  setEducation,
  updateResume,
  selectedResumeId,
  setResume,
  handleEducationChange,
  addEducation,
  loadingAddItem,
  loadingDelete,
  handleDeleteSection
}) => {
  const [selectedEducation, setSelectedEducation] = useState('');
  const [isActiveEducationTab, setIsActiveEducationTab] = useState(false);


  const handleSelectedEducation = (education)=>{
    setSelectedEducation(education)
    setIsActiveEducationTab(true)
  }

  const handleDragStart = (event, educationId, prefix) => {
    event.dataTransfer.setData('text/plain', `${prefix}-${educationId}`);
    
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async(event, targetId, prefix) => {
    event.preventDefault();
    // const draggedId = event.dataTransfer.getData('text/plain');

    const data = event.dataTransfer.getData('text/plain');
    const [dragPrefix, draggedId] = data.split('-');
    
    if (dragPrefix !== prefix) {
      // Ignore drops from different drag sources
      return;
    }
    
    const draggedItem = education.educationArray.find((item) => item?._id === draggedId);
    const targetIndex = education.educationArray.findIndex((item) => item?._id === targetId);

    // Create a copy of the workExperience array
    const newItems = [...education.educationArray];

     // Remove the dragged item from its original position
    newItems.splice(newItems.indexOf(draggedItem), 1);

     // Insert the dragged item at the target position
    newItems.splice(targetIndex, 0, draggedItem);

    try {
      const { status, data } = await updateResume(selectedResumeId, { education:{ educationArray: newItems } });
      if(status===200){
        setResume(data?.data);
        setEducation(data?.data?.education)
        toast.success('education items rearranged')
      }
    } catch (error) {
      console.log(error)
    }
  };
  
  


  return (
    <>
    {
      education && education?.educationArray?.map((education, i)=>(
      <div key={education?._id}
      >
        <div className='flex justify-between items-center my-3 px-3 py-3 bg-gray-100'
          draggable
          onDragOver={handleDragOver}
          onDragStartCapture={(event) => handleDragStart(event, education?._id, 'education')}
          onDropCapture={(event) => handleDrop(event, education?._id, 'education')}
        >
          <h3 className='flex items-center gap-2'
          
          >
            <span className='cursor-grab'><Grip size={17}/></span>
            Education #{i + 1} - {education?.degree}
          </h3>
          <div className='flex items-center gap-3'>
            <span onClick={()=>handleSelectedEducation(education?._id)} 
            className='cursor-pointer bg-white p-2 rounded-full tooltip' data-tip="edit"><Pencil size={13}/>
            </span>
            <span onClick={()=>{
              setSelectedEducation(education?._id)
              handleDeleteSection(education?._id)
            }} className='cursor-pointer p-2 bg-red-100 rounded-full tooltip' data-tip="delete">
              {loadingDelete && selectedEducation === education?._id ? (
                <span className="loading loading-spinner loading-xs"></span>
              ) : (
                <Trash2 color='red' size={15} />
              )}
            </span>
          </div>
        </div>
        {
          isActiveEducationTab && selectedEducation === education?._id &&(
            <>    
              <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div>
                  <label htmlFor="">Degree</label>
                  <input 
                    type="text"
                    name='degree'
                    value={education.degree}
                    onChange={(e) => handleEducationChange(i, 'degree', e.target.value)}
                    placeholder='degree'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div>
                  <label htmlFor="fieldOfStudy">field of study</label>
                  <input 
                    type="text"
                    name='fieldOfStudy'
                    value={education?.fieldOfStudy}
                    onChange={(e) => handleEducationChange(i, 'fieldOfStudy', e.target.value)}
                    placeholder='field of study'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div>
                  <label htmlFor="institution">institution</label>
                  <input 
                    type="text"
                    name='institution'
                    value={education?.institution}
                    onChange={(e) => handleEducationChange(i, 'institution', e.target.value)}
                    placeholder='institution'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div>
                  <label htmlFor="location">location</label>
                  <input 
                    type="text"
                    name='location'
                    value={education?.location}
                    onChange={(e) => handleEducationChange(i, 'location', e.target.value)}
                    placeholder='location'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div>
                  <label htmlFor="country">country</label>
                  <input 
                    type="text"
                    name='country'
                    value={education?.country}
                    onChange={(e) => handleEducationChange(i, 'country', e.target.value)}
                    placeholder='country'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div className='flex flex-col md:flex-row items-center gap-1 relative'>
                  <div className='w-full'>
                    <EducationYear
                      index={i}
                      handleEducationChange={handleEducationChange}
                      initialDate={education?.year}
                    />
                  </div>
                </div>
              </div>
              
            </>
          )
        }
      </div>
      ))
    }
    {
      loadingAddItem ? <SpringLoader /> : <button 
      onClick={addEducation}
    className='w-full flex items-center justify-center gap-3 mt-3 text-center text-white bg-dark py-2 rounded-full hover:bg-gray-700'>
      Add Education
      <Plus size={20}/>
    </button>
    }
    </>
  )
}

export default EducationForm