
import React, { useState, useRef } from 'react';
import { toast } from 'sonner';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ResumeDocumentPDF } from '../download-document';
import { PdfDownloadButton } from '../button/PdfDownloadBtn';
import { usevalidatePayment } from '../../hooks/post/usevalidatePayment';
import { handleResumeTypeDownloaded } from '../../services/heapAnalytics';

const PayButton = ({ template, resume, sections, handleTogglePayWallModal }) => {
    const [loading, setLoading] = useState(false);
    const validatePayment = usevalidatePayment();
    const downloadLinkRef = useRef();

    const handleMakeApiCall = async () => {
        // try {
        //     setLoading(true);
        //     const { status } = await validatePayment();
        //     if (status === 200) {
        //         toast.success('Resume downloaded successfully');
        //         downloadLinkRef.current.click();
        //     }
        // } catch (error) {
        //     if (error.response && error.response.data) {
        //         handleTogglePayWallModal();
        //         toast.error(`${error.response.data.message}`);
        //     } else if (error.request) {
        //         toast.error('No response from server. Please try again later.');
        //     } else {
        //         toast.error(`${error.message}`);
        //     }
        // } finally {
        //     setLoading(false);
        // }
        setLoading(true);
        setTimeout(async()=>{
            try {
                const { status } = await validatePayment();
                if (status === 200) {
                    toast.success('Resume downloaded successfully');
                    downloadLinkRef.current.click();
                    handleResumeTypeDownloaded(template);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.data) {
                    handleTogglePayWallModal();
                    toast.error(`${error.response.data.message}`);
                } else if (error.request) {
                    toast.error('No response from server. Please try again later.');
                } else {
                    toast.error(`${error.message}`);
                }
            }
        },2000)
    };

    return (
        <>
            <PdfDownloadButton loading={loading} onClick={handleMakeApiCall} />
            <PDFDownloadLink
                document={<ResumeDocumentPDF template={template} data={resume} sections={sections} />}
                fileName={`${resume?.lastName}-${resume?.firstName}-resume.pdf`}
                style={{ textDecoration: 'none' }}
            >
                {({ loading: pdfLoading }) => (
                    <a
                        ref={downloadLinkRef}
                        style={{ display: 'none' }}
                    />
                )}
            </PDFDownloadLink>
        </>
    );
};

export default PayButton;
