import React, { useState, useEffect } from 'react';
import { Plus, Trash2,  Grip, Pencil, RotateCw, ListPlus } from 'lucide-react';
import { toast } from 'sonner';
import { SpringLoader } from '../loader';

import { skillYear } from './dates';


const SkillsForm = ({ 
  skills,
  setSkills,
  updateResume,
  selectedResumeId,
  setResume,
  handleSkillsChange,
  addskill,
  loadingAddItem,
  loadingDelete,
  handleDeleteSection
}) => {
  const [selectedSkill, setSelectedSkill] = useState('');
  const [isActiveSkillsTab, setIsActiveSkillsTab] = useState(false);


  const handleSelectedSkill = (skill)=>{
    setSelectedSkill(skill)
    setIsActiveSkillsTab(true)
  }

  const handleDragStart = (event, skillId, prefix) => {
    event.dataTransfer.setData('text/plain', `${prefix}-${skillId}`);
    
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async(event, targetId, prefix) => {
    event.preventDefault();
    // const draggedId = event.dataTransfer.getData('text/plain');

    const data = event.dataTransfer.getData('text/plain');
    const [dragPrefix, draggedId] = data.split('-');
    
    if (dragPrefix !== prefix) {
      // Ignore drops from different drag sources
      return;
    }
    
    const draggedItem = skills.skillsArray.find((item) => item?._id === draggedId);
    const targetIndex = skills.skillsArray.findIndex((item) => item?._id === targetId);

    // Create a copy of the workExperience array
    const newItems = [...skills.skillsArray];

     // Remove the dragged item from its original position
    newItems.splice(newItems.indexOf(draggedItem), 1);

     // Insert the dragged item at the target position
    newItems.splice(targetIndex, 0, draggedItem);

    try {
      const { status, data } = await updateResume(selectedResumeId, { skills:{ skillsArray: newItems } });
      if(status===200){
        setResume(data?.data);
        setSkills(data?.data?.skills)
        toast.success('skills items rearranged')
      }
    } catch (error) {
      return error
    }
  };
  
  


  return (
    <>
    {
      skills && skills.skillsArray?.map((skill, i)=>(
      <div key={skill?._id} className='flex items-center gap-1 my-1'>
        <div draggable
          onDragOver={handleDragOver}
          onDragStartCapture={(event) => handleDragStart(event, skill?._id, 'skill')}
          onDropCapture={(event) => handleDrop(event, skill?._id, 'skill')}
        >
          <h3 className='flex items-center gap-2'>
            <span className='cursor-grab'><Grip size={17} color='gray'/></span>
          </h3>
        </div>
        <div className='w-full'>
        <input
            type="text"
            className='w-full rounded-md outline-none px-4 py-1 pr-10 
            hover:border hover:border-dark focus:border-2 focus:border-slate-500
              text-gray-400 overflow-hidden cursor-pointer focus:cursor-text'
            name='skill'
            value={skill?.skill}
            onChange={(e) => handleSkillsChange(i, 'skill', e.target.value)}
            placeholder="...start writing a new skill"
        />
        </div>
        <div className=''>
        <div className='flex items-center gap-3'>
          <span onClick={()=>handleSelectedSkill(skill?._id)} 
            className='cursor-pointer bg-light p-1 rounded-full tooltip' data-tip="rewrite"><RotateCw size={11}/>
            </span>
            <span onClick={()=>{
              setSelectedSkill(skill?._id)
              handleDeleteSection(skill?._id)
            }} className='cursor-pointer p-1 bg-red-100 rounded-full tooltip' data-tip="delete">
              {loadingDelete && selectedSkill === skill?._id ? (
                <span className="loading loading-spinner w-3 h-3"></span>
              ) : (
                <Trash2 color='red' size={13} />
              )}
            </span>
          </div>
        </div>
      </div>
      ))
    }
    {
      loadingAddItem ? <SpringLoader /> 
      : <div className='flex justify-end'>
          <button 
        onClick={addskill}
      className='flex items-center justify-center gap-3 mt-3 px-10 text-center text-white
      bg-dark py-2 rounded-full hover:bg-gray-700'>
        Add skill
        <Plus size={20}/>
      </button>
      </div>
    }
    </>
  )
}

export default SkillsForm