

import { FileDown } from 'lucide-react';
export const PdfDownloadButton = ({ loading, ...props }) => {
  return (
    // <>
    //   {
    //     loading ? (
    //       <span className="loading loading-dots loading-xs"></span>
    //     ):(
    //       <button
    //         className=' bg-dark flex gap-4 text-white justify-between items-center rounded-sm text-xsm w-full'
    //         {...props}
    //       >
    //         <span className=' flex gap-3 px-4 py-2 justify-center items-center'>
    //           Download PDF
    //         </span>
    //         <span className='bg-gray-600 px-4 py-2'>
    //           <FileDown size={20} />
    //         </span>
    //       </button>
    //     )
    //   }
    // </>
    <button
      //className=' bg-dark flex gap-4 text-white justify-between items-center rounded-sm text-xsm w-full'
      className={`bg-dark flex gap-4 text-white justify-between items-center rounded-sm text-xsm w-full ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
      {...props}
      disabled={loading}
    >
      <span className=' flex gap-3 px-4 py-2 justify-center items-center'>
        {
          loading ? <h4 className='flex items-center gap-2'><span className="loading loading-spinner loading-xs"></span> Generating PDF</h4>: 'Download PDF'
        }
        
      </span>
      <span className='bg-gray-600 px-4 py-2'>
        <FileDown size={20} />
      </span>
    </button>
  );
};
