import React from 'react'

const CoverLetterContentForm = ({ formData, handleChange  }) => {
  return (
    <div>
      <div className='grid grid-cols-2 gap-3'>
        <div>
          <label htmlFor="" className='capitalize text-xsm'>Professional Job Title</label>
          <input 
            type="text"
            name='jobTitle'
            value={formData?.jobTitle}
            onChange={handleChange}
            placeholder='Professional Job Title'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
        </div>
        <div>
          <label htmlFor="" className='capitalize text-xsm'>Application Date</label>
          <input 
            type="text"
            name='applicationDate'
            value={formData?.applicationDate}
            onChange={handleChange}
            placeholder='Application Date'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
        </div>
      </div>
      <div className='mt-4'>
      <label htmlFor="coverLetterContent" className='mb-2 capitalize text-xsm'>Edit cover letter content</label>
      <div className='mt-3'>
        <textarea 
        name="coverLetterContent" 
        id="" 
        rows={Math.max(16, formData?.coverLetterContent?.split('\n').length)}
        placeholder='Edit cover letter content'
        value={formData?.coverLetterContent}
        onChange={handleChange}
        className='w-full border border-slate-300 rounded-sm px-4 py-4 pr-1 focus:outline-none focus:border-dark text-gray-400 overflow-hidden'
        />
      </div>
    </div>
    </div>
  )
}

export default CoverLetterContentForm