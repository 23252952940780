import React, { useState, createContext, useContext } from 'react'
import { useResumeItemContext } from './resumeItemContext';

const ResumeDataContext = createContext();

export const ResumeDataContextProvider = ({ children }) => {
    const [resume, setResume] = useState(null);
    const [loadingUpdate, setLoadingUpdate] = useState(null);
    const { selectedResumeId } = useResumeItemContext();

  return (
    <ResumeDataContext.Provider value={{
        loadingUpdate,
        setLoadingUpdate,
        resume,
        setResume,
        selectedResumeId,
    }}>
        { children }
    </ResumeDataContext.Provider>
  )
}

export const useResumeDataContext = ()=> useContext(ResumeDataContext)

