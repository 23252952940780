
import coreClient from "../../services/coreApi";

export const useGetAllResumes = () => {
  return async()=>{
    try {
        const res = await coreClient.get('/resumes');
        return res;
    } catch (error) {
      throw error;
    }
  }
}

