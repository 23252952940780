import React from 'react'

const ResumeItemSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 w-full my-3">
        <div className='flex items-center gap-3'>
            <div className="skeleton h-2 w-2 rounded-full"></div>
            <div className="skeleton h-2 w-2 rounded-full"></div>
            <div className="skeleton h-2 w-2 rounded-full"></div>
        </div>
        <div className="skeleton h-2 w-64"></div>
        <div className="skeleton h-2 w-64"></div>
        <div className="skeleton h-2 w-64"></div>
        <div className="skeleton h-2 w-full"></div>
        <div className="skeleton h-2 w-full"></div>
        <div className="skeleton h-2 w-full"></div>
  </div>
  )
}

export default ResumeItemSkeleton