import coreClient from "../../services/coreApi";
export const useDeleteAllResume = () => {
  return async(resumeId)=>{
    try {
        const res = await coreClient.delete(`/resume/${resumeId}/delete`,);
        return res;
    } catch (error) {
        throw error
    }
  }
}

