import React from 'react'

const CoverLetterPersonalInformationForm = ({ formData, handleChange }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>last name</label>
          <input 
            type="text"
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            placeholder='Your Last Name'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>first name</label>
          <input 
            type="text"
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            placeholder='Your First Name'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>email</label>
          <input 
            type="text"
            name='email'
            value={formData?.email}
            onChange={handleChange}
            placeholder='Your Email'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
        </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>phone number</label>
          <input 
            type="text"
            name='phone'
            value={formData?.phone}
            onChange={handleChange}
            placeholder='Your Phone Number'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>job title</label>
          <input 
            type="text"
            name='jobTitle'
            value={formData?.jobTitle}
            onChange={handleChange}
            placeholder='Title of job applying for ...'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>linkedin url</label>
          <input 
            type="text"
            name='linkedin'
            value={formData?.linkedin}
            onChange={handleChange}
            placeholder='https://www.linkedin.com/in/johndoe'
            pattern='https://.*'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>Your country</label>
          <input 
            type="text"
            name='applicantCountry'
            value={formData?.applicantCountry}
            onChange={handleChange}
            placeholder='Your state'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>Your state</label>
          <input 
            type="text"
            name='applicantState'
            value={formData?.applicantState}
            onChange={handleChange}
            placeholder='Your state'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>Your city</label>
          <input 
            type="text"
            name='applicantCity'
            value={formData?.applicantCity}
            onChange={handleChange}
            placeholder='Current city'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
      <div>
          <label htmlFor="" className='capitalize text-xsm'>Your Zip code</label>
          <input 
            type="text"
            name='applicantZipCode'
            value={formData?.applicantZipCode}
            onChange={handleChange}
            placeholder='Your zip code'
            className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
          />
      </div>
    </div>
  )
}

export default CoverLetterPersonalInformationForm