import React from 'react'
import { motion } from 'framer-motion';
import { useSideBarContext } from '../../../contexts/sideBarTabsContext';
import { useResumeFormSectionContext } from '../../../contexts/resumeFormSectionContext';
import { HoverToEdit } from '../../hover';

const PreviewResume04 = ({ data, sections }) => {
    const { handleSelectTab } = useSideBarContext();
    const { handleSelectedSection } = useResumeFormSectionContext();


    const handleEditSection = (sectionName)=>{
        handleSelectTab('edit resume')
        handleSelectedSection(sectionName)
    }

    const summaryComp = ()=>{
        return  <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('SUMMARY')}>
        {data?.summary && (<div className='mt-3 '>
            <h2 
                className='text-xxxsm font-normal pb-1 pt-2 flex items-center uppercase border-b border-blue '
            
            >
                summary
            </h2>
            <p className=' mt-1 text-xxxsm'>{data.summary.summary}</p>
            <HoverToEdit />
        </div>)}
    </div>
    }

    const workExperienceComp = ()=>{
        return  <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('EXPERIENCE')}>
        {
            data?.workExperience?.workExperienceArray?.length > 0 && (
                <>
                    <h2 
                    className='font-normal pb-1 pt-2 flex items-center uppercase border-b border-blue '
                    >
                        Work Experience
                    </h2>
                    {
                        data?.workExperience?.workExperienceArray?.map((experience)=>(
                            <div key={experience?._id} className=''>
                                <div className='flex justify-between items-center mt-2'>
                                    <div>
                                        <h3 className='font-bold text-lightDark'>{experience?.company}</h3>
                                        <h3 className='italic text-[10px] font-light'>{experience?.title && experience?.title}</h3>
                                    </div>
                                    <div className='mb-1'>
                                        <h3 className='text-end font-bold text-lightDark'>{experience?.startDate} {experience?.endDate && ' - ' + experience?.endDate}</h3>
                                        <h3 className='text-end italic text-[10px] font-light'>{experience?.location} </h3>
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <ul className='text-gray-600'>
                                        {experience?.bulletPoints.map((points, index)=> (
                                            <li key={index}>{points}</li>
                                        ))}
                                    </ul>
                                </div> 
                            </div>
                        ))
                    }
                </>
            )
        }
        <HoverToEdit />
    </div>
    }

    const educationComp = ()=>{
        return <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('EDUCATION')}>
        {
            data?.education?.educationArray?.length > 0 && (
                <>
                    <h2 
                    className='font-normal pb-1 pt-2 flex items-center uppercase border-b border-blue '
                    >
                        EDUCATION
                    </h2>
                    {
                        data?.education?.educationArray?.map((education)=>(
                            <div key={education._id} className=''>
                                <div className='flex justify-between items-center mt-1'>
                                    <div>
                                        <h3 className='font-bold text-lightDark'>{education?.institution}</h3>
                                        <h3 className='italic text-[10px] font-light'>{education?.degree}</h3>
                                    </div>
                                    <div className='mb-1'>
                                        <h3 className='text-end font-bold text-lightDark'>{education?.year}</h3>
                                        <h3 className='text-end italic text-[10px] font-light'>{education?.location} {education?.country && ', ' + education?.country}</h3>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </>
            )
        }
        <HoverToEdit />
    </div>
    }
    const certificationsComp = ()=>{
        return <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('CERTIFICATIONS')}>
        {
            data?.certifications?.certificationsArray?.length > 0 && (
                <>
                    <h2 
                    className='font-normal pb-1 pt-2 flex items-center uppercase border-b border-blue '
                    >
                        CERTIFICATIONS
                    </h2>
                    {
                        data?.certifications?.certificationsArray?.map((certification)=>(
                            <div key={certification._id} className=''>
                                <div className='flex justify-between items-center mt-1'>
                                </div>
                                <h3 className='font-bold pb-1 pt-2 flex items-center capitalize text-lightDark'>
                                    { certification?.certificationName && certification?.certificationName } 
                                    { certification.issuingOrganization && ' | ' + certification.issuingOrganization }  
                                    { certification?.year && ' | ' + certification?.year }
                                </h3>
                            </div>
                        ))
                    }
                </>
            )
        }
        <HoverToEdit />
    </div>
    }

    const skillsComp = ()=>{
        return <div className='cursor-pointer relative group  p-2 rounded-md' onClick={()=>handleEditSection('SKILLS')}>
        {
            data?.skills?.skillsArray?.length > 0 && (
            <>
                <h2 
                    className='font-normal pb-1 pt-2 flex items-center uppercase border-b border-blue '
                >
                    Skills
                </h2> 
                <div className='mt-2'>
                    {data?.skills && data?.skills?.skillsArray?.map((skill, index)=>(
                        <span className=' font-bold capitalize pt-2 text-lightDark' key={index}>
                            {index === data.skills?.skillsArray?.length - 1 ? skill.skill + '.' : skill.skill + ', '}
                        </span>
                    ))}
                </div>
            </>)
        }
        <HoverToEdit />
    </div>
    }

    const renderSection = (section) => {
        switch (section) {
            case 'SUMMARY':
                return summaryComp();
            case 'EXPERIENCE':
                return workExperienceComp();
            case 'EDUCATION':
                return educationComp();
            case 'CERTIFICATIONS':
                return certificationsComp();
            case 'SKILLS':
                return skillsComp();
            default:
                return null;
        }
    };

  return (
    <motion.div 
    initial={{ x: -100, opacity: 0, scale: 0.8 }} 
    animate={{ x: 0, opacity: 1, scale: 1 }} 
    transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
    className=''>
    <div className='font-primary text-xxxsm'>
        <h1 className='text-center text-lightDark text-title leading-[36px] font-bold capitalize'>{data?.lastName} {data?.firstName}</h1>
        <ul className='flex items-center flex-wrap justify-center gap-2'>
            {
                data?.address && (
                    <li className='flex items-center gap-1'>
                        {/* <MapPin size={10} />  */}
                        <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.address}</h4>
                    </li>
                )
            }
            {
                data?.email && (
                    <li className='flex items-center gap-1'>
                        {/* <Mail size={10} />  */}
                        <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.email}</h4>
                    </li>
                )
            }
            {
                data?.phone && (
                    <li className='flex items-center gap-1'>
                        {/* <Smartphone size={10} />  */}
                        <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.phone}</h4>
                    </li>
                )
            }{
                data?.linkedin && (
                    <li className='flex items-center gap-1'>
                        {/* <Linkedin size={10} />  */}
                        <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.linkedin}</h4>
                    </li>
                )
            }
        </ul>
        {sections.map((section, index) => (
            <div key={`${section?.name}-${index}`}>
                {renderSection(section?.name)}
            </div>
        ))}
        
        </div>
</motion.div>
  )
}

export default PreviewResume04