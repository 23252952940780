import React from 'react'
import { BrowserRouter as Router, unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom';
import { Register, Login, GoogleVerifyCode, LinkedinVerifyCode, EmailVerification, SignUpSuccess } from '../pages/auth';
import { Dashboard, ResumeDashboard} from '../pages/dashboard';
import { Landing } from '../pages';
import { AuthorizedPage } from '../middlewares/AuthorizedPage';
import { Notifications } from '../pages/notifications';
import { CancelPayment, PaymentSuccess } from '../pages/payments';
import history from '../services/history';
const PageRouter = () => {
  return (
    <HistoryRouter history={history}>
        <Routes>
            <Route path='/' element={<Landing/>} />
            <Route path='/sign-in' element={<Login/>} />
            <Route path='/sign-up' element={<Register/>} />
            <Route path='/sign-up-success' element={<SignUpSuccess/>} />
            <Route path='/auth/google/callback' element={<GoogleVerifyCode/>} />
            <Route path='/auth/linkedin/callback' element={<LinkedinVerifyCode/>} />
            <Route path='/verify-email' element={<EmailVerification/>} />
            <Route path='/success' element={<AuthorizedPage><PaymentSuccess /></AuthorizedPage>} />
            <Route path='/cancel' element={<AuthorizedPage><CancelPayment /></AuthorizedPage>} />
            <Route element={
              <AuthorizedPage>
                <Dashboard />
              </AuthorizedPage>
            }>
                <Route index path='resume-dashboard' element={<AuthorizedPage><ResumeDashboard/></AuthorizedPage>} />
            </Route>
           {/* <Route path='*' element={<NotFound />} /> */}
        </Routes>
    </HistoryRouter>
  )
}

export default PageRouter