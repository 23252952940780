import React from 'react'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


const ActionHero = ({ isLoggedIn }) => {

    const buttonVariants = {
        hidden: { opacity: 0, x: -70 },
        visible: { opacity: 1, x: 0 },
    };

  return (
    <div className="hero bg-base-200 min-h-screen font-primary">
        <div className="hero-content text-center">
            <div className="max-w-m w-11/12 md:w-8/12 m-auto">
            <h1 className="text-[2rem] leading-10 md:text-xl font-bold md:leading-[4.3rem]">Create your perfect resume today!</h1>
            <p className="py-6 text-sm">
            Whether you're a recent <b>graduate</b> or an <b>experienced professional</b> <br />
            Boost your job search success with our user-friendly interface and expert guidance.
            </p>
            <div className='md:w-10/12 m-auto'>
            {
                  isLoggedIn ? (
                    <Link to="/resume-dashboard">
                      <motion.button
                        initial="hidden"
                        animate="visible"
                        variants={buttonVariants}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        className="w-full bg-dark border border-dark
                        rounded-md hover:bg-light text-white hover:text-dark text-sm duration-300 py-5 my-4 font-bold">
                        Proceed to Dashboard
                      </motion.button>
                    </Link>
                  ) : (
                    <Link to="/sign-up">
                      <motion.button
                        initial="hidden"
                        animate="visible"
                        variants={buttonVariants}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        className="w-full bg-dark border border-dark
                        rounded-md hover:bg-light text-white hover:text-dark text-sm duration-300 py-5 my-4 font-bold">
                        Create Resume
                      </motion.button>
                    </Link>
                  )
                }
            </div>
            </div>
        </div>
    </div>
  )
}

export default ActionHero