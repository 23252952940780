import React from 'react';
import { useField } from 'formik';

export default function TextInputlg ({ label, ...props }) {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <div className="grid grid-cols-1 my-3">
            <label htmlFor={props.id || props.name} className="text-xsm font-light mb-0 ml-0 capitalize"> {label} <span className='text-red-500'>*</span></label>
            <input 
            className={`w-full ${meta.touched && meta.error ? 'border border-red-500 focus:border-red-500' : 'border'}  border-gray-600  outline-none rounded-md px-4 mt-1 py-2 pr-10 text-xsm
              focus:border-dark focus:border-2 text-slate-500`}
            {...field} {...props} />
            {meta.touched && meta.error ? (
                <p className="text-red-500 text-xxsm mt-1 font-normal">{meta.error}</p>
            ) : null}
        </div>
    );
};