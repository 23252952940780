import React from 'react';
import { motion } from 'framer-motion';
import { useCoverLetterTemplateContext } from '../../contexts/coverLetterTemplateContext';
import coverLetter01 from '../../assets/png/coverletter-01.png';
import coverLetter02 from '../../assets/png/coverletter-02.png';

const CoverLetterTemplates = () => {
  const { handleSelectCoverLetterTemplate, coverLetterTemplate: selectedTemplateId } = useCoverLetterTemplateContext();
  
  const templates = [
    {
      id: 1,
      name: 'Simple',
      image: coverLetter01,
    },
    {
      id: 2,
      name: 'Professional',
      image: coverLetter02,
    },
  ];

  return (
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }} 
      animate={{ x: 0, opacity: 1, scale: 1 }} 
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
      className="bg-white rounded-md p-7 shadow-lg"
    >
      <div className="mb-[3rem]">
        <div className="grid grid-cols-2 gap-2">
          {templates.map((template, i) => (
            <motion.div
              onClick={() => handleSelectCoverLetterTemplate(template.id)}
              className={`rounded-md shadow-lg cursor-pointer p-2 ${selectedTemplateId === template.id ? 'border border-slate-500' : ''}`}
              key={template.id}
              initial={{ x: -50, opacity: 0 }} 
              animate={{ x: 0, opacity: 1 }} 
              transition={{ duration: 0.5, delay: i * 0.3 }}
            >
              <img src={template.image} alt={`${template.name} template`} loading='lazy'/>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default CoverLetterTemplates;
