
import axios from "axios";
import { baseURL } from "./baseURL.service";
const USER_CHANGED_EVENT = 'storage-change-user';

class TokenService {
    USER_CHANGED_EVENT = USER_CHANGED_EVENT;

    getUser() {
        try {
            return JSON.parse(localStorage.getItem('user') || '{}');
        } catch (error) {
            console.error('Error parsing user from localStorage:', error);
            return {};
        }
    }

    setUser(user) {
        try {
            localStorage.setItem('user', JSON.stringify(user));
            dispatchEvent(new Event(USER_CHANGED_EVENT));
        } catch (error) {
            console.error('Error setting user in localStorage:', error);
        }
    }

    removeUser() {
        localStorage.removeItem('user');
        dispatchEvent(new Event(USER_CHANGED_EVENT));
    }

    getLocalRefreshToken() {
        const user = this.getUser();
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = this.getUser();
        return user?.accessToken;
    }

    setLocalAccessToken(token) {
        const user = this.getUser();
        user.accessToken = token;
        this.setUser(user);
    }

    async refreshToken() {
        const refreshToken = this.getLocalRefreshToken();
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        try {
            const response = await axios.post(`${baseURL}/auth/refresh-token`, {
                token:refreshToken,
            });
            const { accessToken, refreshToken: newRefreshToken } = response.data;
            this.setLocalAccessToken(accessToken);
            this.setUser({ ...this.getUser(), refreshToken: newRefreshToken });
            return accessToken;
        } catch (error) {
            console.error('Error refreshing token:', error);
            throw error;
        }
    }
}

const tokenService = new TokenService();

// Listen for storage events to synchronize token changes across tabs
window.addEventListener('storage', (event) => {
    if (event.key === 'user') {
        dispatchEvent(new Event(USER_CHANGED_EVENT));
    }
});

export default tokenService;
