import coreClient from "../../services/coreApi";

export const useRewriteBullet = () => {
  return async(payload)=>{
    try {
        const res = await coreClient.post('/rewrite-text', payload);
        return res;
    } catch (error) {
        throw error;
    }
  }
}

