import React from 'react';
import { motion } from 'framer-motion';

const CoverLetter01 = ({ data }) => {
  const letterContents = data?.coverLetterContent ? data.coverLetterContent.split('.').filter(Boolean).map(content => content.trim()) : [];
  return (
    <motion.div 
    initial={{ x: -100, opacity: 0, scale: 0.8 }}
    animate={{ x: 0, opacity: 1, scale: 1 }} 
    transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
    className=''>
    <div className='font-primary text-xxsm mt-3'>
        <div className='flex gap-5 justify-between border-b-2 border-slate-400 py-2'>
          <h1 className='text-title text-lightDark leading-[36px] font-bold capitalize '>{data?.lastName} {data?.firstName} </h1>
          <ul className='text-right font-normal'>
              <li>{data?.phone}</li>
              <li>{data?.applicantCity}, {data?.applicantCountry} {data?.applicantZipCode ? '/' + data?.applicantZipCode : ''}</li>
              <li>{data?.email}</li>
          </ul>
        </div>
        <div>
          <h2 className='mt-4 font-medium'>{data?.applicationDate}</h2>
          <ul className='my-3 font-medium'>
              <li>{data?.companyName}</li>
              <li>{data?.companyCity}, {data?.companyCountry} {data?.companyZipCode ? '/' + data?.companyZipCode : ''}</li>
          </ul>
          {/* <h2 className='capitalize text-xxsm mt-5 mb-10'><b>RE:</b> {data?.jobTitle}</h2> */}
        </div>
        <div className='my-10'>
          {letterContents.map((content, i) => (
            <p key={i} className='my-2'>{content}{i !== letterContents.length - 1 ? '.' : ''}</p>
          ))}
          <p>
          </p>
          <h2 className='my-5 capitalize font-medium'>{data?.lastName} {data?.firstName}</h2>
        </div>
        
      </div>
    </motion.div>
  )
}

export default CoverLetter01