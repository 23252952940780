import React from 'react'


const DownloadAppButton = ({ icon, text }) => {
  return (
    <button className='flex items-center justify-center gap-2 py-2 px-3 border 
    rounded-md hover:bg-light duration-300 ease-in bg-white'>
        <span>{icon}</span>
        {text}
    </button>
  )
}

export default DownloadAppButton