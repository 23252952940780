
import axios from 'axios';
import { baseURL } from '../../services/baseURL.service';


export const useGoogleAuth = () => {
  return async()=>{
    try {
        const response = await axios.get(`${baseURL}/auth/google`);
        return response
    } catch (error) {
        throw error
    }
  }
}

