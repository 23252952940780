import React from 'react'

const ResumeListSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 w-full my-3">
    <div className="skeleton h-32 w-full rounded-none"></div>
    <div className="skeleton h-4 w-full"></div>
    <div className="skeleton h-4 w-full"></div>
    <div className='flex items-center justify-between'>
        <div className="skeleton h-4 w-28"></div>
        <div className='flex items-center gap-3'>
            <div className="skeleton h-4 w-4 rounded-full"></div>
            <div className="skeleton h-4 w-4 rounded-full"></div>
        </div>
    </div>
  </div>
  )
}

export default ResumeListSkeleton