import React, { useState, useEffect } from 'react';
import { Plus, Trash2,  Grip, Pencil, RotateCw } from 'lucide-react';
import { toast } from 'sonner';
import { SpringLoader } from '../loader';
import { useGenerateBullet } from '../../hooks/post/useGenerateBullet';
import { useRewriteBullet } from '../../hooks/post/useRewriteBullet';

import { StartDateSelector, EndDateSelector } from './dates';


const ExperienceForm = ({ 
  workExperience, 
  handleExperienceChange, 
  addWorkExperience, 
  handleDeleteSection,
  updateResume,
  selectedResumeId,
  setResume,
  setWorkExperience,
  loadingAddItem,
  loadingDelete,
}) => {
  const [selectedExperience, setSelectedExperience] = useState('');
  const [selectedBulletIndex, setSelectedBulletIndex] = useState('');
  const [isActiveExperienceTab, setIsActiveExperienceTab] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const rewriteBullet  = useRewriteBullet();
  const generateBullet = useGenerateBullet();





  const addBulletPoint = (index) => {
    const updatedWorkExperience = [...workExperience];
    updatedWorkExperience[index].bulletPoints.push('');
    handleExperienceChange(index, 'bulletPoints', updatedWorkExperience[index].bulletPoints);
    toast.success('New bullet point section deleted')
};

const deleteBulletPoint = (experienceIndex, bulletIndex) => {
    const updatedWorkExperience = [...workExperience];
    updatedWorkExperience[experienceIndex].bulletPoints.splice(bulletIndex, 1);
    handleExperienceChange(experienceIndex, 'bulletPoints', updatedWorkExperience[experienceIndex].bulletPoints);
    toast.success('bullet point deleted')
};

  const handleSelectedExperience = (experience)=>{
    setSelectedExperience(experience)
    setIsActiveExperienceTab(true)
  }

  // useEffect(()=>{
  //   handleSelectedExperience(workExperience[0]?._id)
  // },[workExperience]);

  const handleDragStart = (event, experienceId) => {
    event.dataTransfer.setData('text/plain', experienceId);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async(event, targetId) => {
    event.preventDefault();
    const draggedId = event.dataTransfer.getData('text/plain');
    
    const draggedItem = workExperience.find((item) => item?._id === draggedId);
    const targetIndex = workExperience.findIndex((item) => item?._id === targetId);

    // Create a copy of the workExperience array
    const newItems = [...workExperience];

     // Remove the dragged item from its original position
    newItems.splice(newItems.indexOf(draggedItem), 1);

     // Insert the dragged item at the target position
    newItems.splice(targetIndex, 0, draggedItem);

    try {
      
      const { status, data } = await updateResume(selectedResumeId, { workExperience:{ workExperienceArray: newItems } });
      if(status===200){
        setResume(data?.data);
        setWorkExperience(data?.data?.workExperience?.workExperienceArray)
        toast.success('experience items rearranged')
      }
    } catch (error) {
      return error
    }
  };
  

  const handleGenerateBullet = async (i, bulletIndex) => {
    try {
      setSelectedBulletIndex(bulletIndex)
      let updatedWorkExperience = [...workExperience];
      let experienceTitle = updatedWorkExperience[i]?.title;
      if(!experienceTitle){
        toast.error('please provide a professional job title e.g Business administrator to generate bulletpoint');
        return
      }
      setLoadingText(true)
      const { status, data } = await generateBullet({ jobTitle: experienceTitle });
      if (status === 200) {
        updatedWorkExperience[i].bulletPoints[bulletIndex] = data?.data;
        setWorkExperience(updatedWorkExperience);
        handleExperienceChange(i, 'bulletPoints', updatedWorkExperience[i].bulletPoints);
        toast.success('Bullet point generated successfully');
      }
    } catch (error) {
      return error
    }finally{
      setLoadingText(false)
    }
  };
  const handleRewriteBullet = async (i, bulletIndex) => {
    try {
      setSelectedBulletIndex(bulletIndex)
      let updatedWorkExperience = [...workExperience];
      let existingBullet = updatedWorkExperience[i]?.bulletPoints[bulletIndex];
      setLoadingText(true)
      const { status, data } = await rewriteBullet({ textContent: existingBullet });
      if (status === 200) {
        // Replace the existing bullet point with the rewritten text
        updatedWorkExperience[i].bulletPoints[bulletIndex] = data?.data;
        setWorkExperience(updatedWorkExperience);
        handleExperienceChange(i, 'bulletPoints', updatedWorkExperience[i].bulletPoints);
        toast.success('Bullet point rewritten successfully');
      }
    } catch (error) {
      return error
    }finally{
      setLoadingText(false)
    }
  };
  
  return (
    <>
    {
      workExperience && workExperience.map((experience, i)=>(
      <div key={experience?._id}
      >
        <div className='flex justify-between items-center my-3 px-3 py-3 bg-gray-100'
          draggable
          onDragOver={handleDragOver}
          onDragStartCapture={(event) => handleDragStart(event, experience?._id)}
          onDropCapture={(event) => handleDrop(event, experience?._id)}
        >
          <h3 className='flex items-center gap-2'
          
          >
            <span className='cursor-grab'><Grip size={17}/></span>
            Experience #{i + 1} - {experience?.title}
          </h3>
          <div className='flex items-center gap-3'>
            <span onClick={()=>handleSelectedExperience(experience?._id)} 
            className='cursor-pointer bg-white p-2 rounded-full tooltip' data-tip="edit"><Pencil size={13}/>
            </span>
            <span onClick={()=>{
              setSelectedExperience(experience?._id)
              handleDeleteSection(experience?._id)
            }} className='cursor-pointer p-2 bg-red-100 rounded-full tooltip' data-tip="delete">
              {/* <Trash2 color='red' size={15}/> */}
              {loadingDelete && selectedExperience === experience?._id ? (
                <span className="loading loading-spinner loading-xs"></span>
              ) : (
                <Trash2 color='red' size={15} />
              )}
            </span>
          </div>
        </div>
        {
          isActiveExperienceTab && selectedExperience === experience?._id &&(
            <>    
              <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div>
                  <label htmlFor="">Position Held</label>
                  <input 
                    type="text"
                    name=''
                    value={experience.title}
                    onChange={(e) => handleExperienceChange(i, 'title', e.target.value)}
                    placeholder='Professional title e.g Accountant'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div>
                  <label htmlFor="">Company Name</label>
                  <input 
                    type="text"
                    name='company'
                    value={experience?.company}
                    onChange={(e) => handleExperienceChange(i, 'company', e.target.value)}
                    placeholder='company name'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div>
                  <label htmlFor="">Company location</label>
                  <input 
                    type="text"
                    name='location'
                    value={experience?.location}
                    onChange={(e) => handleExperienceChange(i, 'location', e.target.value)}
                    placeholder='company location'
                    className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                  />
                </div>
                <div className='flex flex-col md:flex-row items-center md:mt-6 gap-1 relative'>
                  <div className='w-full md:w-1/2'>
                    {/* <label htmlFor="">start date</label> */}
                    {/* <input 
                      type="date"
                      name='startDate'
                      value={experience?.startDate}
                      onChange={handleExperienceChange}
                      placeholder='role location'
                      className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                    /> */}
                    <StartDateSelector
                      index={i}
                      handleExperienceChange={handleExperienceChange}
                      initialStartDate={experience?.startDate}
                    />
                  </div>
                  <div className='w-full md:w-1/2'>
                    {/* <label htmlFor="">end date</label>
                    <input 
                      type="date"
                      name='endDate'
                      value={experience?.endDate}
                      onChange={handleExperienceChange}
                      placeholder='role location'
                      className='w-full border border-gray-400 rounded-md px-4 mt-2 py-1 pr-10 focus:outline-none focus:border-slate-500 text-gray-400'
                    /> */}
                    <EndDateSelector
                      index={i}
                      handleExperienceChange={handleExperienceChange}
                      initialEndDate={experience?.endDate}
                    />
                  </div>
                </div>
              </div>
              <div className='my-3'>
                <div className='flex justify-between items-center py-2'>
                  <h3 className='mb-2 capitalize'>Edit bulletPoints</h3>
                  <button 
                  onClick={() => addBulletPoint(i)}
                  className='flex items-center gap-1 rounded-full bg-[#808081] text-white py-1 px-3 font-bold'
                  >Add Bullet Point <Plus size={15} /></button>
                </div>
                {experience.bulletPoints.map((bulletPoint, bulletIndex) => (
                  <div key={bulletIndex} className='relative my-3'>
                    <textarea
                        type="text"
                        className='w-full rounded-md outline-none px-4 py-4 pr-10 
                        hover:border hover:border-dark focus:border-2 focus:border-slate-500
                         text-gray-400 overflow-hidden cursor-pointer focus:cursor-text'
                        value={bulletPoint}
                        rows={Math.max(2, bulletPoint?.split('\n').length)}
                        onChange={(e) => {
                            const updatedBulletPoints = [...experience.bulletPoints];
                            updatedBulletPoints[bulletIndex] = e.target.value;
                            handleExperienceChange(i, 'bulletPoints', updatedBulletPoints);
                        }}
                        placeholder="...start writing bullet points or click on icon to generate"
                    />
                    <button 
                    onClick={() => deleteBulletPoint(i, bulletIndex)}
                    className='flex items-center gap-1 absolute top-1 right-1 bg-red-100 rounded-full p-1 tooltip tooltip-top'
                    data-tip="delete">
                    <Trash2 size={10} color='red' />
                    </button>
                    {
                      bulletPoint.length > 1 && (
                        <button 
                        onClick={() => handleRewriteBullet(i, bulletIndex)}
                        className='flex items-center gap-1 absolute top-7 right-1 bg-gray-500 rounded-full p-1 tooltip tooltip-top'
                        data-tip="rewrite text">
                          {
                            loadingText && selectedBulletIndex===bulletIndex ? 
                            <span className="loading loading-spinner loading-xs"></span> 
                            : <RotateCw size={10} color='white' />
                          }
                        
                        </button>
                      )
                    }
                    {
                      bulletPoint.length < 1 && (
                        <button 
                        onClick={() => handleGenerateBullet(i, bulletIndex)}
                        className='flex items-center gap-1 absolute top-7 right-1 bg-gray-500 rounded-full p-1 tooltip tooltip-top'
                        data-tip="Generate">
                        {
                            loadingText && selectedBulletIndex===bulletIndex ? 
                            <span className="loading loading-spinner loading-xs"></span> 
                            : <Pencil size={10} color='white' />
                          }
                        </button>
                      )
                    }
                  </div>
                  ))}
              </div>
            </>
          )
        }
      </div>
      ))
    }
    {
      loadingAddItem ? <SpringLoader /> : <button 
      onClick={addWorkExperience}
    className='w-full flex items-center justify-center gap-3 text-center text-white bg-dark py-2 rounded-full hover:bg-gray-700 font-bold'>
      Add work experience
      <Plus size={20}/>
    </button>
    }
      
    </>
  )
}

export default ExperienceForm