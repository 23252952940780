import coreClient from "../../services/coreApi";

export const useGetResume = () => {
  return async(resumeId)=>{
    try {
        const res = await coreClient.get(`/resume/${resumeId}`);
        return res;
    } catch (error) {
        throw error;
    }
  }
}

