import { FaTimesCircle } from 'react-icons/fa';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';
import { useCoverLetterTemplateContext } from '../../contexts/coverLetterTemplateContext';
import { useCoverLetterDataContext } from '../../contexts/coverLetterDataContext';
import { PreviewCoverLetter01, PreviewCoverLetter02 } from '../cover-letters/preview-cover-letters';


const CoverLetterPreviewModal = ({ children, modalOpen, modalClose }) => {
    const { coverLetterTemplate } = useCoverLetterTemplateContext();
    const { coverLetter } = useCoverLetterDataContext();
   
  return (
    <>
        {
            modalOpen && (
                <div className='w-full bg-white h-screen absolute top-0 left-0 overflow-y-auto z-25'>
                    <motion.div 
                    initial={{ 
                        x: -100, 
                        opacity: 0, 
                    }} 
                    animate={{ x: 0, opacity: 1, scale: 1 }} 
                    transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
                    className='h-full bg-white  rounded-md relative'>
                        <span onClick={modalClose} className="cursor-pointer fixed right-3 top-5 text-slate-500 bg-white p-3 rounded-full shadow-lg">
                            <X />
                        </span>
                        <div className='p-5'>
                            {
                                coverLetterTemplate === 1 ? <PreviewCoverLetter01 data={coverLetter} /> :
                                coverLetterTemplate === 2 ? <PreviewCoverLetter02 data={coverLetter} /> : null
                            }
                        </div>
                    </motion.div>
                </div>
            )
        }
    </>
  )
}

export default CoverLetterPreviewModal