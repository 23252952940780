import React from 'react';
import { CoverLetterDoc01, CoverLetterDoc02 } from '../../components/cover-letter-docs';


const CoverLetterDocumentPDF = ({ coverLetterTemplate, data }) => {
    switch (coverLetterTemplate) {
        case 1:
          return <CoverLetterDoc01 data={data} />;
        case 2:
          return <CoverLetterDoc02 data={data} />;
        default:
          return null;
      }
}

export default CoverLetterDocumentPDF