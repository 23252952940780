import React from 'react'

const CreateDocumentCard = ({ type, icon1, icon2, handleSelectTab }) => {
  return (
    <div 
    onClick={()=>handleSelectTab(type==='RESUME' ? 'create resume' : 'create cover letter')}
    className={`w-11/12 md:w-9/12 relative border rounded-md my-2   duration-300
     cursor-pointer ${type==='RESUME' 
     ? 'bg-dark/80 text-white hover:-translate-y-3 hover:scale-105' 
     : 'hover:translate-y-3 hover:scale-110'}`}>
        <div className='flex justify-between items-center py-5 px-3'>
        <div className='flex items-center gap-3'>
            <span className={`rounded-full p-3 bg-[#e3e3e3]`}>{icon1}</span>
            <h3 className='text-xsm capitalize font-normal'>
            {
                type === 'RESUME' 
                ? 'create resume' 
                :  'create cover letter' 
            }
            </h3>
        </div>
        <span>{icon2}</span>
        </div>
    </div>
  )
}

export default CreateDocumentCard