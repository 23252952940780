
import { useState } from 'react';
import coreClient from '../../services/coreApi';

export const useCreateCoverLetter = () => {
  const [loading, setLoading] = useState(false);

  const createCoverLetter = async (payload) => {
    setLoading(true);
    try {
      const response = await coreClient.post('/generate-cover-letter', payload );
      return response;
    } catch (error) {
    //   if (error.response) {
    //     setError(error.response.data.error);
    //   } else if (error.request) {
    //     setError('No response from server. Please try again later.');
    //   } else {
    //     setError('Error: ' + error.message);
    //   }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { createCoverLetter, setLoading, loading };
};