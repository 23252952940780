// import axios from 'axios';
// import tokenService from './token.service';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; 
import { toast } from 'sonner';
import tokenService from './token.service';
import history from './history';
import { baseURL } from './baseURL.service';

const coreClient = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// coreClient.interceptors.request.use((config) => {
//     const token = tokenService.getLocalAccessToken();

//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

coreClient.interceptors.request.use((config) => {
    const token = tokenService.getLocalAccessToken();
    // if(!token){
    //     console.log('no token');
    //     history.push('/sign-in');
    // }
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Current time in seconds

            if (decodedToken.exp < currentTime) {
                // Token is expired, log out and redirect to login page
                tokenService.removeUser(); // Remove token from storage
                toast.error('Your session has expired, please login!')
                history.push('/sign-in');
                return Promise.reject(new Error('Session expired, redirecting to login'));
            }

            config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
            // If there's an error decoding the token, also log out
            tokenService.removeUser();
            history.push('/sign-in');
            return Promise.reject(error);
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});



coreClient.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const newAccessToken = await tokenService.refreshToken();
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return coreClient(originalRequest);
        } catch (refreshError) {
            tokenService.removeUser();
            return Promise.reject(refreshError);
        }
    }

    return Promise.reject(error);
});

export default coreClient;
