import coreClient from "../../services/coreApi";
export const useUpdateCoverLetter = () => {
  return async(coverLetterId, payload)=>{
    try {
        const res = await coreClient.put(`/cover-letter/${coverLetterId}/update`, payload);
        return res;
    } catch (error) {
        throw error
    }
  }
}

