import React from 'react';
import { motion } from 'framer-motion';
import { Check, Linkedin, StickyNote, AudioLines, ChevronRight, FilePlus } from 'lucide-react';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { CreateDocumentCard } from '../../components/cards';

const CreateDocument = () => {
    const { handleSelectTab } = useSideBarContext();
  return (
    <div 
      initial={{ x: -100, opacity: 0, scale: 0.8 }} 
      animate={{ x: 0, opacity: 1, scale: 1 }} 
      transition={{ type: 'spring', stiffness: 80, damping: 10 }}
      className='h-full md:h-3/4 flex flex-col justify-center items-center font-primary'>
          <div className="hero-content text-center">
              <div className="max-w-md">
              <h1 className="text-lg font-bold leading-9">Create Document</h1>
              <p className="py-6">
                Choose a button below to create either a resume or cover letter.
              </p>
              </div>
          </div>
      
          <CreateDocumentCard
              type='RESUME'
              icon1={<FilePlus color='black' />}
              icon2={<ChevronRight />}
              handleSelectTab={handleSelectTab}
          />
          <CreateDocumentCard
              type='COVERLETTER'
              icon1={<StickyNote />}
              icon2={<ChevronRight />}
              handleSelectTab={handleSelectTab}
          />
        
    </div>
  )
}

export default CreateDocument