import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';


import MontserratRegular from "../../assets/fonts/Montserrat-Regular.ttf";
import MontserratItalic from "../../assets/fonts/Montserrat-Italic.ttf";
import MontserratBold from "../../assets/fonts/Montserrat-Bold.ttf";
import MontserratBoldItalic from "../../assets/fonts/Montserrat-BoldItalic.ttf";
import MontserratLight from "../../assets/fonts/Montserrat-Light.ttf";


import MerriweatherRegular from "../../assets/fonts/Merriweather-Regular.ttf";
import MerriweatherItalic from "../../assets/fonts/Merriweather-Italic.ttf";
import MerriweatherBold from "../../assets/fonts/Merriweather-Bold.ttf";
import MerriweatherBoldItalic from "../../assets/fonts/Merriweather-BoldItalic.ttf";
import MerriweatherLight from "../../assets/fonts/Merriweather-Light.ttf";


Font.register({
  family: "Montserrat",
  fonts: [
    { src: MontserratRegular },
    { src: MontserratItalic, fontStyle: "italic" },
    { src: MontserratBold, fontWeight: "bold" },
    { src: MontserratLight, fontWeight: "light" },
    { src: MontserratBoldItalic, fontStyle: "italic", fontWeight: "bold" },
  ],
});

Font.register({
  family: "Merriweather",
  fonts: [
    { src: MerriweatherRegular, fontWeight: "regular"  },
    { src: MerriweatherItalic, fontStyle: "italic" },
    { src: MerriweatherBold, fontWeight: "bold" },
    { src: MerriweatherLight, fontWeight: "light" },
    { src: MerriweatherBoldItalic, fontStyle: "italic", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
  },
  contact: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap:'wrap',
    marginBottom: 6,
    rowGap: 8,
  },
  contactItems: {
    flexDirection:'row',
    alignItems: "center",
    marginLeft: 9,
    gap: 2,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 28,
    fontWeight: "bold",
    textTransform: "capitalize",
    textAlign: "center",
    color:'#14171ac1'
  },
  smallText: {
    fontSize:9,
    fontFamily: 'Montserrat'
  },
  text: {
    fontSize:9,
    fontFamily: 'Montserrat',
    fontWeight: "normal",
    // color: "#283d56",
  },
  section: {
    // paddingHorizontal: 10,
    marginHorizontal: 10,
    marginTop: 3,
    paddingTop: 5,
    flexGrow: 1,
  },
  sectionTitleText: {
    marginBottom: 8,
    marginTop: 8,
    paddingTop: 8,
    paddingBottom: 10,
    // borderTop: "1px solid #DEDBDB",
    borderBottom: "1px solid #283d56",
    fontSize:9,
    fontFamily: 'Montserrat',
    fontWeight: "normal",
  },
  sectionSubTitleText: {
    fontSize:8.5,
    fontFamily: 'Montserrat',
    fontWeight: "bold",
    color:'#14171ac1',
    marginTop: 6,
    marginBottom: 2,
  },
  skillsSectionText: {
    fontSize:8.5,
    fontFamily: 'Montserrat',
    fontWeight: "bold",
    flexDirection: "row", 
    flexWrap: "wrap",
    color:'#14171ac1'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});


const summaryComp = (data)=>{
  return <View style={styles.section}>
  {data?.summary && (<View className='mt-3 '>
      <Text style={styles.sectionTitleText}>
          SUMMARY
      </Text>
      <Text style={styles.text}>{data?.summary.summary}</Text>
  </View>)}
</View>
}

const workExperienceComp = (data)=>{
  return    <View style={styles.section}>
  {
      data?.workExperience?.workExperienceArray?.length > 0 && (
          <>
              <Text style={styles.sectionTitleText}>
                  WORK EXPERIENCE
              </Text>
              {
                  data?.workExperience?.workExperienceArray?.map((experience)=>(
                      <View key={experience?._id} className=''>
                          <Text style={styles.sectionSubTitleText}>
                              { experience?.title && experience?.title } 
                              { experience?.company && ' | ' + experience?.company }  
                              { experience?.location && ' | ' + experience?.location }
                              { experience?.startDate && ' | ' + experience?.startDate } 
                              { experience?.endDate && ' - ' + experience?.endDate} 
                          </Text>
                          <View>
                              <View>
                                  {experience?.bulletPoints.map((points, index) => (
                                      <Text style={[styles.text, { marginVertical: 2 }]} key={index}>{points}</Text>
                                  ))}
                              </View>
                          </View> 
                      </View>
                  ))
              }
          </>
      )
  }
</View>
}

const educationComp = (data)=>{
  return <View style={styles.section}>
    {
            data?.education?.educationArray?.length > 0 && (
                <>
                    <Text 
                    style={styles.sectionTitleText}
                    >
                        EDUCATION
                    </Text>
                    {
                        data?.education?.educationArray?.map((education)=>(
                            <View key={education._id} className=''>
                                <Text style={styles.sectionSubTitleText}>
                                    { education?.degree && education?.degree } 
                                    { education?.fieldOfStudy && ' | ' + education?.fieldOfStudy }
                                    { education.institution && ' | ' + education?.institution }  
                                    { education?.location && ' | ' + education?.location }
                                    { education?.year && (education?.year !=='null' ? (' | ' + education?.year) : '')}
                                </Text>
                            </View>
                        ))
                    }
                </>
            )
        }
  </View>
}
const certificationComp = (data)=>{
  return <View style={styles.section}>
    {
            data?.certifications?.certificationsArray?.length > 0 && (
                <>
                    <Text 
                    style={styles.sectionTitleText}
                    >
                        CERTIFICATIONS
                    </Text>
                    {
                        data?.certifications?.certificationsArray?.map((certification)=>(
                            <View key={certification._id} className=''>
                                <Text style={styles.sectionSubTitleText}>
                                    { certification?.certificationName && certification?.certificationName } 
                                    { certification.issuingOrganization && ' | ' + certification.issuingOrganization }  
                                    {/* { certification?.certificationUrl && ' | ' + certification?.certificationUrl } */}
                                    { certification?.year && ' | ' + certification?.year }
                                </Text>
                            </View>
                        ))
                    }
                </>
            )
        }
  </View>
}

const skillsComp = (data)=>{
  return <View style={styles.section}>
    {
            data?.skills?.skillsArray?.length > 0 && (
            <>
                <Text 
                  style={styles.sectionTitleText}
                >
                    SKILLS
                </Text> 
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {data?.skills && data?.skills?.skillsArray?.map((skill, index) => (
                  <Text style={styles.skillsSectionText} key={index}>
                    {index === data.skills?.skillsArray?.length - 1 ? skill.skill + '.' : skill.skill + ', '}
                  </Text>
                ))}
              </View>
            </>)
        }
  </View>
}



const renderSection = (section, data) => {
  switch (section.name) {
      case 'SUMMARY':
          return summaryComp(data);
      case 'EXPERIENCE':
          return workExperienceComp(data);
      case 'EDUCATION':
          return educationComp(data);
      case 'CERTIFICATIONS':
          return certificationComp(data);
      case 'SKILLS':
          return skillsComp(data);
      default:
          return null;
  }
};

const ResumeDoc02 = ({ data, sections }) => (
  <Document>
    <Page size="A4" style={styles.page}>
        <View>
            <View style={styles.section}>
              <Text style={styles.title}>{data?.lastName} {data?.firstName}</Text>
              <View style={styles.contact}>
                {
                    data?.address && (
                        <View style={styles.contactItems}>
                            <Text style={{ fontSize: 16}}>•</Text>
                            <Text className=' font-normal' style={styles.smallText}>{data?.address}</Text>
                        </View>
                    )
                }
                {
                    data?.email && (
                        <View style={styles.contactItems}>
                            <Text style={{ fontSize: 16}}>•</Text>
                            <Text className=' font-normal' style={styles.smallText}>{data?.email}</Text>
                        </View>
                    )
                }
                {
                    data?.phone && (
                        <View style={styles.contactItems}>
                            <Text style={{ fontSize: 16}}>•</Text>
                            <Text className=' font-normal' style={styles.smallText}>{data?.phone}</Text>
                        </View>
                    )
                }
                {
                    data?.linkedin && (
                        <View style={styles.contactItems}>
                            <Text style={{ fontSize: 16}}>•</Text>
                            <Text className=' font-normal' style={styles.smallText}> {data?.linkedin}</Text>
                        </View>
                    )
                }
              </View>
            </View>
            {sections.map((section, index) => (
            <View key={`${section.name}-${index}`}>
                {renderSection(section, data)}
            </View>
            ))}
        </View>
        <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </Page>
  </Document>
)

export default ResumeDoc02