import React, { useState, createContext, useContext } from 'react'

const ResumeFormSectionContext = createContext();

export const ResumeFormSectionContextProvider = ({ children }) => {
    const [isActiveSection, setIsActiveSection] = useState(false);
    const [selectedSection, setSelectedSection] = useState('');

    const handleSelectedSection = (sectionName)=>{
        setSelectedSection(sectionName); 
        setIsActiveSection(true); 
    }

  return (
    <ResumeFormSectionContext.Provider value={{
        isActiveSection,
        selectedSection,
        setSelectedSection,
        handleSelectedSection,
    }}>
        { children }
    </ResumeFormSectionContext.Provider>
  )
}

export const useResumeFormSectionContext = ()=> useContext(ResumeFormSectionContext)

