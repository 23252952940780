import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { resumeTemplates } from "../../utils/resumeTemplates";

const TemplatesLibrary3 = () => {
  return (
      <div className="w-full h-full bg-white rounded-md p-7 hidden md:block">
        <ShuffleGrid />
      </div>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};



const generateSquares = () => {
  return shuffle(resumeTemplates).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="h-full rounded-md shadow-lg cursor-pointer p-2"
    //   style={{
    //     backgroundImage: `url(${sq.image})`,
    //     backgroundSize: "contain",
    //   }}
    >
        <img src={sq.image} alt="" />
    </motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default TemplatesLibrary3;