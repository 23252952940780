import React from 'react'
import { Formik, Form } from "formik";
import { toast } from 'sonner';
import { TextInputlg } from '../inputs';
import { linkedinFormSchema } from '../../../utils/yupSchemas';

const CreateResumeViaLinkedinForm = ({ setLoading, setProgressValue, createResume, setResume, setSideBarTab, setSelectedResumeId, handleTogglePayWallModal }) => {
  return (
    <div className='bg-white p-3 rounded-md'>
      <Formik
              initialValues={{ email: "", linkedinUrl:"" }}
              validationSchema={linkedinFormSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  setLoading(true)
                  const { status, data } = await createResume({
                    type: 'resume',
                    form:{
                        email: values.email,
                        linkedinUrl: values.linkedinUrl,
                    }
                  });
                  if(status===200 && data?.data !==null){
                    setResume(data?.data);
                    setSideBarTab('edit resume')
                    setSelectedResumeId(data?.data?._id);
                    setProgressValue(0);
                    toast.success('resume created succesfully!')
                  }
                  setSubmitting(false);
                  setLoading(false);
                  setProgressValue(0);
                  //toast.success('Sorry an error occured, please try again!');
                } catch (error) {
                  setProgressValue(0);
                  setLoading(false);
                  if (error.response && error.response.data) {
                    if(error.response.data.message==='Permission denied. Maximum number of resumes created for your account.'){
                      toast.error(`${error.response.data.message}`);
                      handleTogglePayWallModal();
                    }
                    toast.error(`${error.response.data.message}`);
                  } else if (error.request) {
                      toast.error('No response from server. Please try again later.');
                  } else {
                    toast.error(`Error: ${error.message}`);
                  }
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    {/* <TextInputlg
                      label='Your full name'
                      name='name'
                      autoComplete='name'
                      type='text'
                      placeholder='Your full name'
                    /> */}
                    <TextInputlg
                      label='Your email'
                      name='email'
                      autoComplete='email'
                      type='email'
                      placeholder='Your email'
                    />
                    <TextInputlg
                      label='Your linkedin url'
                      name='linkedinUrl'
                      autoComplete='linkedinUrl'
                      type='text'
                      placeholder='https://www.linkedin.com/john-doe'
                    />
                  </div>
                  <div className='my-4'>
                    <button
                    type='submit' disabled={isSubmitting}
                    className='w-full bg-dark py-3 text-white rounded-md'>
                      {isSubmitting ? "Creating Resume" : "Create Resume"}
                    </button>
                </div>
                </Form>
              )}
      </Formik>
    </div>
  )
}

export default CreateResumeViaLinkedinForm