import React, { useState, createContext, useContext } from 'react'

const CoverLetterItemContext = createContext();

export const CoverLetterItemContextProvider = ({ children }) => {

    const [ selectedCoverLetterId, setSelectedCoverLetterId] = useState(null);

  return (
    <CoverLetterItemContext.Provider value={{
        selectedCoverLetterId,
        setSelectedCoverLetterId
    }}>
        { children }
    </CoverLetterItemContext.Provider>
  )
}

export const useCoverLetterItemContext = ()=> useContext(CoverLetterItemContext)

