import React from 'react'
import TopFooter from './TopFooter';
import BottomFooter from './BottomFooter';
const Footer = () => {
  return (
    <>
        {/* <TopFooter /> */}
        <BottomFooter />
    </>
  )
}

export default Footer