import React from 'react';
import { motion } from 'framer-motion';

const PreviewCoverLetter02 = ({ data }) => {
  return (
    <motion.div 
    initial={{ x: -100, opacity: 0, scale: 0.8 }}
    animate={{ x: 0, opacity: 1, scale: 1 }} 
    transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
    className=''>
    <div className='font-primary text-xxsm'>
        <h1 className='text-center text-title text-lightDark  leading-[36px] pb-3 font-bold capitalize border-b-2 border-slate-400'>{data?.lastName} {data?.firstName}</h1>
        <ul className='flex items-center justify-center gap-2 py-3'>
            {
                (data?.applicantCountry || data?.applicantState) && (
                    <li className='flex items-center gap-1'>
                        <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.applicantState} {data?.applicantCountry ? ', ' + data?.applicantCountry : ''}</h4>
                    </li>
                )
            }
            {
                data?.email && (
                    <li className='flex items-center gap-1'>
                        <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.email}</h4>
                    </li>
                )
            }
            {
                data?.phone && (
                    <li className='flex items-center gap-1'>
                        <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.phone}</h4>
                    </li>
                )
            }{
                data?.linkedin && (
                    <li className='flex items-center gap-1'>
                       <span className='text-sm'>•</span>
                        <h4 className=' font-normal'>{data?.linkedin}</h4>
                    </li>
                )
            }
        </ul>
        <div>
          <h2 className='mt-4 font-medium'>{data?.applicationDate}</h2>
          <ul className='my-3 font-medium'>
              <li>{data?.companyName}</li>
              <li>{data?.companyCity}, {data?.companyCountry} {data?.companyZipCode ? '/' + data?.companyZipCode : ''}</li>
          </ul>
          {/* <h2 className='capitalize text-xxsm'><b>RE:</b> {data?.jobTitle}</h2> */}
        </div>
        <div className='my-10'>
          {/* {data?.coverLetterContent} */}
          {data?.coverLetterContent && data?.coverLetterContent.split('.').map((content, i)=>(
            <p key={i} className='my-2'>{content}.</p>
          ))}
          <p>
          </p>
          <h2 className='my-5 capitalize font-medium'>{data?.lastName} {data?.firstName}</h2>
        </div>
        
      </div>
    </motion.div>
  )
}

export default PreviewCoverLetter02