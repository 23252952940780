
import axios from 'axios';
import { baseURL } from '../../services/baseURL.service';



export const useSignUp = () => {
  return async(payload)=>{
    try {
        const response = await axios.post(`${baseURL}/auth/sign-up`,
           payload,
           {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          });
        console.log(response, 'res')
        return response
    } catch (error) {
        throw error
    }
  }
}

