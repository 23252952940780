import React from 'react';
import { useLocation } from 'react-router-dom';
import { SuccessPage } from '../../components/success-pages';

const SignUpSuccess = () => {
  const location = useLocation();
  const { email } = location.state || {}; 
  return (
    <SuccessPage link={'sign-in'}>
        <div>
            Account created successfully. <br /> Please check your email <b>{email && email}</b> for verification.
        </div>
    </SuccessPage>
  )
}

export default SignUpSuccess