import { useState } from "react";
import coreClient from "../../services/coreApi";

export const useGetPaymentDetails = () => {

  const getPaymentDetails = async () => {
    try {
      const response = await coreClient.get('/payment-details');
      return response;
    } catch (error) {
      throw error;
    } 
  };

  return { getPaymentDetails };
}

