
const CircularProgress = ({ value }) => {
  return (
   <div className='h-full flex flex-col justify-center items-center bg-white'>
     <div 
        className="radial-progress" 
        style={{ "--value": value, "--size": "12rem", "--thickness": "2px" }} 
        role="progressbar"
     >
        {value}%
     </div>
     <h3 className='my-5 capitalize'>{value > 90 ? '...Finalising' : 'Generating your document'}</h3>
   </div>
  )
}

export default CircularProgress