
import axios from 'axios';
import { baseURL } from '../../services/baseURL.service';

export const useSignIn = () => {
  return async(payload)=>{
    try {
        const response = await axios.post(`${baseURL}/auth/sign-in`, payload);
        return response
    } catch (error) {
        throw error
    }
  }
}

