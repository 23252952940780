import React from 'react'

const PaymentSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 w-full my-3">
        <div className='flex items-center gap-3'>
            <div className="skeleton h-3 w-3 rounded-full"></div>
            <div className="skeleton h-3 w-3 rounded-full"></div>
            <div className="skeleton h-3 w-3 rounded-full"></div>
        </div>
        <div className="skeleton h-3 w-64"></div>
        <div className="skeleton h-3 w-64"></div>
        <div className="skeleton h-3 w-64"></div>
        <div className="skeleton h-3 w-full"></div>
        <div className="skeleton h-3 w-full"></div>
        <div className="skeleton h-3 w-full"></div>
        <div className='flex items-center gap-3'>
            <div className="skeleton h-3 w-full"></div>
            <div className="skeleton h-3 w-full"></div>
        </div>
        <div className='flex items-center gap-3'>
            <div className="skeleton h-3 w-full"></div>
            <div className="skeleton h-3 w-full"></div>
        </div>
  </div>
  )
}

export default PaymentSkeleton