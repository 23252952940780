import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Link } from '@react-pdf/renderer';
import { insertBreakablePoints, insertBreakablePointsInEmail } from '../../utils/resumeMethods';

import MontserratRegular from "../../assets/fonts/Montserrat-Regular.ttf";
import MontserratItalic from "../../assets/fonts/Montserrat-Italic.ttf";
import MontserratBold from "../../assets/fonts/Montserrat-Bold.ttf";
import MontserratBoldItalic from "../../assets/fonts/Montserrat-BoldItalic.ttf";
import MontserratLight from "../../assets/fonts/Montserrat-Light.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    { src: MontserratRegular },
    { src: MontserratItalic, fontStyle: "italic" },
    { src: MontserratBold, fontWeight: "bold" },
    { src: MontserratLight, fontWeight: "light" },
    { src: MontserratBoldItalic, fontStyle: "italic", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#fff",
      padding: 40,
    },
    mainWrapper: {
      width: "100%",
      fontFamily: "Montserrat",
      flexDirection: "row",
      gap: "20px",
    },
    nameFont: {
      fontSize: 26,
      fontWeight: "bold",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      color: "#5585b5",
      marginBottom: 7,
    },
  
    positionFont: {
      fontSize: 15,
      fontWeight: "normal",
      textTransform: "capitalize",
      color: "#283d56",
      marginBottom: 10,
      fontFamily: "Montserrat",
    },
    normalFont: {
      fontSize: 8.5,
      fontWeight: "normal",
      color: "#000000b6",
      fontFamily: "Montserrat",
    },
    smallFont: {
      fontSize: 8.5,
      fontWeight: "normal",
      color: "#283d56",
      fontFamily: "Montserrat",
    },
    sectionFont: {
      fontSize: 8.5,
      fontWeight: "normal",
      color: "#5585b5",
      textTransform: "uppercase",
      borderBottom: "1px solid rgba(0, 0, 0, 0.057)",
      paddingTop: 8,
      marginBottom: 12,
      paddingBottom: 12,
      fontFamily: "Montserrat",
    },
  
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });


const summaryComp = (data)=>{
  return <View>
  {data?.summary && (<View className='mt-3 '>
      <Text style={styles.normalFont}>{data?.summary.summary}</Text>
  </View>)}
</View>
}

const workExperienceComp = (data)=>{
return <>
    {
        data?.workExperience?.workExperienceArray?.length > 0 && (
        <View style={{ marginTop: "16px" }}>
                    <Text style={styles.sectionFont}> Work Experience</Text>
                    {data?.workExperience?.workExperienceArray?.map((item, index) => {
                    return (
                        <View style={{ marginBottom: "8px" }} key={index}>
                        <View
                            style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            }}
                        >
                            <Text
                            style={[
                                styles.normalFont,
                                { fontWeight: "bold", color: "#14171ac1" },
                            ]}
                            >
                            {item.title !== undefined ? item.title : ""}
                            </Text>
                            <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                            >
                            <Text
                                style={[
                                styles.normalFont,
                                { fontWeight: "medium", color: "#14171ac1" },
                                ]}
                            >
                                {item?.startDate !== undefined ? item?.startDate : ""}
                            </Text>
                            <Text
                                style={[
                                styles.normalFont,
                                { fontWeight: "medium", color: "#14171ac1" },
                                ]}
                            >
                                -
                            </Text>
                            <Text
                                style={[
                                styles.normalFont,
                                { fontWeight: "medium", color: "#14171ac1" },
                                ]}
                            >
                                {item?.endDate !== undefined ? item?.endDate : ""}
                            </Text>
                            </View>
                        </View>
                        <Text style={[styles.normalFont, { marginBottom: "8px" }]}>
                            {item.company}, {item.location}
                        </Text>
                        {item?.bulletPoints &&
                            item?.bulletPoints?.map((bulletPoint, index) => (
                            <Text
                                style={[styles.normalFont, { marginVertical: "2px" }]}
                                key={index}
                            >{`${bulletPoint !== null ? bulletPoint : ""}`}</Text>
                            ))}
                        </View>
                    );
                    })}
            </View>
        )
    }
</>

}

const educationComp = (data)=>{
  return <>
    {
        data?.education?.educationArray?.length > 0 && (
            <>
                <Text
            style={[
              styles.sectionFont,
              { marginTop: "20px", 
                color: "#000" 
            },
            ]}
          >
            Education
          </Text>
            {data?.education?.educationArray?.map((item, index) => {
                return (
                <View key={index} style={{ marginBottom: "20px" }}>
                    {item.institution !== undefined && item.institution && (
                    <Text style={[styles.normalFont, { fontWeight: "medium" }]}>
                        {item.institution}
                    </Text>
                    )}
                    {item.degree !== undefined && item.degree && (
                    <Text style={styles.normalFont}>{item.degree}</Text>
                    )}
                    {item.fieldOfStudy !== undefined && item.fieldOfStudy && (
                    <Text style={styles.normalFont}>{item.fieldOfStudy}</Text>
                    )}
                    {item.location !== undefined && item.location && item.year && (
                    <Text style={styles.normalFont}>
                        {item.location}, {item.year}
                    </Text>
                    )}
                </View>
                );
            })}
            </>
        )
    }
  </>
}

const certificationComp = (data)=>{
    return <>
      {
              data?.certifications?.certificationsArray?.length > 0 && (
                  <>
                      <Text 
                      style={styles.sectionFont}
                      >
                          Certifications
                      </Text>
                      {
                          data?.certifications?.certificationsArray?.map((certification)=>(
                              <View key={certification._id} className='' style={{ marginBottom: "8px", flexDirection: "row",
                                justifyContent: "space-between" }}>
                                  <Text  style={[
                                        styles.normalFont,
                                        { fontWeight: "bold", color: "#14171ac1" },
                                    ]}>
                                      { certification?.certificationName && certification?.certificationName } 
                                      { certification.issuingOrganization && ' | ' + certification.issuingOrganization }  
                                      {/* { certification?.certificationUrl && ' | ' + certification?.certificationUrl } */}
                                      { certification?.year && ' | ' + certification?.year }
                                  </Text>
                              </View>
                          ))
                      }
                  </>
              )
          }
    </>
  }


const skillsComp = (data)=>{

 
    return <>
      {
              data?.skills?.skillsArray?.length > 0 && (
              <>
                <Text
                    style={[
                    styles.sectionFont,
                    { marginTop: "20px", 
                        color: "#000" 
                    },
                    ]}
                >
                    skills
                </Text>

                {data?.skills && data?.skills?.skillsArray?.map((item, index) => {
                    return (
                    <Text style={styles.normalFont} key={index}>
                        <Text style={{ fontSize: 16}}>•</Text> {item.skill !== undefined && item.skill}
                    </Text>
                    );
                })}
              </>)
          }
    </>
  }



const renderRight = (section, data) => {
    switch (section.name) {
        case 'EDUCATION':
            return educationComp(data);
        case 'SKILLS':
            return skillsComp(data);
        default:
            return null;
    }
};

const renderLeft = (section, data)=>{
    switch (section.name) {
        case 'SUMMARY':
            return summaryComp(data);
        case 'EXPERIENCE':
            return workExperienceComp(data);
        case 'CERTIFICATIONS':
            return certificationComp(data);
        default:
            return null;
    }
}


const ResumeDoc005 = ({ data, sections }) => (
  <Document>
    <Page size="A4" style={styles.page}>
    <View style={styles.mainWrapper}>
        <View
          style={{
            width: "33.3%",
            padding: "16px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Text style={[styles.sectionFont, { 
            //color: "#7E3AF2" 
            color: "#000" 
            }]}>
            contact
          </Text>

          {data.address !== undefined && data?.address && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                paddingVertical: "4px",
              }}
            >
              <View
                style={{
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                  backgroundColor: "#283d56",
                }}
              ></View>
              <Text style={styles.normalFont}>{data?.address}</Text>
            </View>
          )}
          {data.phone !== undefined && data?.phone && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                paddingVertical: "4px",
              }}
            >
              <View
                style={{
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                  backgroundColor: "#283d56",
                }}
              ></View>
              <Text style={styles.normalFont}>{data?.phone}</Text>
            </View>
          )}
          {data.email !== undefined && data?.email && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                paddingVertical: "4px",
              }}
            >
              <View
                style={{
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                  backgroundColor: "#283d56",
                }}
              ></View>
              <Text style={styles.normalFont}>
                {/* {data?.email} */}
                {insertBreakablePointsInEmail(data?.email)}
              </Text>
            </View>
          )}
          {data.linkedin !== undefined && data?.linkedin && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                paddingVertical: "4px",
              }}
            >
              <View
                style={{
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                  backgroundColor: "#283d56",
                }}
              ></View>
               <Link
                  src={data?.linkedin}
                  style={styles.normalFont}
                >
                  {insertBreakablePoints(data?.linkedin)}
                </Link>
            </View>
          )}
            {sections.map((section, index) => (
                <View key={`${section.name}-${index}`}>
                    {renderRight(section, data)}
                </View>
            ))}
        </View>
        <View style={{ width: "66.6%" }}>
          <View style={{ textAlign: "left" }}>
            <Text style={[styles.nameFont, { marginTop: "20px" }]}>
            {data?.lastName} {data?.firstName}
            </Text>
            <Text style={styles.positionFont}>{data?.jobTitle}</Text>
          </View>
          {sections.map((section, index) => (
            <View key={`${section.name}-${index}`}>
                {renderLeft(section, data)}
            </View>
            ))}
        </View>
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </Page>
  </Document>
)

export default ResumeDoc005