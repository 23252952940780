import React, { useState, createContext, useContext } from 'react'


const CoverLetterTemplateContext = createContext();

export const CoverLetterTemplateContextProvider = ({ children }) => {
    const [coverLetterTemplate, setCoverLetterTemplate] = useState(1)
    
    const handleSelectCoverLetterTemplate = (id)=>{
        setCoverLetterTemplate(id)
    }

  return (
    <CoverLetterTemplateContext.Provider value={{
        coverLetterTemplate,
        handleSelectCoverLetterTemplate,
        setCoverLetterTemplate
    }}>
        { children }
    </CoverLetterTemplateContext.Provider>
  )
}

export const useCoverLetterTemplateContext = ()=> useContext(CoverLetterTemplateContext)

