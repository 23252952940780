import React from 'react';
import { Link } from 'react-router-dom';

const HomeLink = ({ color }) => {
  return (
    <Link to={'/'} className={`py-3 flex items-center gap-2 text-${color} cursor-pointer`}>
        <b className='font-bold font-greatVibes'>RL </b>
        <h3 className='font-bold'> Resume Link</h3>
    </Link>
  )
}

export default HomeLink