import React from 'react'

const CompanyInformationform = ({ formData, handleChange  }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
    <div>
        <label htmlFor="companyName" className='capitalize text-xsm'>Name of company</label>
        <input 
          type="text"
          name='companyName'
          value={formData?.companyName}
          onChange={handleChange}
          placeholder='Name of company'
          className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
        />
    </div>
    <div>
        <label htmlFor="" className='capitalize text-xsm'>Company location (country)</label>
        <input 
          type="text"
          name='companyCountry'
          value={formData?.companyCountry}
          onChange={handleChange}
          placeholder='Country'
          className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
        />
    </div>
    <div>
        <label htmlFor="" className='capitalize text-xsm'>Company location (city)</label>
        <input 
          type="text"
          name='companyCity'
          value={formData?.companyCity}
          onChange={handleChange}
          placeholder='City'
          className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
        />
    </div>
    <div>
        <label htmlFor="" className='capitalize text-xsm'>Company Zip Code</label>
        <input 
          type="text"
          name='companyZipCode'
          value={formData?.companyZipCode}
          onChange={handleChange}
          placeholder='Company Zip Code'
          className='w-full border border-gray-400 text-xsm rounded-md px-4 mb-0 my-1 py-1 pr-10 focus:outline-none focus:border-slate-800 text-gray-600'
        />
    </div>
    </div>
  )
}

export default CompanyInformationform