import coreClient from "../../services/coreApi";

export const useEmailVerification = () => {
  const emailVerification = async (payload) => {
    try {
      const response = await coreClient.post('/auth/verify-email', { emailVerificationToken:payload });
      return response;
    } catch (error) {
      throw error;
    }
  };

  return { emailVerification };
}

