

const EmptyResume = ({ children, image }) => {
  return (
    <div className='h-screen w-full flex flex-col justify-center items-center bg-white rounded-lg shadow-lg'>
        <div className="flex flex-col justify-center items-center">
          <img src={image} alt="no resume found" className='h-[10rem] w-[10rem] ' />
          <div className='py-3'>{ children }</div>
        </div>
    </div>
  )
}

export default EmptyResume