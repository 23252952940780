
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Trash2, FilePenLine, Plus } from 'lucide-react';
import { toast } from 'sonner';
import { useDeleteAllResume } from '../../hooks/delete/useDeleteAllResume';
import { useGetAllResumes } from '../../hooks/get/useGetAllResumes';
import { useResumeItemContext } from '../../contexts/resumeItemContext';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { useTemplateContext } from '../../contexts/templateContext';
import { useResumeSectionsContext } from '../../contexts/resumeSectionsContext';
import emptyResumeItems2  from '../../assets/svg/empty-resume-items-2.svg'
import { EmptyResume } from '../../components/placeholders';
import { ResumeListSkeleton } from '../../components/loader';
import { CardResume01, CardResume02 } from '../../components/resume/card-resume';

const ResumeList = () => {

    const [resumes, setResumes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingDeleteResume, setLoadingDeleteResume] = useState(false);
    const [selectedResume, setSelectedResume] = useState(null);
    const getResumes = useGetAllResumes();
    const deleteAllResume = useDeleteAllResume();
    const { setSelectedResumeId } = useResumeItemContext();
    const { handleSelectTab } = useSideBarContext();
    const {  template } = useTemplateContext();
    const { sections } = useResumeSectionsContext();

    const handleSelectedTab = ()=>{
        handleSelectTab('create resume')
    }
    const handleEditSelectedTab = ()=>{
        handleSelectTab('edit resume')
    }

    const handleSelectedId = (id)=>{
        setSelectedResumeId(id)
    }
    const handleSelectedResume = (resumeId)=>{
        setSelectedResume(resumeId)
    }
 

    async function getAllResumeItems(){
        setLoading(true)
        setTimeout(async()=>{
            try {
                const { data, status } = await getResumes();
                if(status===200){
                    setResumes(data?.data)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log(error);
                // toast.error('an error occured, try again')
            }
        },2000)
    };
    async function getAllResumeItemsNoReLoad(){
        try {
            const { data, status } = await getResumes();
            if(status===200){
                setResumes(data?.data)
            }
        } catch (error) {
            toast.error('an error occured, try again')
        }
    };

    useEffect(()=>{
        getAllResumeItems();
    },[])

    

    const handleDeleteResume = async(resumeId)=>{
        setLoadingDeleteResume(true)
            setTimeout(async()=>{
                try {
                    const { status, data } = await deleteAllResume(resumeId);
                    if(status===200){
                        getAllResumeItemsNoReLoad();
                        toast.success(data?.message)
                    }
                    setLoadingDeleteResume(false)
                } catch (error) {
                    setLoadingDeleteResume(false)
                    console.log(error);
                    toast.error('an error occured, try again')
                }
        },1500)
    }


  return (
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }} // Initial animation state
      animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to apply
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} // Animation transition
      className=''
    >
     <div className='mb-[6rem]'>
        {
            loading ? <div className='grid grid-cols-2 gap-3'>
                {Array.from({ length: 6}).map((_, i) => (
                <ResumeListSkeleton key={i} />
              ))}
            </div> : (
                <>
                    {
                        resumes && resumes.length > 0 
                        ? (
                        <div className='h-full grid grid-cols-2 gap-4'>
                            {
                                resumes.map((resume, i)=>(
                                <div
                                    key={resume?._id} 
                                        className='w-full h-full p-3 my-3 bg-white shadow-md 
                                        rounded-md relative hover:bg-[rgba(0,0,0,0.05)]'
                                    >
                                    <div className=''>
                                        {/* <img src={kara} alt="" className='shadow-md' /> */}
                                        {
                                            template === 1 ? <CardResume01 data={resume} sections={sections} /> :
                                            template === 2 ? <CardResume02 data={resume} sections={sections} /> :
                                            template === 3 ? <CardResume01 data={resume} sections={sections} /> :
                                            template === 4 ? <CardResume01 data={resume} sections={sections} /> : 
                                            template === 5 ? <CardResume01 data={resume} sections={sections} /> :
                                            template === 6 ? <CardResume01 data={resume} sections={sections} /> : null
                                        }
                                    </div>
                                    <div className='w-full absolute  bottom-0 left-0 child flex items-center justify-between duration-200 md:p-3 gap-3 border-t bg-gray-50'>
                                        <h3 className='text-xxsm md:text-xsm capitalize'>{resume?.jobTitle ? (resume?.jobTitle.length > 16 ? resume?.jobTitle.slice(0, 15) + ' ...' : resume?.jobTitle) : `Resume-#${i+1}`}</h3>
                                        <div className='flex items-center gap-3'>
                                            <span onClick={
                                                ()=>{
                                                    handleSelectedId(resume._id)
                                                    handleEditSelectedTab()
                                                }
                                                } className='p-2 rounded-full bg-white cursor-pointer shadow-lg tooltip' data-tip="edit">
                                                <FilePenLine size={15} color='gray'/>
                                            </span>
                                            <span
                                            onClick={()=>{
                                                handleDeleteResume(resume._id)
                                                handleSelectedResume(resume._id)
                                            }}
                                                className='p-2 rounded-full bg-red-100 shadow-xl cursor-pointer tooltip'
                                                data-tip="delete"
                                            >
                                                {
                                                    loadingDeleteResume && selectedResume === resume._id 
                                                    ? <span className="loading loading-spinner loading-xs"></span> 
                                                    :<Trash2 size={15} color='red' />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                        ) 
                        : (
                        <>
                            <EmptyResume 
                                image={emptyResumeItems2}
                            >
                                <h3 className='mb-3'>Your resume folder is empty</h3>
                                <button className='w-full flex items-center justify-center gap-2 text-xsm bg-[#e3e3e3] px-3 py-2 
                                rounded-full cursor-pointer'
                                    onClick={handleSelectedTab}
                                >
                                    <span><Plus size={15}/></span> create resume
                                </button>
                            </EmptyResume>
                        </>)
                    }
                </>
            )
        }
    </div>
    </motion.div>
  );
};

export default ResumeList;
