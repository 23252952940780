import coreClient from "../../services/coreApi";

export const usevalidatePayment = () => {
  return async()=>{
    try {
        const res = await coreClient.post('/validate-payment');
        return res;
    } catch (error) {
        throw error
    }
  }
}

