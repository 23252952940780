import React from 'react';


const PersonalInformationForm = ({ formData, handleChange }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-1'>
        <div>
            <label htmlFor="" className='capitalize'>last name</label>
            <input 
              type="text"
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              placeholder='Your Last Name'
              className='w-full border border-gray-400 rounded-md px-4 mb-2 my-1 py-1 pr-2 focus:outline-none focus:border-slate-800 text-gray-600'
            />
        </div>
        <div>
            <label htmlFor="" className='capitalize'>first name</label>
            <input 
              type="text"
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              placeholder='Your First Name'
              className='w-full border border-gray-400 rounded-md px-4 mb-2 my-1 py-1 pr-2 focus:outline-none focus:border-slate-800 text-gray-600'
            />
        </div>
        <div>
            <label htmlFor="" className='capitalize'>email</label>
            <input 
              type="text"
              name='email'
              value={formData?.email}
              onChange={handleChange}
              placeholder='Your Email'
              className='w-full border border-gray-400 rounded-md px-4 mb-2 my-1 py-1 pr-2 focus:outline-none focus:border-slate-800 text-gray-600'
            />
          </div>
        <div>
            <label htmlFor="" className='capitalize'>phone number</label>
            <input 
              type="text"
              name='phone'
              value={formData?.phone}
              onChange={handleChange}
              placeholder='Your Phone Number'
              className='w-full border border-gray-400 rounded-md px-4 mb-2 my-1 py-1 pr-2 focus:outline-none focus:border-slate-800 text-gray-600'
            />
        </div>
        <div>
            <label htmlFor="" className='capitalize'>linkedin url</label>
            <input 
              type="text"
              name='linkedin'
              value={formData?.linkedin}
              onChange={handleChange}
              placeholder='https://www.linkedin.com/in/johndoe'
              pattern='https://.*'
              className='w-full border border-gray-400 rounded-md px-4 mb-2 my-1 py-1 pr-2 focus:outline-none focus:border-slate-800 text-gray-600'
            />
        </div>
        <div>
            <label htmlFor="" className='capitalize'>address</label>
            <input 
              type="text"
              name='address'
              value={formData?.address}
              onChange={handleChange}
              placeholder='City, state'
              className='w-full border border-gray-400 rounded-md px-4 mb-2 my-1 py-1 pr-2 focus:outline-none focus:border-slate-800 text-gray-600'
            />
          </div>
        <div>
            <label htmlFor="" className='capitalize'>Professional Title</label>
            <input 
              type="text"
              name='jobTitle'
              value={formData?.jobTitle}
              onChange={handleChange}
              placeholder='Professional Job Job'
              className='w-full border border-gray-400 rounded-md px-4 mb-2 my-1 py-1 pr-2 focus:outline-none focus:border-slate-800 text-gray-600'
            />
        </div>
    </div>
  )
}

export default PersonalInformationForm