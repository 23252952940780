import React, { useState, useEffect } from 'react';
import { Formik, Form } from "formik";
import { toast } from 'sonner';
import { MoveLeft } from 'lucide-react'
import { TextInputlg } from '../../components/forms/inputs';
import { useCreateCoverLetter } from '../../hooks/post/useCreateCoverLetter';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { useCoverLetterDataContext } from '../../contexts/coverLetterDataContext';
import { useCoverLetterItemContext } from '../../contexts/coverLetterItemContext';
import { CoverLetterFormSchema } from '../../utils/yupSchemas';
import { CircularProgress } from '../../components/loader';
import { PayWall } from '../../components/pay-wall';

const CreateCoverLetter = () => {
    const [progressValue, setProgressValue] = useState(0);
    const [showPayWallModal, setShowPayWallModal] = useState(false);
    const { createCoverLetter, setLoading, loading } = useCreateCoverLetter();
    const { setSideBarTab } = useSideBarContext();
    const { setCoverLetter } = useCoverLetterDataContext();
    const { setSelectedCoverLetterId } = useCoverLetterItemContext();

    const handleTogglePayWallModal = ()=>{
        setShowPayWallModal(!showPayWallModal);
      }

    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setProgressValue((prevValue) => {
                    if (prevValue < 100) {
                        return prevValue + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 100); 
            return () => clearInterval(interval);
        }
    }, [loading]);

    if(loading){
        return <CircularProgress value={progressValue}/>
      }
  return (
    <div className='w-11/12 m-auto'>
        <div className='flex justify-start items-start my-5 md:my-10'>
          {/* <button 
          onClick={()=>setSideBarTab('create document')}
          className='capitalize flex items-center gap-2 px-5 font-primary text-xsm 
          rounded-full shadow-md  hover:bg-light duration-300 tooltip' data-tip="go back">
          <span><MoveLeft /></span></button> */}
           <button 
          onClick={()=>setSideBarTab('create document')}
          className='flex items-center justify-center h-12 w-12 text-xsm bg-dark rounded-full cursor-pointer shadow-lg hover:-translate-y-1 duration-500'>
            <span><MoveLeft size={20} color='white'/></span> 
          </button>
        </div>
        <div className='bg-white p-5 rounded-md border shadow-md'>
            <div className='mb-10 bg-light p-5'>
                <h3 className='font-primary font-normal'>Create Cover letter</h3>
            </div>
            <Formik
                    initialValues={{ firstName:"", lastName:"", email: "",  jobTitle:"", companyName:"", yearsOfExperience:'' }}
                    validationSchema={CoverLetterFormSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setLoading(true)
                            const { status, data } = await createCoverLetter({
                            form:{
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email,
                                jobTitle: values.jobTitle,
                                companyName: values.companyName,
                                yearsOfExperience: values.yearsOfExperience
                            }
                            });
                            if(status===200 && data?.data !==null){
                              setCoverLetter(data?.data);
                              setSideBarTab('edit cover letter')
                              setSelectedCoverLetterId(data?.data?._id);
                              setProgressValue(0);
                              toast.success('cover letter succesfully created!')
                            }
                            setSubmitting(false);
                            setLoading(false);
                            setProgressValue(0);
                            //toast.success('Sorry an error occured, please try again!');
                        } catch (error) {
                            setLoading(false);
                            setProgressValue(0);
                            if (error.response && error.response.data) {
                                if(error.response.data.message==='Permission denied. Maximum number of coverletters created for your account.'){
                                    toast.error(`${error.response.data.message}`);
                                    handleTogglePayWallModal();
                                  }
                            } else if (error.request) {
                                toast.error('No response from server. Please try again later.');
                            } else {
                                toast.error(`Error: ${error.message}`);
                            }
                        }
                    }}
                    >
                    {({ isSubmitting }) => (
                        <Form>
                        <div>
                            <TextInputlg
                                label='Your Professional Job Title'
                                name='jobTitle'
                                autoComplete='jobTitle'
                                type='text'
                                placeholder='e.g business administrator'
                            />
                            <TextInputlg
                                label="Hiring company or manager's name"
                                name='companyName'
                                autoComplete='companyName'
                                type='text'
                                placeholder='e.g microsoft'
                            />
                            <TextInputlg
                                label='Your First Name'
                                name='firstName'
                                autoComplete='firstName'
                                type='text'
                                placeholder='Your First Name'
                            />
                            <TextInputlg
                                label='Your Last Name'
                                name='lastName'
                                autoComplete='lastName'
                                type='text'
                                placeholder='Your Last Name'
                            />
                            <TextInputlg
                                label='Your email'
                                name='email'
                                autoComplete='email'
                                type='email'
                                placeholder='Your email'
                            />
                            <TextInputlg
                            label='Years of experience'
                            name='yearsOfExperience'
                            autoComplete='yearsOfExperience'
                            type='number'
                            placeholder='Years of experience'
                            />
                        </div>
                        <div className='my-4'>
                            <button
                            type='submit' disabled={isSubmitting}
                            className='w-full bg-dark py-3 text-white rounded-md'>
                            {isSubmitting ? "Creating Cover Letter" : "Create Cover Letter"}
                            </button>
                        </div>
                        </Form>
                    )}
            </Formik>
        </div>
        <PayWall  
            handleTogglePayWallModal={handleTogglePayWallModal} 
            showPayWallModal={showPayWallModal}
            payWallText={'Support our platform to create more documents!'}
        />
    </div>
  )
}

export default CreateCoverLetter