import { FaTimesCircle } from 'react-icons/fa';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';
import { useResumeSectionsContext } from '../../contexts/resumeSectionsContext';
import { useResumeDataContext } from '../../contexts/resumeDataContext';
import { useTemplateContext } from '../../contexts/templateContext';
import { PreviewResume01, PreviewResume02, PreviewResume03, PreviewResume04, PreviewResume05, PreviewResume06, PreviewResume07, PreviewResume08} from '../resume/preview-resume';


const ResumePreviewModal = ({ children, modalOpen, modalClose }) => {
    const { resume } = useResumeDataContext();
    const {  template } = useTemplateContext();
    const { sections } = useResumeSectionsContext();
   
  return (
    <>
        {
            modalOpen && (
                <div className='w-full bg-white h-screen absolute top-0 left-0 overflow-y-auto z-25'>
                    <motion.div 
                    initial={{ 
                        x: -100, 
                        opacity: 0, 
                    }} 
                    animate={{ x: 0, opacity: 1, scale: 1 }} 
                    transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
                    className='h-full bg-white  rounded-md relative'>
                        <span onClick={modalClose} className="cursor-pointer fixed right-3 top-5 text-slate-500 bg-white p-3 rounded-full shadow-lg">
                            <X />
                        </span>
                        <div className='p-5'>
                            {
                                template === 1 ? <PreviewResume01 data={resume} sections={sections} /> :
                                template === 2 ? <PreviewResume02 data={resume} sections={sections} /> :
                                template === 3 ? <PreviewResume03 data={resume} sections={sections} /> :
                                template === 4 ? <PreviewResume04 data={resume} sections={sections} /> : 
                                template === 5 ? <PreviewResume07 data={resume} sections={sections} /> :
                                template === 6 ? <PreviewResume08 data={resume} sections={sections} /> : 
                                template === 7 ? <PreviewResume05 data={resume} sections={sections} /> : 
                                template === 8 ? <PreviewResume06 data={resume} sections={sections} /> : null
                            }
                        </div>
                    </motion.div>
                </div>
            )
        }
    </>
  )
}

export default ResumePreviewModal