
import { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';
import { useListPlans } from '../../hooks/get/useListPlans';
import { ListSubcriptionsCard } from '../cards';
import tokenService from '../../services/token.service';

const PayWall = ({ payWallText, handleTogglePayWallModal, showPayWallModal }) => {
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const listPlans = useListPlans();
    const user  = tokenService.getUser();


    useEffect(()=>{
        (async function (){
            try {
                setLoadingSubscription(true);
                const { status, data } = await listPlans();
                if(status===200){
                    setSubscriptions(data)
                }
            } catch (error) {
                return error
            }finally{
                setLoadingSubscription(false);
            }
        })()
    },[])

  return (
    <>
        {
        showPayWallModal && (
            <div className='w-full h-screen flex flex-col justify-center items-center fixed top-0 left-0 overflow-y-auto bg-white/70 z-50'>
                <motion.div 
                initial={{ 
                    x: -100, 
                    opacity: 0, 
                    // scale: 0.8 
                }} 
                animate={{ x: 0, opacity: 1, scale: 1 }} 
                transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
                className='h-full md:h-3/4 md:w-3/4 flex flex-col justify-center items-center bg-white shadow-md rounded-md relative border'>
                    <span onClick={handleTogglePayWallModal} className="cursor-pointer absolute right-3 top-5 text-slate-500 bg-white p-3 rounded-full shadow-lg"><X /></span>
                    <div className='h-full w-full flex  justify-center items-center gap-10 p-5 bg-white'>
                        <div className='w-1/2 border-2 border-dark p-10 bg-light hidden md:block'>
                            <h1 className='font-bold font-greatVibes text-xl md:text-lg 
                                    leading-[3rem] md:leading-[2.5rem] pr-5'>
                                        Hello <span className='text-[1.3rem] font-primary border-b-2 border-dark p-1'>{user?.email}</span>
                            </h1>
                            <p className='text-sm my-10'>
                            Thank you for considering our subscription service.
                            Your upgrade makes a big difference. <br /><br />
                            Running a <b>high-quality</b> resume generation platform involves <b>significant costs</b>, 
                            from <b>hosting</b> to continuous feature enhancements. 
                            Your subscription plays a vital role in sustaining our service.
                            </p>
                            <h3 className='text-sm mb-5'><b>- Why Your Support Matters:</b></h3>
                            <ul>
                                <li><b>Maintenance and Development:</b> Your contribution helps us maintain and improve the platform.</li>
                                <li><b>Unlimited Features:</b> Create, edit, and download resumes without any limitations.</li>
                                <li><b>Exclusive Templates:</b> Gain access to a variety of professionally designed resume templates.</li>
                                {/* <li><b>AI Enhancements:</b> Utilize AI-driven content suggestions tailored to your career goals.</li> */}
                                <li><b>Priority Support:</b> Receive fast, dedicated assistance whenever you need it.</li>
                            </ul>
                        </div>
                        <div className='h-full w-full md:w-1/2'>
                            <div className='my-5'>
                                <h1 className='font-bold font-primary text-sm md:text-sm 
                                    leading-[1rem] md:leading-[2.5rem] pr-5 text-red-400'> <span className='text-red-500'>*</span> { payWallText }
                                </h1>
                                <h1 className='font-bold font-primary text-sm md:text-[2.3rem] mt-2 md:mt-0 
                                    leading-[1.2rem] md:leading-[2.5rem] pr-5'>Support Our Platform and Elevate Your Career
                                </h1>
                            </div>
                            <div className='h-full flex flex-col md:flex-row gap-4'>
                                <ListSubcriptionsCard subscriptions={subscriptions}/>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        )
    }
    </>
  )
}

export default PayWall