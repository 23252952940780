import React from 'react';
import { Edit, Pencil } from 'lucide-react';

const HoverToEdit = () => {
  return (
    <div className="absolute inset-0 border-2 border-yellow/60 flex items-center justify-center opacity-0 group-hover:opacity-100 
    transition-opacity duration-300 rounded-md">
        <button className="flex items-center gap-3 text-white text-sm font-normal bg-dark/50 px-10 py-2 shadow-lg"> 
            <span><Pencil size={15}/></span> Click to Edit
        </button>
    </div>
  )
}

export default HoverToEdit