import coreClient from "../../services/coreApi";
export const useDeleteCoverLetter = () => {
  return async(coverLetterId)=>{
    try {
        const res = await coreClient.delete(`/cover-letter/${coverLetterId}/delete`,);
        return res;
    } catch (error) {
        throw error
    }
  }
}

