import React from 'react';
import pdfIcon from '../../assets/png/pdf-icon.png';
import downloadPdfScreenshot from '../../assets/png/screenshot-download-resume.png'

const Feature01 = () => {
  return (
    <div className='h-full flex items-center border border-slate-500 rounded-md font-primary'>
      <div className='w-1/2 flex flex-col justify-center gap-5 items-center'>
        <img src={pdfIcon} alt="" className='h-24 w-24 animate-bounce'/>
        <h3 className='text-lg font-bold'>Instant PDF Downloads</h3>
        <p className='text-center px-10'>Download your completed resume instantly in PDF format, ready to send to employers. 
          Ensure your resume maintains its formatting and looks professional on any device.</p>
      </div>
      <div className='h-full w-1/2 flex justify-center items-center bg-light rounded-md'>
        <img src={downloadPdfScreenshot} alt="" className='rounded-md h-3/4 w-3/4 object-contain'  />
      </div>
    </div>
  )
}

export default Feature01