import React from 'react'
import { WifiOff } from 'lucide-react';

const NetworkChecker = () => {
  return (
    <div className="hero bg-base-200 min-h-screen font-primary">
        <div className=" hero-content text-center">
            <div className="max-w-md">
            <h1 className="text-lg font-bold">oops!</h1>
            <p className="py-6 text-sm">
                Looks like your device is not connected, <br /> <b>please check your internet and try again</b> 
            </p>
            <div className='flex justify-center items-center mt-5'>
                <WifiOff size={60}/>
            </div>
            </div>
        </div>
    </div>
  )
}

export default NetworkChecker