import React from 'react'

const CardCoverLetter = ({ coverLetter }) => {
    const letterContents = coverLetter?.coverLetterContent ? coverLetter?.coverLetterContent.split('.').filter(Boolean).map(content => content.trim()) : [];

  return (
    <div className='text-xxxsm flex items-center my-10'>
        {letterContents && letterContents[0]}
    </div>
  )
}

export default CardCoverLetter