
import React, { useState, useEffect } from 'react';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { Ban, ChevronRight } from 'lucide-react';
import { useGoogleVerifyCode } from '../../hooks/auth/useGoogleVerifyCode';
import tokenService from '../../services/token.service';

const GoogleVerifyCode = () => {
    const [isCodeVerified, setIsCodeVerified] = useState(null);
    const [loading, setLoading] = useState(false);
    const [verificationCompleted, setVerificationCompleted] = useState(false);
    const googleVerifyCode = useGoogleVerifyCode();
    const code = new URL(window.location).searchParams.get('code');
    const navigateTo = useNavigate();
    

    function handleRedirect() {
        navigateTo('/sign-in');
    }

    useEffect(() => {
        async function handleCallback() {
            try {
                setLoading(true);
                const { status, data } = await googleVerifyCode(code);
                if (status === 200) {
                    tokenService.setUser(data);
                    navigateTo('/resume-dashboard');
                    toast.success('Successfully logged in');
                    setIsCodeVerified(true);
                } else {
                    setIsCodeVerified(false);
                }
            } catch (error) {
                setIsCodeVerified(false);
                if (error.response && error.response.data) {
                    toast.error(`${error.response.data.message}`);
                } else if (error.request) {
                    toast.error('No response from server. Please try again later.');
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            } finally {
                setLoading(false);
                setVerificationCompleted(true);
            }
        }

        if (code) {
            handleCallback();
        } else {
            setIsCodeVerified(false);
            setVerificationCompleted(true);
        }
    }, [code]);

    return (
        <main className='h-screen flex justify-center items-center font-primary'>
            {loading ? (
                <h2>Verifying account...</h2>
            ) : (
                verificationCompleted && isCodeVerified === false && (
                    <div className='w-6/12'>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='p-1 border rounded-full'>
                                <div className='p-1 shadow-lg bg-light rounded-full'>
                                    <div className='h-24 w-24 border flex items-center justify-center p-3 rounded-full bg-dark/60'>
                                        <Ban color='red' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center my-6'>
                            <h3>Sorry, verification was not successful!</h3>
                        </div>
                        <button 
                            onClick={handleRedirect}
                            className='m-auto flex items-center justify-center gap-2 py-2 px-3 rounded-md hover:text-light duration-300 ease-in bg-white'>
                            Continue to web
                            <span><ChevronRight /></span>
                        </button>
                    </div>
                )
            )}
        </main>
    );
};

export default GoogleVerifyCode;
