
import React, { useState, useEffect } from 'react';
import { PDFDownloadLink, PDFViewer, StyleSheet, pdf, BlobProvider } from '@react-pdf/renderer';
import { Eye, Pencil, Share2, StickyNote, X, Copy, Cloud } from 'lucide-react';
import { MdCloudDone } from "react-icons/md";
import { motion } from 'framer-motion';
import { toast } from 'sonner';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import QRCode from 'qrcode';
import { useResumeItemContext } from '../../contexts/resumeItemContext';
import { useResumeDataContext } from '../../contexts/resumeDataContext';
import { useTemplateContext } from '../../contexts/templateContext';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { useGetResume } from '../../hooks/get/useGetResume';
import { useBillingContext } from '../../contexts/billingContext';
import { useResumeSectionsContext } from '../../contexts/resumeSectionsContext';
import { useResumeFormSectionContext } from '../../contexts/resumeFormSectionContext';
import { 
  Resume01, Resume02, 
  Resume03, Resume03B, 
  Resume004, Resume004B,
  Resume005, Resume005B,
  } from '../../components/resume';
import EmptyResume from '../../components/placeholders/EmptyResume';
import emptyFolderImg from '../../assets/png/empty-folder.png'
import { ResumeItemSkeleton } from '../../components/loader';
import { ResumeDocumentPDF } from '../../components/download-document';
import { PayWall } from '../../components/pay-wall'
import '../../utils/styles/styles.css'
import { PayButton } from '../../components/pay-button';


const EditResumeTemplate = () => {
    const { selectedResumeId } = useResumeItemContext();
    const { resume, setResume, loadingUpdate } = useResumeDataContext();
    const { fetchPaymentDetails, paymentDetails } = useBillingContext();
    const { handleSelectTab } = useSideBarContext();
    const { handleSelectedSection } = useResumeFormSectionContext();
    const { sections } = useResumeSectionsContext();
    const {  template } = useTemplateContext();
    const getResume = useGetResume()
    const [loading, setLoading] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showPayWallModal, setShowPayWallModal] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [src, setSrc] = useState('');
    const [blob, setBlob] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [error, setError] = useState(null);

    const handleTogglePreviewModal = ()=>{
      setShowPreviewModal(!showPreviewModal);
    }

    const handleToggleShare = ()=>{
      setShowShareModal(!showShareModal)
    }

    const handleTogglePayWallModal = ()=>{
      setShowPayWallModal(!showPayWallModal);
    }

   

    const handleSelectedTab = ()=>{
      handleSelectTab('edit resume')
  }
    const handleSelectResumeListTab = ()=>{
      handleSelectTab('my resumes')
  }

  const handleEditSection = (sectionName)=>{
    handleSelectTab('edit resume')
    handleSelectedSection(sectionName)
  }
  



  


  const renderResumeItems = () => {
    switch (template) {
        case 1:
            return <Resume01 data={resume} sections={sections} handleEditSection={handleEditSection} />;
        case 2:
            return <Resume02 data={resume} sections={sections} handleEditSection={handleEditSection} />;
        case 3:
            return <Resume03 data={resume} sections={sections} handleEditSection={handleEditSection} />;
        case 4:
            return <Resume03B data={resume} sections={sections} handleEditSection={handleEditSection} />;
        case 5:
            return <Resume005 data={resume} sections={sections} handleEditSection={handleEditSection} />;
        case 6:
            return <Resume005B data={resume} sections={sections} handleEditSection={handleEditSection} />;
        case 7:
            return <Resume004 data={resume} sections={sections} handleEditSection={handleEditSection} />;
        case 8:
            return <Resume004B data={resume} sections={sections} handleEditSection={handleEditSection} />;
        // case 9:
        //     return <Resume06 data={resume} sections={sections} handleEditSection={handleEditSection} />;
        // case 10:
        //     return <Resume06 data={resume} sections={sections} handleEditSection={handleEditSection} />;
        default:
            return null;
    }
};

  
    const title = "GitHub";

    const copyToClipboard = () => {
      const input = document.getElementById('linkInput');
      input.select();
      document.execCommand('copy');
      toast.success('link copied')
    };

    const generateQrcode = ()=>{
      if(pdfUrl){
        QRCode.toDataURL(pdfUrl).then(setSrc)
      }
    }

    

  async function getResumeData(){
    if(selectedResumeId){
      setLoading(true);
      setTimeout(async()=>{
        try {
            const { status, data } = await getResume(selectedResumeId);
            if(status===200){
              setResume(data?.data);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false);
        }
      },1000)
    }
  }

  useEffect(()=>{
    getResumeData();
},[selectedResumeId]);

useEffect(()=>{
  fetchPaymentDetails();
},[])


  useEffect(()=>{
    getResumeData();
},[selectedResumeId]);




  return (
    <div className='h-auto bg-[#F4F4F6] shadow-lg p-1 rounded-md'>
      {
        loading ? (<div className='grid grid-cols-1 gap-3 p-10'>
          {Array.from({ length: 4}).map((_, i) => (
          <ResumeItemSkeleton key={i} />
        ))}
      </div> )
        : (
        <>
          {
            selectedResumeId && (
              <ul className='flex justify-between items-center py-5 px-3 border bg-white font-primary relative'>
                  <li className='flex items-center gap-3'
                  >
                    <button className='flex items-center gap-2 text-xsm bg-[#e3e3e3] px-3 py-2 rounded-sm cursor-pointer'
                      onClick={handleSelectedTab}
                    >
                      <span><Pencil size={15}/></span> edit
                    </button>
                     {/* <BlobProvider
                      document={<ResumeDocumentPDF template={template} data={resume} sections={sections} />}
                      fileName="somename.pdf"
                    >
                      {({ blob: newBlob, url: newUrl, loading, error: newError }) => {
                        if (!loading) {
                          if (newBlob && newBlob !== blob) setBlob(newBlob);
                          if (newUrl && newUrl !== pdfUrl) setPdfUrl(newUrl);
                          if (newError && newError !== error) setError(newError);
                        }
                        return  <button className='flex items-center gap-2 text-xsm font-bold bg-[#e3e3e3]/30 duration-500 hover:-translate-y-1 shadow-lg px-3 py-2 rounded-full cursor-pointer'
                        onClick={()=>{
                          handleToggleShare();
                          generateQrcode();
                        }}
                      >
                        <span><Share2 size={15}/></span> Share
                      </button>;
                      }}
                    </BlobProvider> */}
                    <button className='flex items-center gap-2 text-xsm bg-[#e3e3e3]/30 px-3 py-2 rounded-full cursor-pointer'>
                      {loadingUpdate ? <span className="loading loading-spinner loading-xs"></span> : <span><MdCloudDone className='h-6 w-6 text-gray-700' /></span>} { loadingUpdate ? '... saving' : 'saved'}
                    </button>
                  </li>
                  
                  <li className='flex items-center gap-3'>
                    <button 
                    className='flex items-center gap-2 text-xsm bg-[#e3e3e3] px-3 py-2 
                    rounded-sm cursor-pointer border border-dark animate-pulse font-normal'
                      onClick={handleTogglePreviewModal}
                    >
                      <span className=''><Eye size={15}/></span> Preview
                    </button>
                      <PayButton template={template} resume={resume} sections={sections} handleTogglePayWallModal={handleTogglePayWallModal}/>
                  </li>
                  {
                    showShareModal && (
                      <>
                        <div className='w-1/2 flex flex-col items-center justify-center absolute top-16 left-5
                         bg-white shadow-lg z-20 rounded-lg border border-slate-300'>
                        <div className='w-full p-5'>
                            <div className='w-full flex justify-between items-center border-b pb-3'>
                              <h3 className='text-xsm capitalize'>share your resume link</h3>
                              <button 
                                onClick={handleToggleShare}
                                className='flex items-center justify-center h-12 w-12 text-xsm bg-white rounded-full cursor-pointer shadow-lg hover:-translate-y-1 duration-500'>
                                <span><X size={20} /></span> 
                              </button>
                            </div>
                            <h3 className='text-xsm py-4'>
                              share your link with potential employers, we also show you the number of times your resume has been viewed
                            </h3>
                            <div className='grid grid-cols-4 border-b pb-3'>
                              <EmailShareButton
                                url={pdfUrl}
                                subject={title}
                                body="body"
                                className="Demo__some-network__share-button"
                              >
                                <EmailIcon size={40} round />
                              </EmailShareButton>
                              <LinkedinShareButton
                                url={pdfUrl}
                                className="Demo__some-network__share-button"
                              >
                                <LinkedinIcon size={40} round />
                              </LinkedinShareButton>
                                {/* <FacebookShareButton
                                  url={shareUrl}
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookIcon size={40} round />
                                </FacebookShareButton> */}
                                <TwitterShareButton
                                  url={pdfUrl}
                                  title={title}
                                  className="Demo__some-network__share-button"
                                >
                                  <XIcon size={40} round />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                  url={pdfUrl}
                                  title={title}
                                  separator=":: "
                                  className="Demo__some-network__share-button"
                                >
                                  <WhatsappIcon size={40} round />
                                </WhatsappShareButton>
                            </div>
                            <div className='flex justify-between gap-1 items-center mt-4'>
                              <input 
                                id="linkInput" 
                                value={pdfUrl} 
                                readOnly 
                                className='w-full border rounded-md py-1 pl-3'
                              />
                              <button onClick={copyToClipboard} >
                                <Copy size={25}/>
                              </button>
                          </div>
                          <div className='w-full h-32'>
                            <img src={src} alt="" className='w-full h-full object-contain' />
                          </div>
                        </div>
                        </div>
                      </>
                    )
                  }
              </ul>
            )
          }
          {
            resume && resume ? (
              <>
                  <div className='shadow-lg  px-5 py-5 mb-10 rounded-md bg-white relative'>
                      {renderResumeItems()}
                  </div>
              </>
            ):(
              <EmptyResume 
                  image={emptyFolderImg}
              >
                  <h3 className='mb-3'>You have to select a resume to edit</h3>
                  <button className='w-full flex items-center justify-center gap-2 text-xsm bg-[#e3e3e3] px-3 py-2 
                  rounded-full cursor-pointer'
                      onClick={handleSelectResumeListTab}
                  >
                      <span><StickyNote size={15}/></span> my resume list
                  </button>
              </EmptyResume>
            )
          }
        </>
        )
      }
      {
        showPreviewModal && (
          <div className='w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-light/90'>
            <motion.div 
            initial={{ 
              x: -100, 
              opacity: 0, 
          }} 
          animate={{ x: 0, opacity: 1, scale: 1 }} 
          transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
            className='flex justify-center items-center w-full h-24 bg-white'>
              <div className='w-5/12 h-3/4 flex items-center justify-between bg-white'>
                <button 
                onClick={handleTogglePreviewModal}
                className='flex items-center justify-center h-12 w-12 text-xsm bg-dark rounded-full cursor-pointer shadow-lg hover:-translate-y-1 duration-500'>
                  <span><X size={20} color='white'/></span> 
                </button>
                <div>
                  <PayButton template={template} resume={resume} sections={sections} handleTogglePayWallModal={handleTogglePayWallModal}/>
                </div>
              </div>
            </motion.div>
            <div className='w-5/12 h-10/12 bg-white relative'>
              {
                loading ? (<div className='grid grid-cols-1 gap-3 p-10'>
                  {Array.from({ length: 4}).map((_, i) => (
                  <ResumeItemSkeleton key={i} />
                ))}
                </div> ):(
                  <PDFViewer width="100%" height="50%" className="pdfViewer" showToolbar={false}>
                            <ResumeDocumentPDF template={template} data={resume} sections={sections} />
                  </PDFViewer>
                )
              }
              {
                paymentDetails && paymentDetails?.paymentStatus !== 'paid' &&(
                  <div className='w-full h-48 absolute left-0 bottom-0 bg-light blur-l'>
                    <div className='p-10'>
                      <h1 className='font-bold font-primary text-xl md:text-[2.3rem] 
                          leading-[3rem] md:leading-[2.5rem] pr-5'>Support Our Platform and Elevate Your Career
                      </h1>
                      <button 
                        onClick={handleTogglePayWallModal}
                      className='rounded-full py-2 px-10 mt-5 bg-white shadow-lg font-bold hover:bg-black hover:text-white duration-300'>
                        Buy a Plan
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
      {
          <PayWall  
          handleTogglePayWallModal={handleTogglePayWallModal} 
          showPayWallModal={showPayWallModal} 
          payWallText={'Maximum number of downloads exeeded for free plan!'}
          />
      }
    </div>
  )
}

export default EditResumeTemplate