import coreClient from "../../services/coreApi";

export const useGetCoverLetter = () => {
  return async(coverLetterId)=>{
    try {
        const res = await coreClient.get(`/cover-letter/${coverLetterId}`);
        return res;
    } catch (error) {
        throw error
    }
  }
}

