import React,  { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'sonner';
import { Check, Linkedin, StickyNote, AudioLines, ChevronRight, MoveLeft, X } from 'lucide-react';
import { FaLinkedin } from "react-icons/fa";
import { useCreateResume } from '../../hooks/post/useCreateResume';
import { useCreateResumeViaLinkedin } from '../../hooks/post/useCreateResumeViaLinkedin';
import { useResumeDataContext } from '../../contexts/resumeDataContext';
import { useResumeItemContext } from '../../contexts/resumeItemContext';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { CircularProgress } from '../../components/loader';
import { Scratch, CreateResumeViaLinkedinForm } from '../../components/forms/create-resume-forms';
import { PayWall } from '../../components/pay-wall';

const CreateResume = () => {
    const [selectedCraftType, setSelectedCraftType] = useState('DEFAULT');
    const [loading, setLoading] = useState(false);
    const [showPayWallModal, setShowPayWallModal] = useState(false);
    // const [error, setError] = useState('');
    const [progressValue, setProgressValue] = useState(0);
    const createResume = useCreateResume();
    const { CreateResumeViaLinkedin, loading:loadingLinkedin, success } = useCreateResumeViaLinkedin();
    const { setResume} = useResumeDataContext();
    const { setSideBarTab } = useSideBarContext();
    const { setSelectedResumeId } = useResumeItemContext();
     
      const handleSelectedCraftType = (type)=>{
        setSelectedCraftType(type)
      }

      const handleTogglePayWallModal = ()=>{
        setShowPayWallModal(!showPayWallModal);
      }

      const craftingTypes = [
        {
          id:1,
          name: 'DEFAULT',
          icon: <StickyNote size={15} />
        },
        {
          id:2,
          name: 'LINKEDIN',
          icon: <FaLinkedin  />
        },
        // {
        //   id:3,
        //   name: 'VOICE',
        //   icon: <AudioLines size={15} />
        // },
      ]

      useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setProgressValue((prevValue) => {
                    if (prevValue < 100) {
                        return prevValue + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 100); 
            return () => clearInterval(interval);
        }
    }, [loading]);
    
      if(loading){
        return <CircularProgress value={progressValue}/>
      }
      
  return (
    <>
    <motion.div
      initial={{ x: -100, opacity: 0, scale: 0.8 }} 
      animate={{ x: 0, opacity: 1, scale: 1 }} 
      transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
      className='h-full  bg-white'
    >
      <div className='w-11/12 md:w-10/12 m-auto flex justify-start items-start'>
          <button 
          onClick={()=>setSideBarTab('create document')}
          className='flex items-center justify-center h-12 w-12 text-xsm bg-dark rounded-full cursor-pointer shadow-lg hover:-translate-y-1 duration-500'>
            <span><MoveLeft size={20} color='white'/></span> 
          </button>
      </div>
      <div className='w-full flex flex-col justify-center items-center font-primary pt-10 pb-10'>
        
        {
          craftingTypes.map((type)=>(
            <div 
            onClick={()=>handleSelectedCraftType(type.name)}
            className={`w-full md:w-10/12 relative border rounded-md my-2 cursor-pointer ${selectedCraftType === type.name && 'bg-[#e3e3e3] border border-slate-600'}`} key={type.id}>
              <div className='flex justify-between items-center py-5 px-3'>
                <div className='flex items-center gap-3'>
                  <span className={`rounded-full p-3 bg-[#e3e3e3] ${selectedCraftType === type.name && 'bg-white'}`}>{type.icon}</span>
                  <h3 className='text-[1rem] font-normal capitalize'>
                    {
                      type.name === 'DEFAULT' 
                      ? 'create from scratch' 
                      :  type.name === 'LINKEDIN' 
                      ? 'import from linkedin profile' : 'speech command'
                    }
                  </h3>
                </div>
                <span><ChevronRight size={18}/></span>
              </div>
            </div>
          ))
        }
        <div className='w-full md:w-10/12 bg-[#F3F3F3] p-4 rounded-md'>
          {
             selectedCraftType === 'DEFAULT' && (
               <Scratch
                 setLoading={setLoading}
                //  setError={setError}
                 createResume={createResume}
                 setResume={setResume}
                 setSideBarTab={setSideBarTab}
                 setSelectedResumeId={setSelectedResumeId}
                 setProgressValue={setProgressValue}
                 handleTogglePayWallModal={handleTogglePayWallModal}
               />
             )
          }
          {
             selectedCraftType === 'LINKEDIN' && (
               <CreateResumeViaLinkedinForm
                 setLoading={setLoading}
                //  setError={setError}
                 createResume={CreateResumeViaLinkedin}
                 setResume={setResume}
                 setSideBarTab={setSideBarTab}
                 setSelectedResumeId={setSelectedResumeId}
                 setProgressValue={setProgressValue}
                 handleTogglePayWallModal={handleTogglePayWallModal}
               />
             )
          }
        </div>

      </div>
    </motion.div>
    <PayWall  
      handleTogglePayWallModal={handleTogglePayWallModal} 
      showPayWallModal={showPayWallModal}
      payWallText={'Support our platform to create more documents!'}
     />
    </>
  )
}

export default CreateResume