import React, { useState, createContext, useContext } from 'react'

const ResumeItemContext = createContext();

export const ResumeItemContextProvider = ({ children }) => {

    const [ selectedResumeId, setSelectedResumeId] = useState(null);

  return (
    <ResumeItemContext.Provider value={{
        selectedResumeId,
        setSelectedResumeId
    }}>
        { children }
    </ResumeItemContext.Provider>
  )
}

export const useResumeItemContext = ()=> useContext(ResumeItemContext)

