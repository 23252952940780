
import { useState } from 'react';
import coreClient from '../../services/coreApi';

export const useCreateResumeViaLinkedin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const CreateResumeViaLinkedin = async (payload) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await coreClient.post('/generate-resume-via-linkedin', payload );
      setSuccess(response); // handle successful response
      return response;
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else if (error.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError('Error: ' + error.message);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { CreateResumeViaLinkedin, loading, error, success };
};