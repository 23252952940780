import React, { useState, useEffect } from 'react';
import { Pencil, StickyNote } from 'lucide-react';
import { useCoverLetterDataContext } from '../../contexts/coverLetterDataContext';
import { useCoverLetterTemplateContext } from '../../contexts/coverLetterTemplateContext';
import { useCoverLetterItemContext } from '../../contexts/coverLetterItemContext';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { useGetCoverLetter } from '../../hooks/get/useGetCoverLetter';
import { CoverLetter01, CoverLetter02 } from '../../components/cover-letters';
import EmptyResume from '../../components/placeholders/EmptyResume';
import emptyFolderImg from '../../assets/png/empty-folder.png'
import { ResumeItemSkeleton } from '../../components/loader';
import { PayButtonCoverLetter } from '../../components/pay-button';
import { PayWall } from '../../components/pay-wall';


const EditCoverLetterTemplate = () => {
    const [loading, setLoading] = useState(false);
    const [showPayWallModal, setShowPayWallModal] = useState(false);
    const { coverLetterTemplate } = useCoverLetterTemplateContext();
    const {coverLetter, setCoverLetter } = useCoverLetterDataContext();
    const { selectedCoverLetterId } = useCoverLetterItemContext();
    const { handleSelectTab } = useSideBarContext();
    const getCoverLetter = useGetCoverLetter();


    const handleTogglePayWallModal = ()=>{
      setShowPayWallModal(!showPayWallModal);
    }

    const handleSelectedTab = ()=>{
        handleSelectTab('edit cover letter')
    }

    const handleSelectCoverLetterTab = ()=>{
        handleSelectTab('my cover letters')
    }

    const coverLetterItems = () => {
        return (
            coverLetterTemplate === 1 ? <CoverLetter01 data={coverLetter}/> :
            coverLetterTemplate === 2 ? <CoverLetter02 data={coverLetter}/> :
            null
        );
      }


  async function getcoverLetterData(){
    if(selectedCoverLetterId){
      setLoading(true);
      setTimeout(async()=>{
        try {
            const { status, data } = await getCoverLetter(selectedCoverLetterId);
            if(status===200){
                setCoverLetter(data?.data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false);
        }
      },1000)
    }
  }

  useEffect(()=>{
    getcoverLetterData();
},[selectedCoverLetterId]);

  return (
    <div className='h-auto bg-[#F4F4F6] shadow-lg p-1 rounded-md'>
      {
        loading ? (<div className='grid grid-cols-1 gap-3 p-10'>
          {Array.from({ length: 4}).map((_, i) => (
          <ResumeItemSkeleton key={i} />
        ))}
      </div> )
        : (
        <>
          {
            selectedCoverLetterId && (
              <ul className='flex justify-between items-center py-5 px-3 border bg-white font-primary'>
                  <li className='flex items-center gap-2 text-xsm bg-[#e3e3e3] px-3 py-1 rounded-sm cursor-pointer'
                    onClick={handleSelectedTab}
                  >
                    <span><Pencil size={15}/></span> edit cover letter</li>
                  <li>
                    <PayButtonCoverLetter coverLetterTemplate={coverLetterTemplate} coverLetter={coverLetter} handleTogglePayWallModal={handleTogglePayWallModal} />
                  </li>
              </ul>
            )
          }
          {
            coverLetter && coverLetter ? (
              <>
                  <div className='shadow-lg  px-5 py-5 mb-10 rounded-md bg-white relative'>
                        {coverLetterItems()}
                  </div>
              </>
            ):(
              <EmptyResume 
                  image={emptyFolderImg}
              >
                  <h3 className='mb-3'>You have to select a cover letter to edit</h3>
                  <button className='w-full flex items-center justify-center gap-2 text-xsm bg-[#e3e3e3] px-3 py-2 
                  rounded-full cursor-pointer'
                    onClick={handleSelectCoverLetterTab}
                  >
                      <span><StickyNote size={15}/></span> my cover letters
                  </button>
              </EmptyResume>
            )
          }
        </>
        )
      }
      {
          <PayWall  
          handleTogglePayWallModal={handleTogglePayWallModal} 
          showPayWallModal={showPayWallModal} 
          payWallText={'You have reached your maximum number of Downloads!'}
          />
      }
    </div>
  )
}

export default EditCoverLetterTemplate