import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Check, ChevronRight, Ban } from 'lucide-react';
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { toast } from 'sonner';
import { DownloadAppButton } from '../../components/button';

const CancelPayment = () => {

    const navigateTo = useNavigate();
    function handleRedirect(){
        navigateTo('/resume-dashboard')
      }

  return (
    <main className='md:h-screen flex md:flex-row flex-col  justify-center items-center font-primary'>
        <>
            {/* <div className='md:w-6/12'>
                <button 
                onClick={handleRedirect}
                className='m-auto flex items-center justify-center gap-2 py-2 px-3 
                rounded-md hover:text-light duration-300 ease-in bg-white'>
                    continue to web
                    <span><ChevronRight /></span>
                </button>
                </div>
                <div className='h-full border mx-10 hidden md:block'></div>
                <div className='md:w-4/12'>
                <div className="mockup-phone bg-white">
                    <div className="camera"></div> 
                    <div className="display">
                    <div className="artboard artboard-demo phone-1 p-4 bg-dark">
                        <div className='flex flex-col justify-center gap-3 my-3'>
                        <h3 className='text-center uppercase font-normal text-white'>download our mobile app for best experience</h3>
                        <DownloadAppButton icon={<FaGooglePlay />} text='Google Play Store'/>
                        <DownloadAppButton icon={<FaApple />} text='Apple Store'/>
                        </div>
                    </div>
                    </div>
                </div>
                </div> */}
        </>
        <div className='md:w-6/12'>
            <div className='flex flex-col justify-center items-center'>
                <div className='p-1 border rounded-full'>
                <h3 className='p-1 shadow-lg bg-light rounded-full'>
                    <p className='h-24 w-24 border flex items-center justify-center p-3 rounded-full bg-dark/60 '><Ban color='red' /></p>
                </h3>
                </div>
            </div>
            <div className='text-center my-6'>
                <h3>
                Sorry payment was not successfull!
                </h3>
            </div>
            <button 
            onClick={handleRedirect}
            className='m-auto flex items-center justify-center gap-2 py-2 px-3 
            rounded-md hover:text-light duration-300 ease-in bg-white'>
                continue to web
                <span><ChevronRight /></span>
            </button>
        </div>
    </main>
  );
};

export default CancelPayment;
