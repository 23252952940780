import React from 'react';
import dragScreenshot from '../../assets/png/screenshot-drag.png';
import dragIcon from '../../assets/png/touch-and-drag.png'

const Feature04 = () => {
  return (
    <div className='h-full flex items-center border border-slate-500 rounded-md font-primary'>
      <div className='h-full w-1/2 flex justify-center items-center bg-white rounded-md'>
        <img src={dragScreenshot} alt="" className=''  />
      </div>
      <div className='w-1/2 flex flex-col justify-center gap-5 items-center'>
        <img src={dragIcon} alt="" className='h-24 w-24 animate-bounce'/>
        <h3 className='text-lg font-bold'> Effortless Customization</h3>
        <p className='text-center px-10'>Tailor your resume to match the specific job you're applying for with our easy-to-use customization tools.
           Highlight your unique skills and experience to stand out from the competition.</p>
      </div>
    </div>
  )
}

export default Feature04