import React, { useEffect, useState } from 'react';
import { CalendarDays } from 'lucide-react';

const CertificationsYear = ({
    index,
    handleCertificationsChange,
    initialDate,
}) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [searchedMonth, setSearchedMonth] = useState('');
  const [searchedYear, setSearchedYear] = useState('');
  const [showEndDate, setShowEndDate]  = useState(false);

  // Array of months for dropdown
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ];

  // Generate years for dropdown (e.g., from 1900 to current year)
  const currentYear = new Date().getFullYear();
  // const years = Array.from({ length: currentYear - 1990 + 1 }, (_, i) => 1990 + i);
  const years = Array.from(
    { length: currentYear - 1970 + 1 }, // Calculate the length of the array
    (_, i) => currentYear - i // Generate years from current year down to 1990
  );
  

  // Filtered months based on search input
  const filteredMonths = months.filter(month =>
    month.toLowerCase().includes(searchedMonth.toLowerCase())
  );

  // Filtered years based on search input
  const filteredYears = years.filter(year =>
    year.toString().includes(searchedYear)
  );

  // Function to handle month selection
  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  // Function to handle year selection
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  // Function to handle month search
  const handleMonthSearchChange = (event) => {
    setSearchedMonth(event.target.value);
  };

  // Function to handle year search
  const handleYearSearchChange = (event) => {
    setSearchedYear(event.target.value);
  };

  const handleToggleEndDate = ()=>{
    setShowEndDate(!showEndDate)
  }

  useEffect(()=>{
    if(selectedYear || selectedYear==='Present'){
        handleCertificationsChange(index, 'year', (selectedYear===2024 ? 'Present' : selectedMonth + ' ' + selectedYear))
        handleToggleEndDate();
    } 
  }, [selectedMonth, selectedYear]);

  return (
    <div className=''>
        <label htmlFor="Year">Year</label>
    <h3 
    onClick={handleToggleEndDate}
    className='w-full flex items-center gap-1 px-5 py-2 mt-2 border border-gray-400 rounded-md text-xxxsm font-normal cursor-pointer'>
      {(!selectedMonth || !selectedYear) && <CalendarDays size={10} />}
      {selectedMonth || selectedYear ? (selectedMonth + ' ' + (selectedYear===2024 ? 'Present' : selectedYear)) : (initialDate ? initialDate : 'Year')}
    </h3>
    {
      showEndDate && (
      <div className='flex w-full gap-1 text-xxsm absolute mt-1 z-10 bg-white  left-0'>
        <div className='w-1/2'>
          <div className='w-full'>
            <input
              type="text"
              value={searchedMonth}
              onChange={handleMonthSearchChange}
              placeholder="Search Month"
              className='w-full border-2 outline-none border-dark rounded-md px-1'
            />
          </div>
          <div className='bg-white border mt-2'>
            {filteredMonths.map((month, index) => (
              <h5 className={`p-1 cursor-pointer border-b hover:bg-light ${selectedMonth === month ? 'bg-light' : ''}`} key={index} 
              onClick={() => handleMonthChange(month)}>{month}</h5>
            ))}
          </div>
        </div>
          
        <div className='w-1/2'>
          <div className='w-full'>
            <input
              type="text"
              value={searchedYear}
              onChange={handleYearSearchChange}
              placeholder="Search Year"
              className='w-full border-2 outline-none border-dark rounded-md px-1'
            />
          </div>
          <div className='bg-white border h-64 overflow-y-auto mt-2'>
            {filteredYears.map((year, index) => (
              <h5 className={`p-1 cursor-pointer border-b hover:bg-light ${selectedYear === year ? 'bg-light' : ''}`} key={index} 
              onClick={() => handleYearChange(year)}>
                {year === 2024 ? 'Present' : year}
              </h5>
            ))}
          </div>
        </div>
      </div>

      )
    }
    </div>
  );
};

export default CertificationsYear;
