
import coverLetter01 from '../assets/png/coverletter-01.png';
import coverLetter02 from '../assets/png/coverletter-02.png';

export const coverLetterTemplates = [
    {
      id:1,
      name:'simple',
      image:coverLetter01
    },
    {
      id:2,
      name:'professional',
      image:coverLetter02
    },

  ]