import React, { useState } from 'react';
import { HomeLink } from '../action-btns';
import { UserRound, 
    BriefcaseBusiness, 
    Link,
    Crown, 
    BookText,
    StickyNote,
    Plus,
    FileText,
    LogOut, FileStack
 } from 'lucide-react';
import { useSideBarContext } from '../../contexts/sideBarTabsContext';
import { NavDropDown } from '../drop-down';

const DashboardHeader = ({ handleSignOut }) => {

    const { handleSelectTab } = useSideBarContext();
   

    //#5A38DC

    const tabs = [
        { name: 'create document', link: '', icon: <Plus size={17} /> },
        { name: 'my resumes', link: '', icon: <FileText size={17} /> },
        { name: 'my cover letters', link: '', icon: <StickyNote size={17} /> },
        { name: 'resume templates', link: '', icon: <FileStack size={17} /> },
        { name: 'cover letter templates', link: '', icon: <BookText size={17} /> },
        { name: 'go premium', link: '', icon: <Crown size={17} color="#FFAA1D" /> },
    ];

    
  return (
    <div className="navbar bg-base-100 font-primary font-bold text-sm">
        <div className="flex-1 items-center gap-3">
            <HomeLink color='[#000]'/>
        </div>
        <div className="flex-none">
            {/* <div className="dropdown dropdown-end">
                <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                    <div className=" rounded-full flex justify-center items-center border p-1">
                        <UserRound />
                    </div>
                </div>
                <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] 
                shadow bg-base-100 rounded-sm w-60 font-normal">
                  {
                    tabs.map((tab, i)=>(
                        <li 
                        key={`${tab.name}-${i}`}
                        onClick={()=>handleSelectTab(tab.name)}
                        className='w-full flex flex-row items-center gap-1 py-1
                        cursor-pointer hover:bg-light capitalize text-slate-600 border-b border-light'>
                            <span>{tab.icon}</span>{tab.name}
                        </li>
                    ))
                  }
                  <li className='w-full flex flex-row items-center gap-1 mt-4 cursor-pointer' onClick={handleSignOut}><span><LogOut size={17} /></span>Logout</li>
                </ul>
            </div> */}
            <NavDropDown tabs={tabs} handleSignOut={handleSignOut} handleSelectTab={handleSelectTab}/>
        </div>
        </div>
  )
}

export default DashboardHeader