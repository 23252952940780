import React from 'react';
import { motion } from 'framer-motion';
import { 
    UserRound, 
    ChevronRight,
    Crown, 
    BookText,
    StickyNote,
    Pencil,
    Plus,
    FileText,
    FileStack,
 } from 'lucide-react';

import { useSideBarContext } from '../../contexts/sideBarTabsContext';

const IconSideBar = () => {

    const { sideBarTab, handleSelectTab } = useSideBarContext();

    //#5A38DC

    const tabs = [
        {
            name:'create resume',
            link:'',
            icon:<Plus size={20}/>
        },
        {
            name:'my resumes',
            link:'',
            icon:<FileText size={20}/>
        },
        {
            name:'my cover letters',
            link:'',
            icon:<StickyNote size={20}/>
        },
        // {
        //     name:'edit resume',
        //     link:'',
        //     icon:<Pencil size={17}/>
        // },
        {
            name:'resume templates',
            link:'',
            icon:<FileStack size={20}/>
        },
        {
            name:'cover letter templates',
            link:'',
            icon:<BookText size={20}/>
        },
        {
            name:'go premium',
            link:'',
            icon:<Crown size={20} color="#FFAA1D" />
        },
        // {
        //     name:'my account',
        //     link:'',
        //     icon:<UserRound size={20}/>
        // },
        
    ]
  return (
    <div className='h-full flex justify-center items-center bg-[#F3F3F3] font-primary'>
        <ul className='w-9/12 m-auto shadow-sm p-3 rounded-md'>
            {
                tabs.map((tab, i)=>(
                    <motion.li 
                    initial={{ x: -50, opacity: 0 }} 
                    animate={{ x: 0, opacity: 1 }} 
                    transition={{ duration: 0.5, delay: i * 0.1 }} 
                    
                    onClick={()=>handleSelectTab(tab.name)} 
                        className={`flex items-center justify-center cursor-pointer my-5 capitalize ${sideBarTab === tab.name && 'text-[#e7e7e7] rounded-md'}`} 
                        key={`${tab.name}-${i}`}>
                        <div className='flex items-center gap-1 
                        bg-white p-3 rounded-full shadow-lg
                          tooltip tooltip-right' data-tip={`${tab?.name}`}>
                            <span>{tab.icon}</span>
                        </div>
                    </motion.li>
                ))
            }
        </ul>
    </div>
  )
}

export default IconSideBar