import React, { useState } from 'react'
import { Outlet } from "react-router-dom";
import { 
  ChevronRight,
  ChevronLeft
} from 'lucide-react';
import { SideBar, IconSideBar } from "../../components/sidebar";
import useNetworkStatus from '../../hooks/useNetworkStatus';
import { NetworkChecker } from '../../components/network-checker';

const Dashboard = () => {

  const [sideBarToggler, setSideBarToggler] = useState(false);
  const { isOnline } = useNetworkStatus();

  const handleToggleSidebar = ()=>{
    setSideBarToggler(!sideBarToggler)
  }

  return (
    <>
      {
        isOnline 
        ? (

          <div className="h-screen w-full flex fixed">
            <div className={`hidden md:block ${sideBarToggler ? 'w-1/12' : 'w-2/12'} border-r-2 border-white relative`}>
              <span
                className="absolute bottom-[6rem] -right-5 rounded-full bg-dark p-2 cursor-pointer"
                onClick={handleToggleSidebar}
              >
                {sideBarToggler ? <ChevronRight color="white" /> : <ChevronLeft color="white" />}
              </span>
              {sideBarToggler ? <IconSideBar /> : <SideBar />}
            </div>
            <div className={`w-full ${sideBarToggler ? 'md:w-11/12' : 'md:w-10/12'}`}>
              <Outlet />
            </div>
          </div>
        ):(
          <>
            <NetworkChecker/>
          </>
        )
      }
    </>
  )
}

export default Dashboard